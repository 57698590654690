import { SortOption } from "@dogstrust/src/context/DogSearchApi/DogSearchApi.types";

export const getFilteredDogs = (
	sponsorDogs: (Queries.SponsorDogDataFragment | SponsorDogWithDistance)[],
	distance: number,
	selectedBreeds: string[],
	canVisit: boolean,
	sortBy: SortOption,
) => {
	const sortByNear = (
		dogA: SponsorDogWithDistance,
		dogB: SponsorDogWithDistance,
	) => (dogA.distance > dogB.distance ? 1 : -1);
	const sortByFar = (
		dogA: SponsorDogWithDistance,
		dogB: SponsorDogWithDistance,
	) => (dogA.distance > dogB.distance ? -1 : 1);
	const sortByNew = (
		dogA: Queries.SponsorDogDataFragment,
		dogB: Queries.SponsorDogDataFragment,
	) =>
		new Date(dogB.field_date).getTime() - new Date(dogA.field_date).getTime();
	const sortByOld = (
		dogA: Queries.SponsorDogDataFragment,
		dogB: Queries.SponsorDogDataFragment,
	) =>
		new Date(dogA.field_date).getTime() - new Date(dogB.field_date).getTime();
	const sortByAZ = (
		dogA: Queries.SponsorDogDataFragment,
		dogB: Queries.SponsorDogDataFragment,
	) => (dogA.title > dogB.title ? 1 : -1);
	const sortByZA = (
		dogA: Queries.SponsorDogDataFragment,
		dogB: Queries.SponsorDogDataFragment,
	) => (dogB.title > dogA.title ? 1 : -1);

	const getSort = () => {
		switch (sortBy) {
			case "NEAR":
				return sortByNear;
			case "FAR":
				return sortByFar;
			case "A":
				return sortByAZ;
			case "Z":
				return sortByZA;
			case "NEW":
				return sortByNew;
			case "OLD":
				return sortByOld;
		}
	};
	return sponsorDogs
		.filter(
			(dog) =>
				selectedBreeds.length === 0 ||
				selectedBreeds.includes(dog.relationships.field_breed[0].name),
		)
		.filter(
			(dog) =>
				distance >= 1000 ||
				(dog as SponsorDogWithDistance).distance <= distance,
		)
		.filter((dog) => (!canVisit ? true : dog.field_can_be_visited))
		.sort(getSort());
};

export const getSortName = (sortBy: SortOption) => {
	switch (sortBy) {
		case "NEAR":
			return "Nearest first";
		case "FAR":
			return "Furthest first";
		case "NEW":
			return "Newest Listed";
		case "OLD":
			return "Oldest Listed";
		case "A":
			return "Alphabetical (A - Z)";
		case "Z":
			return "Alphabetical (Z - A)";
	}
};

export const isWithinDays = (days: number, compareDate: Date) => {
	const msBetweenDates = Math.abs(compareDate.getTime() - new Date().getTime());
	const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
	return daysBetweenDates < days;
};
