import {
	DogClassLocation,
	DogClassRegion,
	DogClassesSortOption,
	UserDistanceToLocation,
} from "./DogSchoolApi.types";

// ========================== LOAD_ALL_DOG_SCHOOL_REGIONS ==========================
export const LOAD_ALL_DOG_SCHOOL_REGIONS = "LOAD_ALL_DOG_SCHOOL_REGIONS";
interface LoadAllDogSchoolRegions {
	type: typeof LOAD_ALL_DOG_SCHOOL_REGIONS;
	dogClassRegions: DogClassRegion[];
}

export const onLoadAllDogSchoolRegionsAction = (
	dogClassRegions: DogClassRegion[],
): LoadAllDogSchoolRegions => ({
	type: LOAD_ALL_DOG_SCHOOL_REGIONS,
	dogClassRegions,
});

// ========================== UPDATE_SELECTED_NAME ==========================
export const UPDATE_SELECTED_NAME = "UPDATE_SELECTED_NAME";
interface UpdateSelectedName {
	type: typeof UPDATE_SELECTED_NAME;
	selectedRegionName: string;
}

export const onUpdateSelectedRegionAction = (
	selectedRegionName: string,
): UpdateSelectedName => ({
	type: UPDATE_SELECTED_NAME,
	selectedRegionName,
});

// ========================== UPDATE_TRAVEL_DISTANCE =========================
export const UPDATE_TRAVEL_DISTANCE = "UPDATE_TRAVEL_DISTANCE";
interface UpdateTravelDistanceAction {
	type: typeof UPDATE_TRAVEL_DISTANCE;
	distance: number;
}
export const onUpdateTravelDistanceAction = (
	distance: number,
): UpdateTravelDistanceAction => ({
	type: UPDATE_TRAVEL_DISTANCE,
	distance,
});

// ========================== UPDATE_LOCATION_SUGGESTIONS ====================
export const UPDATE_LOCATION_SUGGESTIONS = "UPDATE_LOCATION_SUGGESTIONS";
interface UpdateLocationSuggestionsAction {
	type: typeof UPDATE_LOCATION_SUGGESTIONS;
	locations: string[];
}
export const onUpdateLocationSuggestionsAction = (
	locations: string[],
): UpdateLocationSuggestionsAction => {
	return {
		type: UPDATE_LOCATION_SUGGESTIONS,
		locations,
	};
};

// ========================== UPDATE_LOCATION ==========================
export const UPDATE_LOCATION = "UPDATE_LOCATION";
interface UpdateLocationAction {
	type: typeof UPDATE_LOCATION;
	userLocation: Place | null;
	distance: number;
}

export const onUpdateLocationAction = (
	userLocation: Place | null,
	distance: number,
): UpdateLocationAction => ({ type: UPDATE_LOCATION, userLocation, distance });

// ========================== UPDATE_CLASS_TYPE ==========================
export const UPDATE_CLASS_TYPE = "UPDATE_CLASS_TYPE";
interface UpdateClassType {
	type: typeof UPDATE_CLASS_TYPE;
	selectedType?: string;
}

export const onUpdateClassTypeAction = (
	selectedType?: string,
): UpdateClassType => ({
	type: UPDATE_CLASS_TYPE,
	selectedType,
});

// ========================== UPDATE_SORT_OPTION ===================================
export const UPDATE_SORT_OPTION = "UPDATE_SORT_OPTION";
interface UpdateSortOptionAction {
	type: typeof UPDATE_SORT_OPTION;
	selectedOption: DogClassesSortOption;
}

export const onUpdateSortOptionAction = (
	selectedOption: DogClassesSortOption,
): UpdateSortOptionAction => ({
	type: UPDATE_SORT_OPTION,
	selectedOption,
});

// ========================== CLEAR_SELECTED_REGION ===================================
export const CLEAR_SELECTED_REGION = "CLEAR_SELECTED_REGION";
interface ClearSelectedRegionAction {
	type: typeof CLEAR_SELECTED_REGION;
}

export const onClearSelectedRegionAction = (): ClearSelectedRegionAction => ({
	type: CLEAR_SELECTED_REGION,
});

// ========================== UPDATE_USER_DISTANCE ===================================
export const UPDATE_USER_DISTANCE = "UPDATE_USER_DISTANCE";
interface UpdateUserDistanceAction {
	type: typeof UPDATE_USER_DISTANCE;
	userDistance: UserDistanceToLocation[];
}

export const onUpdateUserDistanceAction = (
	userDistance: UserDistanceToLocation[],
): UpdateUserDistanceAction => ({
	type: UPDATE_USER_DISTANCE,
	userDistance,
});

// ========================== UPDATE_SELECTED_CLASS_LOCATION ===================================
export const UPDATE_SELECTED_CLASS_LOCATION = "UPDATE_SELECTED_CLASS_LOCATION";
interface UpdateSelectedClassLocation {
	type: typeof UPDATE_SELECTED_CLASS_LOCATION;
	selectedLocation: DogClassLocation;
}

export const onUpdateSelectedClassLocationAction = (
	selectedLocation: DogClassLocation,
): UpdateSelectedClassLocation => ({
	type: UPDATE_SELECTED_CLASS_LOCATION,
	selectedLocation,
});

export type DogSchoolAction =
	| LoadAllDogSchoolRegions
	| UpdateSelectedName
	| UpdateTravelDistanceAction
	| UpdateLocationSuggestionsAction
	| UpdateLocationAction
	| UpdateSortOptionAction
	| UpdateClassType
	| ClearSelectedRegionAction
	| UpdateUserDistanceAction
	| UpdateSelectedClassLocation;
