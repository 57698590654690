import React, { useCallback, useState } from "react";

import { VacancyWithDistance } from "@dogstrust/src/containers/sections/SectionVolunteerVacancyFiltersList/SectionVolunteerVacancyFiltersList";
import { unique } from "@dogstrust/src/utils/array";

import { haversineDistance } from "@dogstrust/src/utils/distance";
import { useMemo } from "react";
import { VolunteerVacanciesContext } from "./VolunteerVacancies.context";

const VolunteerVacanciesProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [vacancies, setVacancies] = useState<
		Queries.VolunteerVacancyDataFragment[]
	>([]);
	const [userLocation, setUserLocation] = useState<Place | null>(null);
	const [currentDistance, setCurrentDistance] = useState<number>(1000);

	const roughDistanceMapper = useCallback(
		(vacancy: Queries.VolunteerVacancyDataFragment): VacancyWithDistance => {
			if (!userLocation) return { ...vacancy, distance: 0 };
			const distanceBetween = haversineDistance(userLocation, {
				longitude: parseFloat(vacancy.vacancyLocation.longitude),
				latitude: parseFloat(vacancy.vacancyLocation.latitude),
				address: "",
			});
			return { ...vacancy, distance: distanceBetween };
		},
		[userLocation],
	);

	const nationalVacancies: VacancyWithDistance[] = vacancies
		.map((vacancy) => ({ ...vacancy, distance: 0 }))
		.filter((vacancy) => vacancy.vacancyLocation.isNational);

	const nonNationalVacancies: VacancyWithDistance[] = useMemo(
		() =>
			vacancies
				.map(roughDistanceMapper)
				.filter((vacancy) => !vacancy.vacancyLocation.isNational)
				.sort((a, b) => a.distance - b.distance),
		[vacancies, roughDistanceMapper],
	);

	const vacanciesWithDistance = [...nationalVacancies, ...nonNationalVacancies];

	const availableRoles = vacanciesWithDistance
		.map((vacancy) => vacancy.relationships.field_volunteer_role.title)
		.filter(unique)
		.sort();

	const onChangeLocation = (location: Place | null, distance: number) => {
		setUserLocation(location);
		setCurrentDistance(distance);
	};
	return (
		<VolunteerVacanciesContext.Provider
			value={{
				vacanciesWithDistance,
				availableRoles,
				onChangeLocation,
				currentDistance,
				userLocation,
				setVacancies,
			}}
		>
			{children}
		</VolunteerVacanciesContext.Provider>
	);
};

export default VolunteerVacanciesProvider;
