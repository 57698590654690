import { navigate } from "gatsby";
import debounce from "lodash.debounce";
import React, { useRef } from "react";
import {
	type PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { v4 as uuidv4 } from "uuid";
import { usePersistSurveyData } from "./hooks/usePersistSurveyData";
import { getNextScreen, multiDogFields } from "./utils/nationalDogSurvey";

const SOFT_END_SCREENS: NDSScreens[] = [
	"nds-q-32.1",
	"nds-q-32.2",
	"nds-q-32.3",
	"nds-q-34.1",
];
interface NationalDogSurveyContextState {
	routeStack: NDSScreenState[];
	setRouteStack: (value: NDSScreenState[]) => void;
	screenState: NDSScreenState;
	disableNext: boolean;
	setDisableNext: (value: boolean) => void;
	currentDogs: Record<number, Record<string, string | string[]>>;
	setCurrentDogs: (
		value: Record<number, Record<string, string | string[]>>,
	) => void;
	formData: NDSFormData;
	setFormData: (value: NDSFormData) => void;
	scrollToTop: () => void;
	onBack?: () => void;
	onNext?: () => void;
	handleChange: (name: NDSFormFieldNames, value?: string | string[]) => void;
	currentDog: Record<string, string | string[]>;
	currentDogName?: string;
	warmData: NDSWarmUserData;
	onSpecialNavigation: (
		nextRoute: NDSRoute,
		nextScreen: NDSScreens,
		nextDogIndex?: number,
	) => void;
	onSubmit: () => void;
	onSoftSubmit: () => Promise<boolean>;
	onLoadWarmRoute: (incomingWarmData: NDSWarmUserData) => void;
	onLoadSupporterId: (supporterId: string) => void;

	surveyProgress: NDSSurveyProgress;

	transition?: NDSTransition;
	playedTransitions?: NDSTransition[];
	setTransition?: (value: NDSTransition | null) => void;
	setPlayedTransitions?: (value: NDSTransition[]) => void;
	clearSurveyCookies?: () => void;
	importSurveyCookies?: (data: string) => void;
	exportSurveyCookies?: () => string;
	hasInitialisedFromCookies?: boolean;
	questionSchema: NDSQuestionSchema;
	setQuestionSchema: (value: NDSQuestionSchema) => void;
	updateNAQFields: (fieldName: NDSFormFieldNames, value: string) => void;
	isOneDog: boolean;
	isLoading: boolean;
	handleThankYouReload?: () => void;
	resetSurvey?: () => void;
}
const NationalDogSurveyContext = createContext<NationalDogSurveyContextState>(
	{} as NationalDogSurveyContextState,
);
export const NationalDogSurveyContextProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [hasSubmitted, setHasSubmitted] = useState(false);
	const [hasInitialisedFromCookies, setHasInitialisedFromCookies] =
		useState<boolean>(false);
	const [warmData, setWarmData] = useState<NDSWarmUserData>(null);
	const [routeStack, setRouteStack] = useState<NDSScreenState[]>([
		{
			route: "about-your-dogs",
			screen: "nds-q-1",
			dogIndex: 1,
		},
	]);

	const [surveyProgress, setSurveyProgress] = useState<NDSSurveyProgress>({
		aboutYou: -1,
		lifeWithYourDog: -1,
		aboutYourDog: 0,
		wellbeing: -1,
		attitudeToDogs: -1,
		multiDog: {
			aboutYourDog: -1,
			lifeWithYourDog: -1,
		},
	});

	const screenState = useMemo(
		() => routeStack[routeStack.length - 1],
		[routeStack],
	);

	const [transition, setTransition] = useState<NDSTransition | null>(null);
	const [playedTransitions, setPlayedTransitions] = useState<NDSTransition[]>(
		[],
	);

	const [disableNext, setDisableNext] = useState<boolean>(false);
	const naqFieldsRef = useRef<NDSFormFieldNames[]>([]);

	const [_submissionError, setSubmissionError] = useState<string | null>(null);
	const [currentDogs, setCurrentDogs] = useState<
		Record<number, Record<string, string | string[]>>
	>({});
	// The form data submitted by the user
	const [formData, setFormData] = useState<NDSFormData>({} as NDSFormData);
	const [supporterId, setSupporterId] = useState<string | null>(null);
	const [surveyStartTime, setSurveyStartTime] = useState<number>(0);
	const [surveyResponseId, setSurveyResponseId] = useState<string>(null);
	const [questionSchema, setQuestionSchema] =
		useState<NDSQuestionSchema | null>(null);

	useEffect(() => {
		if (!hasInitialisedFromCookies) {
			const canInitialiseFromCookies =
				localStorage.getItem("nds-survey-status") === "started";

			if (!canInitialiseFromCookies) {
				setSurveyStartTime(Date.now());
				setSurveyResponseId(uuidv4());
				setHasInitialisedFromCookies(true);
				setRouteStack([
					{
						route: "about-your-dogs",
						screen: "nds-q-1",
						dogIndex: 1,
					},
				]);

				return;
			}
			const surveyStartTimeCookie = localStorage.getItem(
				"nds-surveyStartTime",
			) as string;
			const warmDataCookie = localStorage.getItem("nds-warmData") as string;
			const routeStackCookie = localStorage.getItem("nds-routeStack") as string;
			const surveyProgressCookie = localStorage.getItem(
				"nds-surveyProgress",
			) as string;
			const playedTransitionsCookie = localStorage.getItem(
				"nds-playedTransitions",
			) as string;
			const currentDogsCookie = localStorage.getItem(
				"nds-currentDogs",
			) as string;
			const formDataCookie = localStorage.getItem("nds-formData") as string;

			const naqFieldsCookie = localStorage.getItem("nds-naqFields") as string;

			const supporterIdCookie = localStorage.getItem(
				"nds-supporterId",
			) as string;

			const surveyResponseIdCookie = localStorage.getItem(
				"nds-surveyResponseId",
			) as string;

			surveyStartTimeCookie &&
				setSurveyStartTime(Number.parseInt(surveyStartTimeCookie));
			warmDataCookie && setWarmData(JSON.parse(warmDataCookie));
			routeStackCookie && setRouteStack(JSON.parse(routeStackCookie));
			surveyProgressCookie &&
				setSurveyProgress(JSON.parse(surveyProgressCookie));
			playedTransitionsCookie &&
				setPlayedTransitions(JSON.parse(playedTransitionsCookie));
			currentDogsCookie && setCurrentDogs(JSON.parse(currentDogsCookie));
			formDataCookie && setFormData(JSON.parse(formDataCookie));

			supporterIdCookie && setSupporterId(supporterIdCookie);
			setSurveyResponseId(
				surveyResponseIdCookie ? surveyResponseIdCookie : uuidv4(),
			);

			if (naqFieldsCookie !== "undefined") {
				naqFieldsRef.current = JSON.parse(naqFieldsCookie);
			}

			setHasInitialisedFromCookies(true);
		}
	}, [hasInitialisedFromCookies]);

	const { clearSurveyCookies, importSurveyCookies, exportSurveyCookies } =
		usePersistSurveyData(
			warmData,
			routeStack,
			surveyProgress,
			playedTransitions,
			formData,
			currentDogs,
			surveyStartTime,
			supporterId,
			surveyResponseId,
		);

	const resetSurvey = useCallback(() => {
		setRouteStack([
			{
				route: "about-your-dogs",
				screen: "nds-q-1",
				dogIndex: 1,
			},
		]);
		setSurveyProgress({
			aboutYou: -1,
			lifeWithYourDog: -1,
			aboutYourDog: 0,
			wellbeing: -1,
			attitudeToDogs: -1,
			multiDog: {
				aboutYourDog: -1,
				lifeWithYourDog: -1,
			},
		});
		setWarmData(null);
		setFormData({} as NDSFormData);
		setCurrentDogs({});
		setSurveyStartTime(Date.now());
		setSupporterId(null);
		setHasInitialisedFromCookies(false);
		clearSurveyCookies();
	}, [clearSurveyCookies]);
	/**
	 * Scrolls to the top of the page
	 * */
	const scrollToTop = useCallback(() => {
		window.scrollTo({ top: 0, behavior: "instant" });
	}, []);

	/**
	 *  Goes back to the previous screen
	 */
	const onBack = useCallback(() => {
		const nextRouteStack = routeStack.slice(0, -1);

		setRouteStack(nextRouteStack);

		scrollToTop();
		return;
	}, [scrollToTop, routeStack]);

	/**
	 * Proceeds to the next screen
	 * */
	const onNext = useCallback(() => {
		const [nextScreenState, nextSurveyProgress, nextTransition] = getNextScreen(
			screenState,
			formData,
			questionSchema,
			currentDogs,
			surveyProgress,
		);
		const preserveSurveyProgress: NDSSurveyProgress = {
			aboutYou: Math.max(surveyProgress.aboutYou, nextSurveyProgress.aboutYou),
			lifeWithYourDog: Math.max(
				surveyProgress.lifeWithYourDog,
				nextSurveyProgress.lifeWithYourDog,
			),
			aboutYourDog: Math.max(
				surveyProgress.aboutYourDog,
				nextSurveyProgress.aboutYourDog,
			),
			wellbeing: Math.max(
				surveyProgress.wellbeing,
				nextSurveyProgress.wellbeing,
			),
			attitudeToDogs:
				nextSurveyProgress.attitudeToDogs === -1 // We need this to reset this option if the user goes back and changes their mind
					? -1
					: Math.max(
							surveyProgress.attitudeToDogs,
							nextSurveyProgress.attitudeToDogs,
						),
			multiDog: nextSurveyProgress.multiDog,
		};

		setTransition(nextTransition);

		setSurveyProgress(preserveSurveyProgress);

		setRouteStack((prevRouteStack) => [...prevRouteStack, nextScreenState]);
		scrollToTop();
	}, [
		scrollToTop,
		screenState,
		formData,
		questionSchema,
		currentDogs,
		surveyProgress,
	]);

	/**
	 * Handles changes to the form data
	 * */
	const handleChange = useCallback(
		(name: NDSFormFieldNames, value?: string | string[] | null) => {
			// We keep a record of the form data for later submission
			if (value === undefined) {
				return;
			}
			// Multi dog fields need to be stored against the dog index
			if (multiDogFields.includes(name)) {
				setCurrentDogs((prevDogs) => {
					const newDogs = { ...prevDogs };

					if (value === null) {
						delete newDogs[screenState.dogIndex][name];
						return newDogs;
					}

					newDogs[screenState.dogIndex] = {
						...newDogs[screenState.dogIndex],
						[name]: value,
					};

					if (name === "nds-q-2-dogs-name") {
						newDogs[screenState.dogIndex].name = value;
					}
					return newDogs;
				});
				return;
			}

			setFormData((prevFormData) => {
				return { ...prevFormData, [name]: value };
			});
		},
		[screenState.dogIndex],
	);

	const onLoadWarmRoute = useCallback(
		(incomingWarmData: NDSWarmUserData) => {
			// It's already been loaded and initialised so just use cookies.
			if (warmData?.supporterId === incomingWarmData.supporterId) return;

			setFormData({} as NDSFormData);
			setCurrentDogs({
				1: { name: incomingWarmData.dog?.name },
			});
			setSurveyProgress({
				aboutYou: -1,
				lifeWithYourDog: -1,
				aboutYourDog: 0,
				wellbeing: -1,
				attitudeToDogs: -1,
				multiDog: {
					aboutYourDog: -1,
					lifeWithYourDog: -1,
				},
			});
			setSurveyStartTime(Date.now());
			setSurveyResponseId(uuidv4());
			setWarmData(incomingWarmData);
			setRouteStack([
				{
					dogIndex: 1,
					route: "warm",
					screen: "warm-intro",
					warmData: incomingWarmData,
				},
			]);
		},
		[warmData],
	);

	const updateNAQFields = useCallback(
		(fieldName: NDSFormFieldNames, value: string) => {
			if (value === "NAQ") {
				naqFieldsRef.current = naqFieldsRef.current
					? [...naqFieldsRef.current, fieldName]
							.filter((field, index, self) => self.indexOf(field) === index)
							.sort((a, b) =>
								a.replace("nds-q-", "").localeCompare(b.replace("nds-q-", "")),
							)
					: [fieldName];
				return;
			}
			naqFieldsRef.current =
				naqFieldsRef.current?.filter((field) => field !== fieldName) || [];
		},
		[],
	);

	const { executeRecaptcha } = useGoogleReCaptcha();

	/**
	 * Submits the form data
	 * */
	const onSubmit = useCallback(
		async (e?: {
			preventDefault: () => void;
		}) => {
			e?.preventDefault();
			setIsLoading(true);
			return debounce(async () => {
				const surveyEndTime = Date.now();

				const surveyDuration = surveyEndTime - surveyStartTime;

				// The survey duration in hours:min:sec
				const surveyDurationHours = Math.floor(surveyDuration / 3600000);
				const surveyDurationMinutes = Math.floor(
					(surveyDuration % 3600000) / 60000,
				);
				const surveyDurationSeconds = Math.floor(
					(surveyDuration % 60000) / 1000,
				);

				const mappedDogData: Record<string, string | string[]> = {};

				for (const dogIndex in currentDogs) {
					const dogData = currentDogs[dogIndex];
					for (const key in dogData) {
						const newKey = `${key}_${dogIndex}`;
						mappedDogData[newKey] = dogData[key];
					}
				}

				const formDataToSend = { ...formData };

				for (const naqField of naqFieldsRef.current || []) {
					if ([null, undefined, "", []].includes(formDataToSend[naqField])) {
						formDataToSend[naqField] = "NAQ";
					}
				}
				// We want to map this question into the q1 question
				if (formData["nds-q-0.4-number-of-dogs"]) {
					formDataToSend["nds-q-1-number-of-dogs"] =
						formData["nds-q-0.4-number-of-dogs"];
					formDataToSend["nds-q-1-number-of-dogs-specify"] =
						formData["nds-q-0.4-number-of-dogs-specify"];
				}

				// Some values in form data will be "DO_DELETE" we want to remove these before it's sent
				for (const key in formDataToSend) {
					if (
						formDataToSend[key as keyof typeof formDataToSend] === "DO_DELETE"
					) {
						delete formDataToSend[key as keyof typeof formDataToSend];
					}
				}

				const recaptchaToken = await executeRecaptcha?.("national_dog_survey");
				const submitData = {
					...formDataToSend,
					...mappedDogData,
					surveyStartTime,
					surveyEndTime,
					surveyDuration: `${surveyDurationHours}:${surveyDurationMinutes}:${surveyDurationSeconds}`,
					recaptchaToken,
					supporterId: warmData?.supporterId || supporterId,
					responseId: surveyResponseId,
					submitType: "final",
				};

				const submitResponse = await fetch(
					process.env.GATSBY_NDS_SURVEY_SUBMIT_URL,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(submitData),
					},
				);
				if (submitResponse.ok) {
					setIsLoading(false);
					handleChange("nds-survey-status", "completed");
					navigate("/national-dog-survey/global/nds-thank-you");
				} else {
					console.error(
						"There was an error submitting the form",
						submitResponse,
					);
					setHasSubmitted(false);
					setIsLoading(false);
					setSubmissionError("There was an error submitting the form");
					alert("There was an error submitting the form, please try again");
				}
			}, 5000)();
		},
		[
			formData,
			currentDogs,
			surveyStartTime,
			warmData,
			supporterId,
			handleChange,
			surveyResponseId,
			executeRecaptcha,
		],
	);

	/**
	 * Submits the form data
	 * */
	const onSoftSubmit = useCallback(
		async (e?: {
			preventDefault: () => void;
		}) => {
			e?.preventDefault();
			return debounce(async () => {
				const surveyEndTime = Date.now();

				const surveyDuration = surveyEndTime - surveyStartTime;

				// The survey duration in hours:min:sec
				const surveyDurationHours = Math.floor(surveyDuration / 3600000);
				const surveyDurationMinutes = Math.floor(
					(surveyDuration % 3600000) / 60000,
				);
				const surveyDurationSeconds = Math.floor(
					(surveyDuration % 60000) / 1000,
				);

				const mappedDogData: Record<string, string | string[]> = {};

				for (const dogIndex in currentDogs) {
					const dogData = currentDogs[dogIndex];
					for (const key in dogData) {
						const newKey = `${key}_${dogIndex}`;
						mappedDogData[newKey] = dogData[key];
					}
				}

				const formDataToSend = { ...formData };

				for (const naqField of naqFieldsRef.current || []) {
					if ([null, undefined, "", []].includes(formDataToSend[naqField])) {
						formDataToSend[naqField] = "NAQ";
					}
				}
				// We want to map this question into the q1 question
				if (formData["nds-q-0.4-number-of-dogs"]) {
					formDataToSend["nds-q-1-number-of-dogs"] =
						formData["nds-q-0.4-number-of-dogs"];
					formDataToSend["nds-q-1-number-of-dogs-specify"] =
						formData["nds-q-0.4-number-of-dogs-specify"];
				}

				// Some values in form data will be "DO_DELETE" we want to remove these before it's sent
				for (const key in formDataToSend) {
					if (
						formDataToSend[key as keyof typeof formDataToSend] === "DO_DELETE"
					) {
						delete formDataToSend[key as keyof typeof formDataToSend];
					}
				}

				const recaptchaToken = await executeRecaptcha?.("national_dog_survey");

				const submitData = {
					...formDataToSend,
					...mappedDogData,
					surveyStartTime,
					surveyEndTime,
					surveyDuration: `${surveyDurationHours}:${surveyDurationMinutes}:${surveyDurationSeconds}`,
					recaptchaToken,
					responseId: surveyResponseId,
					supporterId: warmData?.supporterId || supporterId,
					submitType: "soft",
				};

				const submitResponse = await fetch(
					process.env.GATSBY_NDS_SURVEY_SUBMIT_URL,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(submitData),
					},
				);

				return submitResponse.ok;
			}, 5000)();
		},
		[
			formData,
			currentDogs,
			surveyStartTime,
			warmData,
			supporterId,
			surveyResponseId,
			executeRecaptcha,
		],
	);

	const onSpecialNavigation = useCallback(
		async (
			nextRoute: NDSRoute,
			nextScreen: NDSScreens,
			nextDogIndex?: number,
		) => {
			// They were on a potential end screen but did not choose to submit.
			if (SOFT_END_SCREENS.includes(screenState.screen)) {
				await onSoftSubmit();
			}
			const nextScreenState =
				nextDogIndex !== undefined
					? {
							...screenState,
							route: nextRoute,
							screen: nextScreen,
							dogIndex: nextDogIndex,
						}
					: { ...screenState, route: nextRoute, screen: nextScreen };

			setSurveyProgress((prevSurveyProgress) => ({
				...prevSurveyProgress,
				multiDog: {
					aboutYourDog: 0,
					lifeWithYourDog: -1,
				},
			}));
			setRouteStack([...routeStack, nextScreenState]);
			scrollToTop();
		},
		[scrollToTop, screenState, routeStack, onSoftSubmit],
	);

	const [isOneDog, setIsOneDog] = useState(false);

	useEffect(() => {
		if (!questionSchema) return;
		// If the user has said they have a dog, named it and then gone back and said they don't have a dog, we need to reset the dog data
		const numberOfDogs = formData["nds-q-1-number-of-dogs"];
		const noDogOption =
			questionSchema.routes["about-your-dogs"]["nds-q-1"].fields[0].options[0]
				.value;
		const oneDogOption =
			questionSchema.routes["about-your-dogs"]["nds-q-1"].fields[0].options[1]
				.value;

		if (numberOfDogs === noDogOption) {
			currentDogs && Object.keys(currentDogs).length > 0 && setCurrentDogs({});

			return;
		}
		if (numberOfDogs === oneDogOption) {
			setIsOneDog(true);
			return;
		}
		setIsOneDog(false);
	}, [formData, questionSchema, currentDogs]);

	useEffect(() => {
		if (screenState.screen === "nds-thank-you" && hasSubmitted) {
			// When they navigate away from the survey, we want to clear the cookies
			window.addEventListener("beforeunload", clearSurveyCookies);
		}
	}, [screenState, hasSubmitted, clearSurveyCookies]);
	const onLoadSupporterId = useCallback((supporterId: string) => {
		setSupporterId(supporterId);
	}, []);

	const handleThankYouReload = useCallback(() => {
		resetSurvey();
		navigate("/about-us/what-we-do/national-dog-survey");
	}, [resetSurvey]);

	return (
		<NationalDogSurveyContext.Provider
			value={{
				setRouteStack,
				setDisableNext,
				setCurrentDogs,
				setFormData,
				setTransition,
				setPlayedTransitions,

				routeStack,
				screenState,
				disableNext: disableNext || isLoading || hasSubmitted,
				currentDogs,
				formData,
				scrollToTop,
				onBack,
				onNext,
				handleChange,
				currentDog: currentDogs[screenState.dogIndex] || {},
				currentDogName: currentDogs[screenState.dogIndex]?.name as string,
				warmData,
				onSpecialNavigation,
				onSubmit,
				onSoftSubmit,
				onLoadWarmRoute,
				surveyProgress,

				transition,
				playedTransitions,
				clearSurveyCookies,
				importSurveyCookies,
				exportSurveyCookies,
				hasInitialisedFromCookies,
				questionSchema,
				setQuestionSchema,
				updateNAQFields,
				onLoadSupporterId,
				handleThankYouReload,
				resetSurvey,
				isOneDog,
				isLoading,
			}}
		>
			{children}
		</NationalDogSurveyContext.Provider>
	);
};
export const useNationalDogSurveyContext = (): NationalDogSurveyContextState =>
	useContext(NationalDogSurveyContext);
