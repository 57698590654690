/**
 * Given the current screen and form data, returns the next screen for the about your dogs route
 * @param currentScreen	- The current screen
 * @param formData	- The form data
 * @param _questionSchema - The question schema
 * @returns The next screen
 */

import { DEFAULT_MULTI_DOG_PROGRESS } from "..";

export const getNextScreenAboutYourDogsRoute = (
	screenState: NDSScreenState,
	formData: NDSFormData,
	questionSchema: NDSQuestionSchema,
	currentDogs: Record<number, Record<string, string | string[]>>,
	surveyProgress: NDSSurveyProgress,
):
	| [NDSScreenState, NDSSurveyProgress]
	| [NDSScreenState, NDSSurveyProgress, NDSTransition] => {
	const currentScreen = screenState.screen;
	const currentDogIndex = screenState.dogIndex;
	if (currentScreen === "nds-q-1") {
		//! How many dogs do you currently own?

		// How many dogs do you currently own?
		const answer = formData["nds-q-1-number-of-dogs"];

		const dontHaveDog =
			questionSchema.routes["about-your-dogs"]["nds-q-1"].fields[0].options[0]
				.value;

		// If they don't have a dog, go to the non dog owner route
		if (answer === dontHaveDog) {
			// User goes to the non dog owner route
			return [
				{ ...screenState, route: "non-dog-owner", screen: "nds-q-E1" },
				{ ...surveyProgress, aboutYourDog: 100, attitudeToDogs: 0 },
			];
		}

		// Otherwise, go to the intro screen
		return [
			{ ...screenState, screen: "about-your-dogs-intro" },
			{ ...surveyProgress, aboutYourDog: 10, attitudeToDogs: -1 },
		];
	}

	if (currentScreen === "about-your-dogs-intro") {
		//! Title screen
		const answer = formData["nds-q-1-number-of-dogs"];

		// We know they have at least one dog so we only need to check if they have one or more.
		const ownsOneDog =
			questionSchema.routes["about-your-dogs"]["nds-q-1"].fields[0].options[1]
				.value;

		// If they have one dog they skip straight to question 2
		if (answer === ownsOneDog) {
			return [{ ...screenState, screen: "nds-q-2" }, surveyProgress]; // What is your dog’s name?
		}

		// Otherwise we show the intro screen for multiple dogs
		return [
			{ ...screenState, screen: "about-your-dogs-multi-dog-intro" },
			surveyProgress,
		]; // Intro screen
	}

	if (currentScreen === "about-your-dogs-multi-dog-intro") {
		//! Intro screen for multiple dogs
		return [{ ...screenState, screen: "nds-q-2" }, surveyProgress];
	}
	const multiDogAnswer = currentDogIndex > 1;

	if (currentScreen === "nds-q-2") {
		//! What is your dog’s name?

		return [
			{ ...screenState, screen: "nds-q-3" },
			{
				...surveyProgress,
				aboutYourDog: 20,
				multiDog: multiDogAnswer
					? { ...surveyProgress.multiDog, aboutYourDog: 20 }
					: DEFAULT_MULTI_DOG_PROGRESS,
			},
		]; // Intro screen
	}
	if (currentScreen === "nds-q-3") {
		//! When did you get <Dog’s Name>?

		// Only ask this question for the first dog - and not if they answered the warm route.
		const warmAnswer = formData["nds-q-0.2-confirm-dog-still-lives-with-you"];

		const warmPreferNotToSay =
			questionSchema.routes.warm["nds-q-0.2"].fields[0].options[2].value;

		const warmPreferredNotToSay = warmAnswer === warmPreferNotToSay;
		if (currentDogIndex === 1 || warmPreferredNotToSay) {
			return [
				{ ...screenState, screen: "nds-q-4" },
				{
					...surveyProgress,
					aboutYourDog: 30,
					multiDog: multiDogAnswer
						? { ...surveyProgress.multiDog, aboutYourDog: 30 }
						: DEFAULT_MULTI_DOG_PROGRESS,
				},
			]; // Is <Dog’s Name> the first dog you have owned as an adult?
		}

		return [{ ...screenState, screen: "nds-q-5" }, surveyProgress]; // What is your dog’s name?
	}
	if (currentScreen === "nds-q-4") {
		//! Is <Dog’s Name> the first dog you have owned as an adult?
		return [
			{ ...screenState, screen: "nds-q-5" },
			{ ...surveyProgress, aboutYourDog: 40 },
		]; // How old is <Dog’s Name>?
	}
	if (currentScreen === "nds-q-5") {
		//! How old is <Dog’s Name>?
		return [
			{ ...screenState, screen: "nds-q-6" },
			{
				...surveyProgress,
				aboutYourDog: 50,
				multiDog: multiDogAnswer
					? { ...surveyProgress.multiDog, aboutYourDog: 50 }
					: DEFAULT_MULTI_DOG_PROGRESS,
			},
		]; //  Is <Dog’s Name> female or male?
	}
	if (currentScreen === "nds-q-6") {
		//! Is <Dog’s Name> female or male?
		return [
			{ ...screenState, screen: "nds-q-7" },
			{
				...surveyProgress,
				aboutYourDog: 60,
				multiDog: multiDogAnswer
					? { ...surveyProgress.multiDog, aboutYourDog: 60 }
					: DEFAULT_MULTI_DOG_PROGRESS,
			},
		]; // Which of the following best describes <Dog’s Name>? (breed question)
	}
	if (currentScreen === "nds-q-7") {
		//! Which of the following best describes <Dog’s Name>? (breed question)

		// TODO this is possibly going to come from 6.2 or 6.3 depending on what they decided in
		const answer = currentDogs[currentDogIndex]["nds-q-7-describe-breed"];

		const pureBreedOption =
			questionSchema.routes["about-your-dogs"]["nds-q-7"].fields[0].options[0]
				.value;
		const crossbreedOption =
			questionSchema.routes["about-your-dogs"]["nds-q-7"].fields[0].options[1]
				.value;

		if (answer === pureBreedOption) {
			return [
				{ ...screenState, screen: "nds-q-7.1" },
				{
					...surveyProgress,
					aboutYourDog: 70,
					multiDog: multiDogAnswer
						? { ...surveyProgress.multiDog, aboutYourDog: 70 }
						: DEFAULT_MULTI_DOG_PROGRESS,
				},
			]; // Purebreed Select one breed from breedlist
		}
		if (answer === crossbreedOption) {
			return [
				{ ...screenState, screen: "nds-q-7.2" },
				{
					...surveyProgress,
					aboutYourDog: 70,
					multiDog: multiDogAnswer
						? { ...surveyProgress.multiDog, aboutYourDog: 70 }
						: DEFAULT_MULTI_DOG_PROGRESS,
				},
			]; // Crossbreed Select two breeds from breedlist
		}
		return [
			{ ...screenState, screen: "nds-q-8" },
			{
				...surveyProgress,
				aboutYourDog: 70,
				multiDog: multiDogAnswer
					? { ...surveyProgress.multiDog, aboutYourDog: 70 }
					: DEFAULT_MULTI_DOG_PROGRESS,
			},
		]; // Breed not listed Complete free-text
	}
	if (currentScreen === "nds-q-7.1") {
		//! Purebreed Select one breed from breedlist

		return [
			{ ...screenState, screen: "nds-q-8" },
			{
				...surveyProgress,
				aboutYourDog: 80,
				multiDog: multiDogAnswer
					? { ...surveyProgress.multiDog, aboutYourDog: 80 }
					: DEFAULT_MULTI_DOG_PROGRESS,
			},
		]; // Your dog’s personality
	}
	if (currentScreen === "nds-q-7.2") {
		//! Crossbreed Select two breeds from breedlist
		return [
			{ ...screenState, screen: "nds-q-8" },
			{
				...surveyProgress,
				aboutYourDog: 80,
				multiDog: multiDogAnswer
					? { ...surveyProgress.multiDog, aboutYourDog: 80 }
					: DEFAULT_MULTI_DOG_PROGRESS,
			},
		]; // Your dog’s personality
	}

	if (currentScreen === "nds-q-8") {
		//! Your dog’s personality
		return [
			{ ...screenState, screen: "nds-q-9" },
			{
				...surveyProgress,
				aboutYourDog: 90,
				multiDog: multiDogAnswer
					? { ...surveyProgress.multiDog, aboutYourDog: 90 }
					: DEFAULT_MULTI_DOG_PROGRESS,
			},
		]; // Your dog’s favourite toy
	}
	if (currentScreen === "nds-q-9") {
		//! Your dog’s favorite toy

		// Only ask this question for the first dog.
		if (currentDogIndex === 1) {
			return [
				{
					...screenState,
					route: "reading-your-dog",
					screen: "out-and-about-with-your-dog-intro",
				},
				{ ...surveyProgress, aboutYourDog: 100 },
				"transition-end-about-your-dog",
			]; // How frequently do you walk <Dog’s Name> in the following locations?
		}

		return [
			{ ...screenState, route: "reading-your-dog", screen: "nds-q-11" },
			{
				...surveyProgress,
				multiDog: multiDogAnswer
					? { ...surveyProgress.multiDog, aboutYourDog: -1, lifeWithYourDog: 0 }
					: DEFAULT_MULTI_DOG_PROGRESS,
			},
		];
	}

	return [screenState, surveyProgress];
};
