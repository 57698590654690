import React from "react";

export const VolunteerVacanciesContext =
	React.createContext<VolunteerVacanciesContext>({
		vacanciesWithDistance: [],
		availableRoles: [],
		onChangeLocation: () => {},
		currentDistance: 0,
		userLocation: null,
		setVacancies: () => {},
	});
