/**
 * Gets the user profile from the my account API
 * @returns The user profile
 */
export const getProfile = async (): Promise<Profile | false> => {
	try {
		const profileResponse = await fetch(process.env.GATSBY_PROFILE_API_URL, {
			method: "GET",
			redirect: "manual",
			headers: {
				"Content-Type": "application/json",
			},
			credentials:
				typeof window !== "undefined" &&
				window.location.href.includes("localhost")
					? "same-origin"
					: "include",
		});

		if (profileResponse.ok) {
			return await profileResponse.json();
		} else {
			return false;
		}
	} catch (error) {
		console.error(error.message);
		return false;
	}
};

/**
 * Syncs the user profile with the my account API
 * @param profile The user profile to sync
 * @returns Whether the sync was successful
 */
export const syncProfile = async (profile: Profile): Promise<boolean> => {
	try {
		const profileResponse = await fetch(
			process.env.GATSBY_PROFILE_SYNC_API_URL,
			{
				method: "POST",
				redirect: "manual",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					...profile,
					interested: profile.interested.map((dog) =>
						process.env.GATSBY_ENVIRONMENT === "development"
							? dog
							: {
									apiKey: dog.apiKey,
							  },
					),
					favourites: profile.favourites.map((dog) =>
						process.env.GATSBY_ENVIRONMENT === "development"
							? dog
							: {
									apiKey: dog.apiKey,
							  },
					),
					basket: profile.basket.map((dog) =>
						process.env.GATSBY_ENVIRONMENT === "development"
							? dog
							: {
									apiKey: dog.apiKey,
									basketId: dog.basketId,
									isGift: dog.isGift,
							  },
					),
				}),
				credentials:
					typeof window !== "undefined" &&
					window.location.href.includes("localhost")
						? "same-origin"
						: "include",
			},
		);
		return profileResponse.ok;
	} catch (error) {
		console.error(error.message);
		return false;
	}
};

export const getSignInLink = () => {
	if (typeof window === "undefined")
		return process.env.GATSBY_PROFILE_SIGN_IN_URL;
	// The ptrt param is used to redirect the user back to the page they were on after logging in
	return `${process.env.GATSBY_PROFILE_SIGN_IN_URL}?ptrt=${window.location.pathname}`;
};
