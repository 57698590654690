import { DogClassLocation, DogClassRegion } from "./DogSchoolApi.types";

export const filterDogRegions = (
	dogRegion: DogClassRegion,
	selectedType: string,
	userLocation: Place,
	distance: number,
) => {
	if (selectedType === "1-2-1 classes" && !dogRegion.oneToOne) {
		return false;
	}
	if (
		!!userLocation && // The user has chosen their location
		!!dogRegion.userDistance && // The distance to the region has been calculated
		dogRegion.userDistance > distance // The region is too far away
	)
		return false;
	return true;
};

export const filterDogLocations = (
	dogClassLocation: DogClassLocation,
	selectedType: string,
	userLocation: Place,
	distance: number,
) => {
	if (selectedType === "1-2-1 classes" && !dogClassLocation.oneToOne) {
		return false;
	}
	if (
		!!userLocation && // The user has chosen their location
		!!dogClassLocation.userDistance && // The distance to the region has been calculated
		dogClassLocation.userDistance > distance // The region is too far away
	)
		return false;
	return true;
};
