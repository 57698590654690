import CurvedBorderThick from "@dogstrust/src/components/borders/CurvedBorderThick";
import FurryCurvedBorder from "@dogstrust/src/components/borders/FurryCurvedBorder";
import FuzzyBorderFullWidth from "@dogstrust/src/components/borders/FuzzyBorderFullWidth";
import { Colours } from "@dogstrust/src/utils/colours";
import cx from "classnames";
import React from "react";

import CurvedBorderShallow from "@dogstrust/src/components/borders/CurvedBorderShallow";
import ProcessedImage from "@dogstrust/src/components/common/ProcessedImage/ProcessedImage";
import * as styles from "./PageManager.module.scss";
import { getBackgroundStyle } from "./PageManager.utils";

interface BorderProps {
	currentSection?: SectionData;
	previousSection?: SectionData;
	previousSectionTheme?: string;
	nextSection?: SectionData;
	borderColour?: string;
	image?: ImageSourceProps;
	bottomMargin?: boolean;
}

export const SectionFurryBorderTop: React.FC<BorderProps> = ({
	previousSection,
	borderColour = Colours.SUNSHINE_YELLOW,
}) => {
	return (
		<div
			className={styles.borderwrapper}
			style={{
				backgroundColor: previousSection?.primaryColour || Colours.WHITE,
			}}
		>
			<FurryCurvedBorder className={styles.border} fill={borderColour} />
		</div>
	);
};

export const SectionFuzzyBorderTop: React.FC<BorderProps> = ({
	previousSection,
	borderColour = Colours.SUNSHINE_YELLOW,
}) => (
	<div
		className={styles.borderwrapper}
		style={{
			backgroundColor: previousSection?.primaryColour || Colours.WHITE,
		}}
	>
		<FuzzyBorderFullWidth className={styles.border} fill={borderColour} />
	</div>
);

export const SectionCurvedBorderTop: React.FC<BorderProps> = ({
	previousSection,
	previousSectionTheme,
	borderColour = Colours.SUNSHINE_YELLOW,
}) => {
	const backgroundColor =
		previousSectionTheme || previousSection?.primaryColour || Colours.WHITE;
	return (
		<div
			className={styles.borderwrapper}
			style={{
				backgroundColor,
			}}
		>
			<CurvedBorderShallow className={styles.border} fill={borderColour} />
		</div>
	);
};

export const SectionCurvedBorderTopWithImage: React.FC<BorderProps> = ({
	previousSection,
	previousSectionTheme,
	borderColour,
	image,
}) => {
	const backgroundColor =
		previousSectionTheme || previousSection?.primaryColour || Colours.WHITE;
	return (
		<div
			className={
				previousSection?.sectionType === "ParagraphContentSection"
					? styles.borderwithimagewrapper
					: styles.borderwithimagewrapperwithmargin
			}
			style={{
				backgroundColor,
			}}
		>
			<CurvedBorderThick
				className={styles.borderwithimage}
				fill={borderColour}
			/>
			<div className={styles.borderimage}>
				<div className={styles.imagewrapper}>
					<ProcessedImage
						src={image.src}
						alt={image.alt}
						dimensions="1080x810"
						width={1080}
						height={810}
					/>
				</div>
			</div>
		</div>
	);
};

export const SectionCurvedBorderBottomWithIcon: React.FC<BorderProps> = ({
	nextSection,
	borderColour,
	image,
}) => {
	const borderFill = nextSection?.primaryColour || Colours.WHITE;
	return (
		<div
			className={cx(
				styles.bordersectionbottom,
				getBackgroundStyle(borderColour),
			)}
		>
			<CurvedBorderThick
				className={styles.borderbottom}
				fill={borderFill}
				rotation={0}
			/>
			{!!image && (
				<div className={styles.borderbottomimagecontainer}>
					<ProcessedImage
						src={image.src}
						alt={image.alt}
						dimensions="200x200"
						width={200}
						height={200}
					/>
				</div>
			)}
		</div>
	);
};

export const SectionCurvedBorderBottom: React.FC<BorderProps> = ({
	nextSection,
	borderColour,
}) => {
	const borderFill = nextSection?.primaryColour || Colours.WHITE;
	return (
		<div
			className={cx(
				styles.bordersectionbottom,
				getBackgroundStyle(borderColour),
			)}
		>
			<CurvedBorderShallow
				className={styles.borderbottom}
				fill={borderFill}
				rotation={0}
			/>
		</div>
	);
};

export const SectionFuzzyBorderBottom: React.FC<BorderProps> = ({
	currentSection,
	nextSection,
	borderColour,
	bottomMargin,
}) => {
	const borderFill =
		nextSection?.sectionType === "ParagraphCaseStudy" ||
		currentSection?.sectionType === "ParagraphCaseStudy"
			? Colours.WHITE
			: nextSection?.primaryColour || Colours.WHITE;
	return (
		<div
			className={cx(
				bottomMargin
					? styles.bordersectionbottomwithmargin
					: styles.bordersectionbottom,
				getBackgroundStyle(borderColour),
			)}
		>
			<FuzzyBorderFullWidth
				className={styles.borderbottom}
				fill={borderFill}
				rotation={0}
			/>
		</div>
	);
};

export const SectionSlimFuzzyBorderBottom: React.FC<BorderProps> = ({
	currentSection,
	nextSection,
	borderColour,
	bottomMargin,
}) => {
	const borderFill =
		nextSection?.sectionType === "ParagraphCaseStudy" ||
		currentSection?.sectionType === "ParagraphCaseStudy"
			? Colours.WHITE
			: nextSection?.primaryColour || Colours.WHITE;
	return (
		<div
			className={cx(
				bottomMargin
					? styles.bordersectionbottomwithmargin
					: styles.bordersectionbottom,
				getBackgroundStyle(borderColour),
			)}
		>
			<FuzzyBorderFullWidth
				className={styles.borderbottom}
				fill={borderFill}
				rotation={0}
			/>
		</div>
	);
};

export const SectionFurryCurvedBorderBottom: React.FC<BorderProps> = ({
	nextSection,
	borderColour,
}) => {
	const borderFill = nextSection?.primaryColour || Colours.WHITE;
	return (
		<div
			className={cx(
				styles.bordersectionbottom,
				getBackgroundStyle(borderColour),
			)}
		>
			<FurryCurvedBorder
				className={styles.borderbottom}
				fill={borderFill}
				rotation={0}
			/>
		</div>
	);
};
