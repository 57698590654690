/**
 * Given the current screen and form data, returns the next screen for the non dog owner route
 * @param currentScreen	- The current screen
 * @param formData	- The form data
 * @param _questionSchema - The question schema
 * @returns The next screen
 */
export const getNextScreenNonDogOwnerRoute = (
	screenState: NDSScreenState,
	formData: NDSFormData,
	questionSchema: NDSQuestionSchema,
	_currentDogs: Record<number, Record<string, string | string[]>>,
	surveyProgress: NDSSurveyProgress,
): [NDSScreenState, NDSSurveyProgress] => {
	const currentScreen = screenState.screen;
	// ======================== Non Dog Owner Screens ========================
	if (currentScreen === "nds-q-E1") {
		//! Which of these statements best describes you?

		const question =
			questionSchema.routes["non-dog-owner"][currentScreen].fields[0].name;
		const answer = formData[question] as string[];
		const hadDogASAdultOption =
			questionSchema.routes["non-dog-owner"][currentScreen].fields[0].options[0]
				.value;

		const didHaveDogAsAdult = answer.includes(hadDogASAdultOption);

		// I used to have a dog as an adult
		if (didHaveDogAsAdult) {
			return [
				{ ...screenState, route: "non-dog-owner", screen: "nds-q-E1.1" },
				{ ...surveyProgress, attitudeToDogs: 10 },
			]; // How recently did you last have a dog?
		}

		// Never had a dog as an adult or had a dog as a child
		return [
			{ ...screenState, route: "non-dog-owner", screen: "nds-q-E2" },
			{ ...surveyProgress, attitudeToDogs: 30 },
		]; // Would you like to have a dog in the future?
	}

	if (currentScreen === "nds-q-E1.1") {
		//! How recently did you last have a dog?
		const positiveAnswer =
			questionSchema.routes["non-dog-owner"][currentScreen].fields[0].options[0]
				.value;

		const answer = formData["nds-q-E1.1-how-recently-last-dog"];

		// If they answer less than 12 months there is a follow up.
		if (answer === positiveAnswer) {
			return [
				{ ...screenState, route: "non-dog-owner", screen: "nds-q-E1.2" },
				{ ...surveyProgress, attitudeToDogs: 20 },
			]; // Why do you no longer have a dog?
		}

		return [
			{ ...screenState, route: "non-dog-owner", screen: "nds-q-E2" },
			{ ...surveyProgress, attitudeToDogs: 30 },
		]; // Would you like to have a dog in the future?
	}

	if (currentScreen === "nds-q-E1.2") {
		//! Why do you no longer have a dog?
		return [
			{ ...screenState, route: "non-dog-owner", screen: "nds-q-E2" },
			{ ...surveyProgress, attitudeToDogs: 30 },
		]; // Would you like to have a dog in the future?
	}

	if (currentScreen === "nds-q-E2") {
		//! Would you like to have a dog in the future?
		const question =
			questionSchema.routes["non-dog-owner"][currentScreen].fields[0].name;
		const answer = formData[question];
		const optionIndex = questionSchema.routes["non-dog-owner"][
			currentScreen
		].fields[0].options
			.map((option) => option.value)
			.indexOf(answer as string);

		// Yes, actively looking for a dog
		if (optionIndex === 0) {
			return [
				{ ...screenState, route: "non-dog-owner", screen: "nds-q-E3" },
				{ ...surveyProgress, attitudeToDogs: 50 },
			]; // Where would you search for a new dog?
		}

		// Yes they would like a dog but can't have one right now
		if (optionIndex === 1) {
			return [
				{ ...screenState, route: "non-dog-owner", screen: "nds-q-E2.1" },
				{ ...surveyProgress, attitudeToDogs: 40 },
			]; // What about your current situation prevents you from owning a dog?
		}

		// No they would not like a dog
		if (optionIndex === 2) {
			return [
				{ ...screenState, route: "non-dog-owner", screen: "nds-q-E2.2" },
				{ ...surveyProgress, attitudeToDogs: 40 },
			]; // Reasons for not wanting a dog?
		}

		// Not sure if they would like a dog / Prefer not to say
		return [
			{ ...screenState, route: "non-dog-owner", screen: "nds-q-E4" },
			{ ...surveyProgress, attitudeToDogs: 70 },
		]; // Body language question
	}

	if (currentScreen === "nds-q-E2.1") {
		//! What about your current situation prevents you from owning a dog?
		return [
			{ ...screenState, route: "non-dog-owner", screen: "nds-q-E3" },
			{ ...surveyProgress, attitudeToDogs: 50 },
		]; //  Where would they search for a new dog
	}

	if (currentScreen === "nds-q-E2.2") {
		//! Reasons for not wanting a dog?
		return [
			{ ...screenState, route: "non-dog-owner", screen: "nds-q-E4" },
			{ ...surveyProgress, attitudeToDogs: 70 },
		]; // Body language question
	}

	if (currentScreen === "nds-q-E3") {
		//! Where would you search for a new dog?
		const question =
			questionSchema.routes["non-dog-owner"][currentScreen].fields[0].name;
		const answer = formData[question] as string[];

		const ukBasedRehomingCentreOption =
			questionSchema.routes["non-dog-owner"][currentScreen].fields[0].options[0]
				.value;

		const wouldUseUkRehomingCentre = answer?.includes(
			ukBasedRehomingCentreOption,
		);

		if (!wouldUseUkRehomingCentre) {
			return [
				{ ...screenState, route: "non-dog-owner", screen: "nds-q-E3.1" },
				{ ...surveyProgress, attitudeToDogs: 60 },
			]; // Why would you not consider a UK rehoming centre?
		}
		return [
			{ ...screenState, route: "non-dog-owner", screen: "nds-q-E4" },
			{ ...surveyProgress, attitudeToDogs: 70 },
		]; // Body language question
	}

	if (currentScreen === "nds-q-E3.1") {
		//! Why would you not consider a UK rehoming centre?
		return [
			{ ...screenState, route: "non-dog-owner", screen: "nds-q-E4" },
			{ ...surveyProgress, attitudeToDogs: 70 },
		]; // Body language question
	}

	if (currentScreen === "nds-q-E4") {
		//! Body language question
		return [
			{ ...screenState, route: "non-dog-owner", screen: "nds-q-E5" },
			{ ...surveyProgress, attitudeToDogs: 80 },
		]; // Would you be interested in hearing more about Fostering/Sponsoring?
	}

	if (currentScreen === "nds-q-E5") {
		//! Would you be interested in hearing more about Fostering/Sponsoring?
		return [
			{ ...screenState, route: "non-dog-owner", screen: "nds-q-E6" },
			{ ...surveyProgress, attitudeToDogs: 90 },
		]; // Welfare question
	}

	if (currentScreen === "nds-q-E6") {
		//! Welfare question
		return [
			{ ...screenState, route: "about-you", screen: "about-you-non-dog-intro" },
			{ ...surveyProgress, attitudeToDogs: 100 },
		]; // About you intro
	}

	// Default return statement
	return [screenState, surveyProgress];
};
