import { isInRangeFilter } from "@dogstrust/src/utils/distance";
import type { OurCentresAction } from "./OurCentres.actions";
import { CentresSortOption, OurCentresState } from "./OurCentres.types";

export const ourCentresDefaultState: OurCentresState = {
	ourCentresLoaded: false,
	selectedCentreCode: "",
	centreLocations: [],
	centreNames: {},
	centresInRange: [],
	addressSuggestions: [],
	sortBy: "A",
	pagination: 0,
	currentDistance: 1000,
	userLocation: null,
	locationSearchTerm: "",
	distanceToCentres: {},
};
export function ourCentresReducer(
	state: OurCentresState,
	action: OurCentresAction,
): OurCentresState {
	switch (action.type) {
		case "LOAD_ALL_CENTRES":
			if (state.ourCentresLoaded) return state;
			return {
				...state,
				pagination: 0,
				ourCentresLoaded: true,
				centreLocations: [...action.allCentres],
				centresInRange: [...action.allCentres],
			};
		case "SELECT_CENTRE":
			return {
				...state,
				selectedCentreCode:
					state.selectedCentreCode === action.centreCode
						? ""
						: action.centreCode,
			};
		case "UPDATE_SORT_OPTION":
			return {
				...state,
				pagination: 0,
				sortBy: action.selectedOption,
			};

		case "LOAD_MORE":
			return {
				...state,
				pagination: state.pagination + 1,
			};

		case "UPDATE_TRAVEL_DISTANCE": {
			const centresInRange1 = state.centreLocations.filter((centre) =>
				isInRangeFilter(
					{
						latitude: parseFloat(centre.field_latitude),
						longitude: parseFloat(centre.field_longitude),
						address: centre.title,
					},
					state.userLocation,
					action.distance,
				),
			);

			return {
				...state,
				pagination: 0, // Reset pagination as results will change
				centresInRange: centresInRange1,
				currentDistance: action.distance,
				userLocation: {
					...state.userLocation,
				},
			};
		}

		case "UPDATE_LOCATION": {
			const centresInRange2 = state.centreLocations.filter((centre) =>
				isInRangeFilter(
					{
						latitude: parseFloat(centre.field_latitude),
						longitude: parseFloat(centre.field_longitude),
						address: centre.title,
					},
					action.userLocation,
					action.distance,
				),
			);

			// Update the sorting if the user selects location for first time
			const newSortBy: CentresSortOption = !state.userLocation
				? "Near"
				: state.sortBy;
			return {
				...state,
				sortBy: newSortBy,
				locationSearchTerm: action.userLocation?.address || "",
				pagination: 0, // Reset pagination as results will change
				centresInRange: centresInRange2,
				userLocation: action.userLocation,
				currentDistance: action.distance,
			};
		}

		case "CLEAR_USER_LOCATION":
			return {
				...state,
				pagination: 0, // Reset pagination as results will change
				locationSearchTerm: "",
				currentDistance: 1000,
				userLocation: null,
				centresInRange: state.centreLocations,
			};

		case "UPDATE_CENTRES_IN_RANGE":
			return {
				...state,
				centresInRange: action.centresInRange,
				distanceToCentres: action.distanceToCentres,
				selectedCentreCode: action.centresInRange
					.map((centre) => centre.relationships.field_centre_code.name)
					.includes(state.selectedCentreCode)
					? state.selectedCentreCode
					: "",
			};
		default:
			return state;
	}
}
