import { getNextScreenAboutYouRoute } from "./aboutYou";
import { getNextScreenAboutYourDogsRoute } from "./aboutYourDog";
import { getNextScreenGlobal } from "./global";
import { getNextScreenNonDogOwnerRoute } from "./nonDogOwner";
import { getNextScreenReadingYourDogsRoute } from "./readingYourDog";
import { getNextScreenWarmRoute } from "./warm";
import { getNextScreenWellbeingAndSupportRoute } from "./wellbeingAndSupport";

const nextScreenRouteFunctionMap: Record<
	NDSRoute,
	(
		screenState: NDSScreenState,
		formData: NDSFormData,
		questionSchema: NDSQuestionSchema,
		currentDogs: Record<number, Record<string, string | string[]>>,
		surveyProgress: NDSSurveyProgress,
	) =>
		| [NDSScreenState, NDSSurveyProgress]
		| [NDSScreenState, NDSSurveyProgress, NDSTransition]
> = {
	"about-you": getNextScreenAboutYouRoute,
	"about-your-dogs": getNextScreenAboutYourDogsRoute,
	"reading-your-dog": getNextScreenReadingYourDogsRoute,
	"non-dog-owner": getNextScreenNonDogOwnerRoute,
	warm: getNextScreenWarmRoute,
	"wellbeing-and-support": getNextScreenWellbeingAndSupportRoute,
	global: getNextScreenGlobal,
};

/**
 * Given the current screen and form data, returns the next screen
 * @param currentScreen - The current screen
 * @param formData - The form data
 * @returns The next screen
 */
export const getNextScreen = (
	screenState: NDSScreenState,
	formData: NDSFormData,
	questionSchema: NDSQuestionSchema,
	currentDogs: Record<number, Record<string, string | string[]>>,
	surveyProgress: NDSSurveyProgress,
):
	| [NDSScreenState, NDSSurveyProgress]
	| [NDSScreenState, NDSSurveyProgress, NDSTransition] =>
	nextScreenRouteFunctionMap[screenState.route](
		screenState,
		formData,
		questionSchema,
		currentDogs,
		surveyProgress,
	);
// https://www.figma.com/file/rrfjcBq2GEHpWpQc7XerHB/Questions-and-logic?type=whiteboard&node-id=0-1&t=6rE5PTd5jIUaHAcT-0
