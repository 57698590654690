import { CharityShopsSortOption } from "./CharityShops.types";

// ========================== LOAD_ALL_CHARITYS_SHOPS ===================================
export const LOAD_ALL_CHARITY_SHOPS = "LOAD_ALL_CHARITY_SHOPS";
interface LoadCharityShopsAction {
	type: typeof LOAD_ALL_CHARITY_SHOPS;
	allCharityShops: Queries.CharityShopDataFragment[];
}

export const onLoadCharityShopsAction = (
	allCharityShops: Queries.CharityShopDataFragment[],
): LoadCharityShopsAction => ({
	type: LOAD_ALL_CHARITY_SHOPS,
	allCharityShops,
});

// ========================== SELECT_CHARITY_SHOP ===================================
export const SELECT_CHARITY_SHOP = "SELECT_CHARITY_SHOP";
interface SelectCharityShopsAction {
	type: typeof SELECT_CHARITY_SHOP;
	charityShopName: string;
}

export const onSelectCharityShopsAction = (
	charityShopName: string,
): SelectCharityShopsAction => ({
	type: SELECT_CHARITY_SHOP,
	charityShopName,
});

// ========================== UPDATE_SORT_OPTION ===================================
export const UPDATE_SORT_OPTION = "UPDATE_SORT_OPTION";
interface UpdateSortOptionAction {
	type: typeof UPDATE_SORT_OPTION;
	selectedOption: CharityShopsSortOption;
}

export const onUpdateSortOptionAction = (
	selectedOption: CharityShopsSortOption,
): UpdateSortOptionAction => ({
	type: UPDATE_SORT_OPTION,
	selectedOption,
});

// ========================== LOAD_MORE ==================================
export const LOAD_MORE = "LOAD_MORE";
interface LoadMoreAction {
	type: typeof LOAD_MORE;
}
export const onLoadMoreAction = (): LoadMoreAction => ({
	type: LOAD_MORE,
});

// ========================== UPDATE_TRAVEL_DISTANCE =========================
export const UPDATE_TRAVEL_DISTANCE = "UPDATE_TRAVEL_DISTANCE";
interface UpdateTravelDistanceAction {
	type: typeof UPDATE_TRAVEL_DISTANCE;
	distance: number;
}
export const onUpdateTravelDistanceAction = (
	distance: number,
): UpdateTravelDistanceAction => ({
	type: UPDATE_TRAVEL_DISTANCE,
	distance,
});

// ========================== UPDATE_LOCATION_SUGGESTIONS ====================
export const UPDATE_LOCATION_SUGGESTIONS = "UPDATE_LOCATION_SUGGESTIONS";
interface UpdateLocationSuggestionsAction {
	type: typeof UPDATE_LOCATION_SUGGESTIONS;
	locations: string[];
}
export const onUpdateLocationSuggestionsAction = (
	locations: string[],
): UpdateLocationSuggestionsAction => {
	return {
		type: UPDATE_LOCATION_SUGGESTIONS,
		locations,
	};
};

// ========================== UPDATE_LOCATION ==========================
export const UPDATE_LOCATION = "UPDATE_LOCATION";
interface UpdateLocationAction {
	type: typeof UPDATE_LOCATION;
	userLocation: Place | null;
	distance: number;
}

export const onUpdateLocationAction = (
	userLocation: Place | null,
	distance: number,
): UpdateLocationAction => ({ type: UPDATE_LOCATION, userLocation, distance });

// ========================== UPDATE_CHARITY_SHOPS_IN_RANGE ==========================
export const UPDATE_CHARITY_SHOPS_IN_RANGE = "UPDATE_CHARITY_SHOPS_IN_RANGE";
interface UpdateCharityShopsInRangeAction {
	type: typeof UPDATE_CHARITY_SHOPS_IN_RANGE;
	charityShopsInRange: Queries.CharityShopDataFragment[];
	distanceToCharityShops: { [key: string]: number };
}

export const onUpdateCharityShopsInRangeAction = (
	charityShopsInRange: Queries.CharityShopDataFragment[],
	distanceToCharityShops: { [key: string]: number },
): UpdateCharityShopsInRangeAction => ({
	type: UPDATE_CHARITY_SHOPS_IN_RANGE,
	charityShopsInRange,
	distanceToCharityShops,
});

export type CharityShopsAction =
	| LoadCharityShopsAction
	| SelectCharityShopsAction
	| UpdateSortOptionAction
	| LoadMoreAction
	| UpdateTravelDistanceAction
	| UpdateLocationSuggestionsAction
	| UpdateLocationAction
	| UpdateCharityShopsInRangeAction;
