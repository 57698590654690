import { QuickLinksItemProps } from "@dogstrust/src/components/common/QuickLinksItem";
import { formatDate } from "@dogstrust/src/utils/dates";
import { SignPostCardProps } from "../components/SignPostCards";
import { BreedCardProps } from "../components/SignPostCards/BreedListingCard/BreedListingCard";
import { DogCardProps } from "../components/SignPostCards/DogListingCard";
import {
	PromoType,
	PromotionCardToDisplayProps,
} from "../components/SignPostCards/PromotionCards/PromotionCardToDisplay";
import { SponsorDogCardProps } from "../components/SignPostCards/SponsorDogCard/SponsorDogCard";
import SponsorDogPromoCardSquareProps from "../components/SignPostCards/SponsorDogPromoCard/Square/SponsorDogPromoCardSquare";
import { VolunteerVacancyCardProps } from "../components/SignPostCards/VolunteerVacancyCard/VolunteerVacancyCard";
import {
	MediaImageProps,
	MediaVideoProps,
} from "../components/common/MediaCarousel/MediaCarousel";
import { VacancyWithDistance } from "../containers/sections/SectionVolunteerVacancyFiltersList/SectionVolunteerVacancyFiltersList";
import { getDogHeroImage, orderDogImages } from "./dog";

export const mapNodeDogToEsDogDoc = (
	dogData: Queries.DogFragment,
): ESDocDog => ({
	apiKey: dogData.apiKey,
	key: dogData.field_dog_key,
	id: dogData.id,
	name: dogData.title,
	url: dogData.path.alias,
	created: dogData.field_dog_external_creation_date,
	isPublished: dogData.status,
	gender: dogData.field_dog_gender,
	dob: dogData.field_dob,
	breed: dogData.relationships.field_dog_breed?.name,
	crossbreed: dogData.relationships.field_crossbreed?.name,
	isCrossBreed: dogData.field_iscrossbreed,
	frontEndBreedName: dogData.frontEndBreedName,
	size: dogData.relationships.field_dog_dog_size?.name,
	centreName: dogData.thisDogsCentre?.title,
	centreCode: dogData.relationships.field_dog_location?.name,
	status: dogData.relationships.field_dog_status?.name,
	liveWithCats: dogData.field_dog_with_cats,
	liveWithDogs: dogData.field_dog_with_dogs,
	liveWithPreschool: dogData.field_dog_with_preschool,
	liveWithPrimary: dogData.field_dog_with_primary,
	liveWithSecondary: dogData.field_dog_with_secondary,
	needsMedicalCare: dogData.field_needs_medical_care,
	media: {
		images: dogData.media.images.map(
			(dogImage) => ({ ...dogImage }) as DogImage,
		),
		videos: dogData.media.videos.map(
			(dogVideo) => ({ ...dogVideo }) as DogVideo,
		),
	},
	info: dogData.field_info,
	likesDislikes: dogData.field_likesdislikes,
	homeRequirements: dogData.field_typeofhomerequired,
	isReserved: !!dogData.field_isreserved,
	isHomeFromHome: !!dogData.field_ishomefromhome,
	isUnderdog: !!dogData.field_isunderdog,
	location: {
		latitude: parseFloat(dogData.thisDogsCentre?.field_latitude),
		longitude: parseFloat(dogData.thisDogsCentre?.field_longitude),
	},
	imgUrl: dogData.media?.images?.[0]?.src || "",
});

export const mapEsDogDocToSignpostCard = (
	dog: ESDocDog,
	userLocation?: Place,
	queryStrings?: string,
): DogCardProps => ({
	id: dog.id,
	apiKey: dog.apiKey,
	userLocation: userLocation,
	queryStrings: queryStrings,
	type: "DogListingCard",
	name: dog.name,
	frontEndBreedName: dog.frontEndBreedName,
	gender: dog.gender,
	dob: dog.dob,
	size: dog.size,
	centreName: dog.centreName,
	media: dog.media,
	url: dog.url,
	linkId: dog.url,
	isReserved: dog.isReserved,
	isUnderdog: dog.isUnderdog,
	dog: dog,
});
export const mapCentreArticleToSignpostCard = (
	relatedArticles: Queries.node__centre_article[],
): SignPostCardProps[] =>
	relatedArticles.map((article, i) => ({
		priority: i,
		title: article.title,
		body: article.field_centre_article_intro_text,
		buttonLabel: article.field_short_link_text,
		buttonUrl: article.path.alias,
		imgSrc:
			article.relationships?.field_hero_image?.field_media_image?.uri || "",
		imgAlt: article.relationships?.field_hero_image?.field_media_image?.alt,
	}));

export const mapVolunteerVacancyToSignpostCard = (
	vacancies: (Queries.VolunteerVacancyDataFragment | VacancyWithDistance)[],
): VolunteerVacancyCardProps[] =>
	vacancies?.map((vacancy) => ({
		volunteerRole: vacancy.relationships.field_volunteer_role.title.trim(),
		city: vacancy.field_city,
		url: vacancy.path.alias,
		latitude: vacancy.field_latitude,
		longitude: vacancy.field_longitude,
		date: vacancy.field_date,
		distance: (vacancy as VacancyWithDistance).distance,
		vacancyLocation: vacancy.vacancyLocation,
	}));

export const mapSponsorDogToCard = (
	dogs: SponsorDogWithDistance[],
): SponsorDogCardProps[] =>
	dogs.map((dog) => ({
		id: dog.fundCode,
		fundCode: dog.fundCode,
		name: dog.title,
		breed: dog.relationships.field_breed?.[0]?.name,
		gender: dog.field_sex,
		centreName: dog.rehomingCentre.title,
		galleryItems: [
			dog.relationships.field_featured_image,
			...dog.relationships.field_gallery_of_images_or_video,
		]
			.filter((media) => media.__typename === "media__image")
			.map((image: Queries.MediaImageOrVideoData_media__image_Fragment) => ({
				type: "image",
				src: image.field_media_image.uri,
				alt: image.field_media_image.alt,
			})),
		url: dog.path.alias,
		distance: dog.distance,
	}));

export const mapSponsorDogToSquareCard = (
	dog: Queries.SponsorDogDataFragment,
): SponsorDogPromoCardSquareProps => ({
	type: "SponsorDogPromoCardSquare",
	title: dog.title,
	imgSrc: dog.relationships.field_featured_image.field_media_image.uri,
	imgAlt: dog.relationships.field_featured_image.field_media_image.alt,
	text: dog.field_promo_short_text,
	url: dog.path.alias,
});

export const getParagraphSections = (
	sectionsToMap: Queries.ParagraphSectionsDataFragment[],
): (
	| Queries.ParagraphSectionsDataFragment
	| Queries.ParagraphSectionsDataFragment[]
)[] => {
	const sections: (
		| Queries.ParagraphSectionsDataFragment
		| Queries.ParagraphSectionsDataFragment[]
	)[] = [];

	let sectionGroup: Queries.ParagraphSectionsDataFragment[] = [];

	for (const section of sectionsToMap) {
		if (section.__typename === "paragraph__content_section") {
			// It's a content section so add it to the group.
			sectionGroup.push(section);
		} else if (
			section.__typename === "paragraph__centre_aligned_content_section"
		) {
			// It's a content section so add it to the group.
			sectionGroup.push(section);
		} else {
			if (sectionGroup.length > 0) {
				// If there is a group of paragraph__content_section to add from previous iterations add it now
				sections.push(sectionGroup);
				// Then clear it.
				sectionGroup = [];
			}

			// Push the section
			sections.push(section);
		}
	}

	// Content section could have been the last elements so don't forget to add the groups.
	if (sectionGroup.length > 0) {
		sections.push(sectionGroup);
	}

	return sections;
};

export const mapArticleToSignpostCard = (
	link: Queries.SignpostUnionDataFragment,
) => {
	switch (link.__typename) {
		case "node__article":
			return {
				priority: link.field_node_weight,
				title: link.title,
				body: link.field_short_description,
				buttonLabel: link.field_short_link_text || "Read more",
				buttonUrl: link.path?.alias,
				imgSrc:
					link.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
				imgAlt:
					link.relationships?.field_featured_image?.field_media_image?.alt,
				label: link.relationships?.field_category_type?.name,
			} as SignPostCardProps;

		case "node__external_signposts":
			return {
				priority: 1,
				title: link.title,
				body: link.field_external_signpost_summary,
				buttonLabel: link.field_link.title || "Read more",
				buttonUrl: link.field_link.uri,
				externalUrl: link.field_link.uri,
				imgSrc: link.relationships?.field_image?.field_media_image?.uri || "",
			} as SignPostCardProps;

		case "node__landing_page":
			return {
				priority: 1,
				isRTE: true,
				title: link.title,
				body: link.field_intro_text?.value,
				buttonLabel: link.field_short_link_text || "Read more",
				buttonUrl: link.path.alias,
				imgSrc:
					link.relationships?.field_images?.[0]?.field_media_image?.uri || "",
			} as SignPostCardProps;

		case "node__static_internal_page_reference":
			return {
				priority: 1,
				title: link.title,
				body: link.field_summary,
				buttonLabel: link.field_link.title,
				buttonUrl: link.field_link.uri,
				imgSrc: link.relationships?.field_image?.field_media_image?.uri || "",
			} as SignPostCardProps;

		case "node__our_story":
			return {
				title: link.title,
				priority: link.field_node_weight,
				body: link.field_short_description,
				buttonLabel: link.field_short_link_text || "Read more",
				buttonUrl: link.path.alias,
				imgSrc:
					link.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
			} as SignPostCardProps;
	}
};

export const mapPromotedItemToSignpostCard = (
	link: Queries.PromoteToUnionDataFragment,
) => {
	switch (link.__typename) {
		case "node__article":
			return {
				title: link.title,
				body: link.field_short_description,
				buttonLabel: link.field_short_link_text || "Read more",
				buttonUrl: link.path.alias,
				imgSrc:
					link.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
				imgAlt:
					link.relationships?.field_featured_image?.field_media_image?.alt,
				label: link.relationships?.field_category_type?.name,
			} as SignPostCardProps;

		case "node__external_signposts":
			return {
				title: link.title,
				body: link.field_external_signpost_summary,
				buttonLabel: link.field_link.title || "Read more",
				buttonUrl: link.field_link.uri,
				externalUrl: link.field_link.uri,
				imgSrc: link.relationships?.field_image?.field_media_image?.uri || "",
			} as SignPostCardProps;

		case "node__landing_page":
			return {
				isRTE: true,
				title: link.title,
				body: link.field_intro_text?.value,
				buttonLabel: link.field_short_link_text || "Read more",
				buttonUrl: link.path.alias,
				imgSrc:
					link.relationships?.field_images?.[0]?.field_media_image?.uri || "",
			} as SignPostCardProps;

		case "node__static_internal_page_reference":
			return {
				title: link.title,
				body: link.field_summary,
				buttonLabel: link.field_link.title || "Read more",
				buttonUrl: link.field_link.uri,
				imgSrc: link.relationships?.field_image?.field_media_image?.uri || "",
			} as SignPostCardProps;

		case "node__our_story":
			return {
				title: link.title,
				body: link.field_short_description,
				buttonLabel: link.field_short_link_text || "Read more",
				buttonUrl: link.path.alias,
				imgSrc:
					link.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
			} as SignPostCardProps;
	}
};

export const mapPromotedItemToBreedCard = (
	link: Queries.PromoteToUnionDataFragment,
) => {
	switch (link.__typename) {
		case "node__breed":
			return {
				title: link.title,
				field_intro_copy: link.field_intro_copy,
				...link,
			};
	}
};

export const mapContentUnionToSignPostCardProps = (
	content: Queries.ContentUnionDataFragment,
): SignPostCardProps => {
	switch (content.__typename) {
		case "node__article":
			return {
				title: content.title,
				drupal_id: content.drupal_id,
				priority: content.field_node_weight,
				body: content.field_short_description,
				buttonLabel: content.field_short_link_text || "Read more",
				buttonUrl: content.path.alias,
				imgSrc:
					content.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
				imgAlt:
					content.relationships?.field_featured_image?.field_media_image?.alt ||
					"",
				label: content.relationships?.field_category_type?.name,
			};
		case "node__appeal":
			return {
				title: content.title,
				body: content.field_short_description,
				buttonLabel: content.field_short_link_text || "Read more",
				buttonUrl: content.path.alias,
				imgSrc:
					content.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
				imgAlt:
					content.relationships?.field_featured_image?.field_media_image?.alt ||
					"",
				label: "Appeal",
			};
		case "node__our_story":
			return {
				title: content.title,
				priority: content.field_node_weight,
				body: content.field_short_description,
				buttonLabel: content.field_short_link_text || "Read more",
				buttonUrl: content.path.alias,
				imgSrc:
					content.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
				imgAlt:
					content.relationships?.field_featured_image?.field_media_image?.alt ||
					"",
				label: content.relationships?.field_category_type?.name,
			};
		case "node__page":
			return {
				title: content.title,
				buttonLabel: "Read more",
				buttonUrl: content.path.alias,
				imgSrc:
					content.relationships?.field_images?.[0]?.field_media_image?.uri ||
					"",
				imgAlt:
					content.relationships?.field_images?.[0]?.field_media_image?.alt ||
					"",
			};
		case "node__breed":
			return {
				title: content.title,
				body: content.field_intro_copy,
				buttonLabel: "Read more",
				buttonUrl: content.path.alias,
				imgSrc:
					content.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
				imgAlt:
					content.relationships?.field_featured_image?.field_media_image?.alt ||
					"",
			};
		case "node__event":
			return {
				title: content.title,
				buttonLabel: "Read more",
				buttonUrl: content.path.alias,
				imgSrc:
					content.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
				imgAlt:
					content.relationships?.field_featured_image?.field_media_image?.alt ||
					"",
				label: formatDate(new Date(content.field_event_date)),
			};
		case "node__centre":
			return {
				title: content.title,
				buttonLabel: "Read more",
				buttonUrl: content.path.alias,
				imgSrc:
					content.relationships?.field_centre_images?.[0]?.field_media_image
						?.uri || "",
				imgAlt:
					content.relationships?.field_centre_images?.[0]?.field_media_image
						?.alt || "",
			};
		case "node__centre_article":
			return {
				title: content.title,
				buttonLabel: content.field_short_link_text || "Read more",
				buttonUrl: content.path.alias,
				imgSrc:
					content.relationships?.field_hero_image?.field_media_image?.uri || "",
				imgAlt:
					content.relationships?.field_hero_image?.field_media_image?.alt || "",
			};
		case "node__charity_shop":
			return {
				title: content.title,
				buttonLabel: "Read more",
				buttonUrl: content.path.alias,
				imgSrc:
					content.relationships?.field_shop_images?.[0]?.field_media_image
						?.uri || "",
				imgAlt:
					content.relationships?.field_shop_images?.[0]?.field_media_image
						?.alt || "",
			};
		case "node__landing_page":
			return {
				title: content.title,
				drupal_id: content.drupal_id,
				body: content.field_intro_text?.value,
				isRTE: true,
				buttonLabel: content.field_short_link_text || "Read more",
				buttonUrl: content.path.alias,
				imgSrc:
					content.relationships?.field_images?.[0]?.field_media_image?.uri ||
					"",
				imgAlt:
					content.relationships?.field_images?.[0]?.field_media_image.alt || "",
			};
		case "node__topic":
			return {
				title: content.title,
				drupal_id: content.drupal_id,
				body: content.field_short_description,
				buttonLabel: content.field_short_link_text || "Read more",
				buttonUrl: content.path.alias,
				imgSrc:
					content.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
				label: content.relationships?.field_parent?.name,
				imgAlt:
					content.relationships?.field_featured_image?.field_media_image?.alt ||
					"",
			};
		case "node__static_internal_page_reference":
			return {
				title: content.field_link.title,
				priority: content.field_node_weight,
				body: content.field_summary,
				buttonLabel: content.field_link.title,
				buttonUrl: content.field_link.uri,
				imgSrc:
					content.relationships?.field_image?.field_media_image?.uri || "",
				imgAlt:
					content.relationships?.field_image?.field_media_image?.alt || "",
			};
	}
};

export const mapContentUnionToQuickLinksProps = (
	content: Queries.ContentUnionDataFragment,
): QuickLinksItemProps => {
	switch (content.__typename) {
		case "node__article":
			return {
				title: content.title,
				priority: content.field_node_weight,

				imgSrc:
					content.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
				imgAlt:
					content.relationships?.field_featured_image?.field_media_image?.alt ||
					"",
				url: content.path.alias,
			};
		case "node__appeal":
			return {
				title: content.title,
				imgSrc:
					content.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
				imgAlt:
					content.relationships?.field_featured_image?.field_media_image?.alt ||
					"",
				url: content.path.alias,
			};
		case "node__our_story":
			return {
				title: content.title,
				priority: content.field_node_weight,
				imgSrc:
					content.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
				imgAlt:
					content.relationships?.field_featured_image?.field_media_image?.alt ||
					"",
				url: content.path.alias,
			};
		case "node__page":
			return {
				title: content.title,
				imgSrc:
					content.relationships?.field_images?.[0]?.field_media_image?.uri ||
					"",
				imgAlt:
					content.relationships?.field_images?.[0]?.field_media_image?.alt ||
					"",
				url: content.path.alias,
			};
		case "node__breed":
			return {
				title: content.title,
				imgSrc:
					content.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
				imgAlt:
					content.relationships?.field_featured_image?.field_media_image?.alt ||
					"",
				url: content.path.alias,
			};
		case "node__event":
			return {
				title: content.title,
				imgSrc:
					content.relationships?.field_featured_image?.field_media_image?.uri ||
					"",
				imgAlt:
					content.relationships?.field_featured_image?.field_media_image?.alt ||
					"",
				url: content.path.alias,
			};
		case "node__centre":
			return {
				title: content.title,
				imgSrc:
					content.relationships?.field_centre_images?.[0]?.field_media_image
						?.uri || "",
				imgAlt:
					content.relationships?.field_centre_images?.[0]?.field_media_image
						?.alt || "",
				url: content.path.alias,
			};
		case "node__centre_article":
			return {
				title: content.title,
				imgSrc:
					content.relationships?.field_hero_image?.field_media_image?.uri || "",
				imgAlt:
					content.relationships?.field_hero_image?.field_media_image?.alt || "",
				url: content.path.alias,
			};
		case "node__charity_shop":
			return {
				title: content.title,
				imgSrc:
					content.relationships?.field_shop_images?.[0]?.field_media_image
						?.uri || "",
				imgAlt:
					content.relationships?.field_shop_images?.[0]?.field_media_image
						?.alt || "",
				url: content.path.alias,
			};
		case "node__landing_page":
			return {
				title: content.title,
				imgSrc:
					(content as Queries.LandingPageSignPostDataFragment).relationships
						?.field_icon?.field_media_image?.uri || "",
				imgAlt:
					(content as Queries.LandingPageSignPostDataFragment).relationships
						?.field_icon?.field_media_image?.alt || "",
				url: content.path.alias,
			};
		case "node__topic":
			return {
				title: content.title,
				imgSrc:
					(content as Queries.TopicFragment).relationships?.field_icon
						?.field_media_image?.uri || "",
				imgAlt:
					(content as Queries.TopicFragment).relationships?.field_icon
						?.field_media_image?.alt || "",
				url: content.path.alias,
			};
		case "node__static_internal_page_reference":
			return {
				title: content.title,
				imgSrc:
					content.relationships?.field_image?.field_media_image?.uri || "",
				imgAlt:
					content.relationships?.field_image?.field_media_image?.alt || "",
				url: content.field_link.uri,
			};
	}
};

export const mapOurStoryToSignpostCard = (
	story: Queries.OurStoryFragment,
): SignPostCardProps => ({
	title: story.title,
	imgSrc: story.relationships?.field_featured_image?.field_media_image?.uri,
	imgAlt: story.relationships?.field_featured_image?.field_media_image?.alt,
	body: story.field_short_description,
	buttonLabel: story.field_short_link_text,
	buttonUrl: story.path.alias,
	date: story.field_date,
	topics: story.relationships?.field_related_our_story_topics?.map(
		(topic) => topic.name,
	),
});

export const mapBreedToBreedListingCard = (
	breed: Queries.BreedDataFragment,
): BreedCardProps => ({
	name: breed.title,
	imgSrc: breed.relationships?.field_featured_image?.field_media_image?.uri,
	imgAlt: breed.relationships?.field_featured_image?.field_media_image?.alt,
	description: breed.field_intro_copy,
	url: breed.path.alias,
	shedding: breed.relationships?.field_shedding.name,
	size: breed.relationships?.field_size.name,
});
export const mapArticleToQuickLink = (
	link: Queries.SignpostUnionDataFragment,
) => {
	switch (link.__typename) {
		case "node__article":
			return {
				priority: link.field_node_weight,
				title: link.field_short_link_text,
				url: link.path.alias,
				imgSrc: link.relationships?.field_icon?.field_media_image?.uri || "",
				imgAlt: link.relationships?.field_icon?.field_media_image?.alt || "",
			} as QuickLinksItemProps;

		case "node__external_signposts":
			return {
				priority: 1,
				title: link.title,
				url: link.field_link.uri,
				imgSrc: link.relationships?.field_image?.field_media_image?.uri || "",
				imgAlt: link.relationships?.field_image?.field_media_image?.alt || "",
			} as QuickLinksItemProps;

		case "node__static_internal_page_reference":
			return {
				priority: link.field_node_weight,
				title: link.field_link.title,
				url: link.field_link.uri,
				imgSrc: link.relationships?.field_icon?.field_media_image?.uri || "",
				imgAlt: link.relationships?.field_icon?.field_media_image?.alt || "",
			} as QuickLinksItemProps;

		case "node__landing_page":
			return {
				priority: 1,
				title: link.field_short_link_text,
				url: link.path.alias,
				imgSrc: link.relationships?.field_icon?.field_media_image?.uri || "",
				imgAlt: link.relationships?.field_icon?.field_media_image?.alt || "",
			} as QuickLinksItemProps;
	}
};

export const mapPromotedItemsToQuickLink = (
	link: Queries.PromoteToUnionDataFragment,
) => {
	switch (link.__typename) {
		case "node__article":
			return {
				title: link.field_short_link_text,
				url: link.path.alias,
				imgSrc: link.relationships?.field_icon?.field_media_image?.uri || "",
				imgAlt: link.relationships?.field_icon?.field_media_image?.alt || "",
			} as QuickLinksItemProps;

		case "node__external_signposts":
			return {
				title: link.title,
				url: link.field_link.uri,
				imgSrc: link.relationships?.field_image?.field_media_image?.uri || "",
				imgAlt: link.relationships?.field_image?.field_media_image?.alt || "",
			} as QuickLinksItemProps;

		case "node__static_internal_page_reference":
			return {
				title: link.field_link.title,
				url: link.field_link.uri,
				imgSrc: link.relationships?.field_icon?.field_media_image?.uri || "",
				imgAlt: link.relationships?.field_icon?.field_media_image?.alt || "",
			} as QuickLinksItemProps;

		case "node__landing_page":
			return {
				title: link.field_short_link_text,
				url: link.path.alias,
				imgSrc: link.relationships?.field_icon?.field_media_image?.uri || "",
				imgAlt: link.relationships?.field_icon?.field_media_image?.alt || "",
			} as QuickLinksItemProps;
	}
};

export const mapPromoToSignPostCard = (
	promo: Queries.PromotionDataFragment,
): PromotionCardToDisplayProps => ({
	type: "PromoCard",
	title: promo.field_title_front_end,
	imgSrc: promo.relationships.field_promo_image?.field_media_image?.uri,
	imgAlt: promo.relationships.field_promo_image?.field_media_image?.alt,
	promotionType: promo.relationships.field_promotion_type.name as PromoType,
	body: promo.field_text,
	buttonLabel: promo.field_promo_link.title,
	buttonUrl: promo.field_promo_link.uri,
	isOnDogListing: true,
});
export const mapBreedToSignPostCards = (breed: Queries.node__breed) => ({
	title: breed.title,
	imgSrc: breed.relationships?.field_featured_image?.field_media_image?.uri,
	imgAlt: breed.relationships?.field_featured_image?.field_media_image?.alt,
	label: "Breed",
	buttonUrl: breed.path.alias,
});

export const mapEsDogDocToDogCarouselCardProps = (dog: ESDocDog) => ({
	...dog,
	id: dog.key.toString(),
	imgSrc: getDogHeroImage(dog)?.src,
	imgAlt: getDogHeroImage(dog)?.alt,
	title: dog.name,
	body: dog.frontEndBreedName,
	label: dog.centreName,
	url: dog.url,
});

export const mapEsDogDocToFavouriteDogCarouselCardProps = (dog: ESDocDog) => ({
	id: dog.id,
	imgSrc: getDogHeroImage(dog)?.src,
	imgAlt: getDogHeroImage(dog)?.alt,
	title: dog.name,
	url: dog.url,
});

export const mapVolunteerRoleToSignpostCard = (
	role: Queries.node__volunteer_role,
): SignPostCardProps => ({
	title: role.title,
	imgSrc: role.relationships?.field_featured_image?.field_media_image?.uri,
	imgAlt: role.relationships?.field_featured_image?.field_media_image?.alt,
	label: "Role",
	buttonUrl: role.path.alias,
});

export const getDogMedia = (dogData: ESDocDog) => {
	let images = dogData?.media?.images || [];
	images = [...images].sort(orderDogImages);
	return [
		...images.map(
			(image, i) =>
				({
					src: image.src,
					alt: `${dogData.name} | ${dogData.frontEndBreedName} | ${
						dogData.centreName
					} - ${i + 1}`, // Turns out salesforce has no way of including alt text for images
					type: "image",
				}) as MediaImageProps,
		),
		...(dogData?.media?.videos?.map(
			(video) =>
				({
					youtubeId: video.url,
					alt: video.title,
					type: "video",
				}) as MediaVideoProps,
		) || []),
	] as (MediaImageProps | MediaVideoProps)[];
};

export const mapQuickLinksUnionToQuickLinksProps = (
	content: Queries.QuickLinksUnionDataFragment,
): QuickLinksItemProps => {
	switch (content.__typename) {
		case "node__article":
			return {
				title: content.title,
				imgSrc: content.relationships?.field_icon?.field_media_image?.uri || "",
				imgAlt: content.relationships?.field_icon?.field_media_image?.alt || "",
				url: content.path.alias,
			};
		case "node__landing_page":
			return {
				title: content.title,
				imgSrc: content.relationships?.field_icon?.field_media_image?.uri || "",
				imgAlt: content.relationships?.field_icon?.field_media_image?.alt || "",
				url: content.path.alias,
			};
		case "node__topic":
			return {
				title: content.title,
				imgSrc: content.relationships?.field_icon?.field_media_image?.uri || "",
				imgAlt: content.relationships?.field_icon?.field_media_image?.alt || "",
				url: content.path.alias,
			};
		case "node__static_internal_page_reference":
			return {
				title: content.title,
				imgSrc: content.relationships?.field_icon?.field_media_image?.uri || "",
				imgAlt: content.relationships?.field_icon?.field_media_image?.alt || "",
				url: content.field_link.uri,
			};
	}
};

export const mapEsDogDocToSimpleDogCardProps = (dog: ESDocDog) => ({
	id: dog.id,
	imgSrc: getDogHeroImage(dog)?.src,
	imgAlt: getDogHeroImage(dog)?.alt,
	title: dog.name,
});
