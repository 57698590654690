import { Button } from "../common";
import { Cross } from "../common/Icons";
import * as styles from "./RemoveDog.module.scss";

import React from "react";

export interface RemoveDogProps {
	type: "sponsorship" | "favourite";
	dogName: string;
	onClickCancel: () => void;
	onClickConfirm: () => void;
}

export const RemoveDog: React.FC<RemoveDogProps> = ({
	type,
	dogName,
	onClickCancel,
	onClickConfirm,
}) => {
	const isFavourite = type === "favourite";

	return (
		<>
			<button
				type="button"
				className={styles.iconWrapper}
				onClick={onClickCancel}
			>
				<Cross />
			</button>
			<div className={styles.container}>
				<h3>
					Are you sure you would like to remove {dogName} from your
					{isFavourite ? " favourites" : " basket"}?
				</h3>
				<Button onClick={onClickConfirm} fullWidth={true} variant="secondary">
					Confirm
				</Button>
				<Button onClick={onClickCancel} fullWidth={true} variant="tertiary">
					Cancel
				</Button>
			</div>
		</>
	);
};

export default RemoveDog;
