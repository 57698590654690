import { getNextScreenAboutYouRoute } from "./aboutYou";
import { getMultiDogStatus } from "./utils";

/**
 * Given the current screen and form data, returns the next screen for the about your dogs route
 * @param currentScreen	- The current screen
 * @param formData	- The form data
 * @param _questionSchema - The question schema
 * @returns The next screen
 */
export const getNextScreenWellbeingAndSupportRoute = (
	screenState: NDSScreenState,
	formData: NDSFormData,
	questionSchema: NDSQuestionSchema,
	_currentDogs: Record<number, Record<string, string | string[]>>,
	surveyProgress: NDSSurveyProgress,
):
	| [NDSScreenState, NDSSurveyProgress]
	| [NDSScreenState, NDSSurveyProgress, NDSTransition] => {
	const currentScreen = screenState.screen;
	if (currentScreen === "wellbeing-and-support-intro") {
		//! Intro screen
		return [
			{
				...screenState,
				route: "wellbeing-and-support",
				screen: "nds-q-21",
			},
			surveyProgress,
		]; // How do you feel about the following statements about your relationship with your dog(s)?
	}
	if (currentScreen === "nds-q-21") {
		//! How do you feel about the following statements about your relationship with your dog(s)?
		return [
			{
				...screenState,
				route: "wellbeing-and-support",
				screen: "nds-q-22",
			},
			{ ...surveyProgress, wellbeing: 25 },
		]; // Thinking about who in your household provides care for your dog(s), please select the relevant answer to the following statements:
	}
	if (currentScreen === "nds-q-22") {
		//! Thinking about who in your household provides care for your dog(s), please select the relevant answer to the following statements:
		return [
			{
				...screenState,
				route: "wellbeing-and-support",
				screen: "nds-q-23",
			},
			{ ...surveyProgress, wellbeing: 50 },
		]; // How many people do you feel you could call on, from outside your household, for help with the following dog care-related tasks?
	}
	if (currentScreen === "nds-q-23") {
		//! How many people do you feel you could call on, from outside your household, for help with the following dog care-related tasks?
		return [
			{
				...screenState,
				route: "wellbeing-and-support",
				screen: "welfare-and-campaigns-intro",
			},
			{ ...surveyProgress, wellbeing: 75 },
		]; // Please tell us about which dog welfare issues matter most to you.
	}
	if (currentScreen === "welfare-and-campaigns-intro") {
		//! "Welfare and campaigns" - Intro screen
		return [
			{
				...screenState,
				route: "wellbeing-and-support",
				screen: "nds-q-24",
			},
			surveyProgress,
		]; // Please tell us about which dog welfare issues matter most to you.
	}
	if (currentScreen === "nds-q-24") {
		//! Please tell us about which dog welfare issues matter most to you.
		const [numberOfDogs] = getMultiDogStatus(
			formData,
			questionSchema,
			screenState.dogIndex,
		);

		// We don't put them through about you again. So we go via question 32 to see which done screen they should get
		if (screenState.dogIndex > 1) {
			return getNextScreenAboutYouRoute(
				{ ...screenState, route: "about-you", screen: "nds-q-32" },
				formData,
				questionSchema,
				_currentDogs,
				surveyProgress,
			);
		}

		// If user only has one dog
		if (numberOfDogs <= 1) {
			return [
				{
					...screenState,
					route: "about-you",
					screen: "about-you-intro",
				},
				{ ...surveyProgress, wellbeing: 100 },
				"transition-end-wellbeing-and-support",
			]; // Please tell us about which dog welfare issues matter most to you.
		}

		return [
			{
				...screenState,
				route: "wellbeing-and-support",
				screen: "nds-q-24.1",
			},
			{ ...surveyProgress, wellbeing: 100 },
		]; // You will have an opportunity to tell us about your other dog(s) later if you wish.
	}

	if (currentScreen === "nds-q-24.1") {
		//! You will have an opportunity to tell us about your other dog(s) later if you wish.
		return [
			{
				...screenState,
				route: "about-you",
				screen: "about-you-intro",
			},
			surveyProgress,
			"transition-end-wellbeing-and-support",
		]; // Please tell us about which dog welfare issues matter most to you.
	}
};
