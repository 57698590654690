import React from "react";

export const SponsorDogsContext = React.createContext<SponsorDogsContext>({
	onChangeLocation: () => {},
	userLocation: null,
	setSponsorDogs: () => {},
	setShowFilters: () => {},
	setShowSortOptions: () => {},
	setSortBy: () => {},
	onUpdateBreeds: () => {},
	setCanVisit: () => {},
	onReset: () => {},
	showFilters: false,
	showSortOptions: false,
	sortBy: "NEW",
	selectedBreeds: [],
	availableBreeds: [],
	selectedLocations: [],
	filteredDogs: [],
	canVisit: false,
});
