import {
	DogSearchApiState,
	DogSearchFilterState,
	DogSearchQueryVariables,
} from "./DogSearchApi.types";

import { isInRangeFilter } from "@dogstrust/src/utils/distance";
import qs from "qs";
import type { DogSearchAction } from "./DogSearchApi.actions";
import { parseQueryVarsToState } from "./DogSearchApi.utils";

const previousResultsPages =
	typeof window !== "undefined"
		? localStorage.getItem("previousResultsPages")
		: null;

const clearPreviousResultsPages = (): ESDocDog[] => {
	if (previousResultsPages) {
		localStorage.removeItem("previousResultsPages");
		return [];
	} else {
		return [];
	}
};

const defaultFilterState: DogSearchFilterState = {
	pagination: 0,
	previousResultsPages: previousResultsPages
		? JSON.parse(previousResultsPages)
		: [], //Get results from local storage if exists else empty array
	isListingPage: false,
	showFilters: "Initial",
	lastView: "None",
	selectedBreeds: [],
	userLocation: null,
	selectedCentres: [],
	tempSelectedBreeds: [],
	selectedAges: [],
	selectedSizes: [],
	selectedGenders: [],
	selectedLivesWith: [],
	selectedDayRange: "Any",
	currentDistance: 1000,
	locationSearchTerm: "",
	breedSearchTerm: "",
	isUnderdog: false,
	noReserved: false,
};
export const dogSearchDefaultState: DogSearchApiState = {
	...defaultFilterState,
	sortBy: "NEW",
	centreLocations: [],
	centreNames: {},
	centresInRange: [],
	addressSuggestions: [],
	postcodeSuggestions: [],
	breedList: [],
	maintainQueryParams: false,
};

const addOrRemoveFromArray = (array: string[], value: string) => {
	if (array.includes(value)) return array.filter((x) => x !== value);
	return [...array, value];
};

// eslint-disable-next-line complexity
export function dogSearchReducer(
	state: DogSearchApiState,
	action: DogSearchAction,
): DogSearchApiState {
	let updatedCentresInRange;
	let updatedSelectedCentres;
	switch (action.type) {
		case "INIT_FILTERS": {
			// Get the names of the centre from the Drupal query - Dogs have centreCode and we can get centre data from that
			const centreNames: { [key: string]: string } = {};
			for (const centre of action.allCentres) {
				centreNames[centre.centreCode] = centre.title;
			}

			// Parse query vars
			const queryVars: DogSearchQueryVariables = qs.parse(
				window.location.search.split("?")[1],
				// biome-ignore lint/suspicious/noExplicitAny: Legit casting
			) as any;

			// Map to state object
			const queryVarsToState = parseQueryVarsToState(queryVars);

			const { clearPagination, ...newState } = queryVarsToState;

			if (action.centreCode) {
				// If we're looking to filter by a single centre (for the Dog Bio page)
				updatedCentresInRange = action.allCentres.filter(
					(centre) => centre.centreCode === action.centreCode,
				);
				updatedSelectedCentres = [
					...updatedCentresInRange.map((centre) => centre.centreCode),
				];

				return {
					...state,
					isListingPage: action.isListingPage,
					maintainQueryParams: action.shouldMaintainQueryParams,
					centreLocations: action.allCentres,
					centreNames,
					...newState,
					centresInRange: updatedCentresInRange,
					selectedCentres: updatedSelectedCentres,
					userLocation: null,
					previousResultsPages: clearPagination
						? clearPreviousResultsPages()
						: state.previousResultsPages,
				};
			}
			return {
				...state,
				isListingPage: action.isListingPage,
				maintainQueryParams: action.shouldMaintainQueryParams,
				centreLocations: action.allCentres,
				centreNames,
				...newState,
				previousResultsPages: clearPagination
					? clearPreviousResultsPages()
					: state.previousResultsPages,
			};
		}
		case "CHANGE_FILTER_VIEW":
			return {
				...state,
				breedSearchTerm: "",
				showFilters: action.newView,
				lastView: state.showFilters === "Initial" ? "None" : state.showFilters,
			};
		case "UPDATE_SORT_OPTION":
			return {
				...state,
				pagination: 0, // Reset pagination as results will change
				sortBy: action.selectedOption,
				previousResultsPages: clearPreviousResultsPages(),
			};

		case "LOAD_MORE":
			// set prev results in localStorage
			localStorage.setItem(
				"previousResultsPages",
				JSON.stringify(action.previousResultsPages),
			);
			return {
				...state,
				pagination: state.pagination + 1,
				previousResultsPages: action.previousResultsPages,
			};
		case "UPDATE_FILTER_OPTION": {
			const {
				selectedAge,
				selectedSize,
				selectedGender,
				selectedCentre,
				selectedDayRange,
				selectedLivesWithOption,
			} = action.selectedOption;

			const {
				selectedAges,
				selectedSizes,
				selectedGenders,
				selectedCentres,
				selectedLivesWith,
			} = state;

			if (selectedAge) {
				return {
					...state,
					pagination: 0,
					previousResultsPages: clearPreviousResultsPages(),
					selectedAges: addOrRemoveFromArray(selectedAges, selectedAge),
				};
			} else if (selectedSize) {
				return {
					...state,
					pagination: 0,
					selectedSizes: addOrRemoveFromArray(selectedSizes, selectedSize),
					previousResultsPages: clearPreviousResultsPages(),
				};
			} else if (selectedGender) {
				return {
					...state,
					pagination: 0,
					selectedGenders: addOrRemoveFromArray(
						selectedGenders,
						selectedGender,
					),
					previousResultsPages: clearPreviousResultsPages(),
				};
			} else if (selectedCentre) {
				return {
					...state,
					pagination: 0,
					selectedCentres: addOrRemoveFromArray(
						selectedCentres,
						selectedCentre,
					),
					previousResultsPages: clearPreviousResultsPages(),
				};
			} else if (selectedDayRange) {
				return {
					...state,
					pagination: 0,
					selectedDayRange,
					previousResultsPages: clearPreviousResultsPages(),
				};
			} else if (selectedLivesWith) {
				return {
					...state,
					pagination: 0,
					selectedLivesWith: addOrRemoveFromArray(
						selectedLivesWith,
						selectedLivesWithOption,
					),
					previousResultsPages: clearPreviousResultsPages(),
				};
			}

			return { ...state };
		}

		case "RESET_FILTERS": {
			const isListingPage = state.isListingPage;
			return {
				...state,
				pagination: 0,
				breedSearchTerm: "",
				...defaultFilterState,
				showFilters: action.dontShowFilters ? state.showFilters : "Any",
				isListingPage,
				locationSearchTerm: "",
				userLocation: null,
				previousResultsPages: clearPreviousResultsPages(),
			};
		}

		case "SELECT_BREED": {
			// Remove if already selected, otherwise add to list
			const tempSelectedBreeds = state.tempSelectedBreeds.includes(action.breed)
				? state.tempSelectedBreeds.filter((x) => x !== action.breed)
				: [...state.tempSelectedBreeds, action.breed];

			return { ...state, tempSelectedBreeds };
		}

		case "SELECT_BREEDS":
			return { ...state, selectedBreeds: [...action.breeds] };
		case "CONFIRM_SELECTED_BREEDS":
			return {
				...state,
				breedSearchTerm: "",
				pagination: 0,
				previousResultsPages: clearPreviousResultsPages(),
				showFilters: state.isListingPage ? "Any" : "None",
				selectedBreeds: [...state.tempSelectedBreeds],
			};

		case "CANCEL_SELECTED_BREEDS":
			return {
				...state,
				breedSearchTerm: "",
				tempSelectedBreeds: state.selectedBreeds,
				lastView: state.showFilters,
				showFilters: state.lastView,
			};

		case "UPDATE_TRAVEL_DISTANCE": {
			const centresInRange1 = state.centreLocations.filter(
				(centre) =>
					!state.userLocation ||
					isInRangeFilter(
						{
							address: "",
							latitude: centre.latitude,
							longitude: centre.longitude,
						},
						state.userLocation,
						action.distance,
					),
			);
			return {
				...state,
				pagination: 0, // Reset pagination as results will change
				previousResultsPages: clearPreviousResultsPages(),
				centresInRange: centresInRange1,
				selectedCentres: [
					"NULL",
					...centresInRange1.map((centre) => centre.centreCode),
				],
				currentDistance: action.distance,
				userLocation: {
					...state.userLocation,
				},
			};
		}

		case "UPDATE_LOCATION": {
			const centresInRange2 = state.centreLocations.filter(
				(centre) =>
					!action.userLocation ||
					isInRangeFilter(
						{
							address: "",
							latitude: centre.latitude,
							longitude: centre.longitude,
						},
						action.userLocation,
						action.distance,
					),
			);
			let newSortBy = state.sortBy;
			// Default to nearest if a user location is set
			// Don't change if the user has already selected a sort option
			if (action.userLocation && newSortBy === "NEW") {
				newSortBy = "NEAR";
			}

			return {
				...state,
				pagination: 0, // Reset pagination as results will change
				previousResultsPages: clearPreviousResultsPages(),
				centresInRange: centresInRange2,
				selectedCentres: [
					"NULL",
					...centresInRange2.map((centre) => centre.centreCode),
				],
				userLocation: action.userLocation,
				currentDistance: action.distance,
				sortBy: newSortBy,
			};
		}

		case "UPDATE_LOCATION_SUGGESTIONS":
			return {
				...state,
				postcodeSuggestions: action.postcodeSuggestions || [],
				addressSuggestions: action.addressSuggestions || [],
			};

		case "CLEAR_USER_LOCATION":
			return {
				...state,
				pagination: 0, // Reset pagination as results will change
				locationSearchTerm: "",
				currentDistance: 1000,
				userLocation: null,
				centresInRange: [...state.centreLocations],
				selectedCentres: [],
				previousResultsPages: clearPreviousResultsPages(),
			};

		case "UPDATE_SELECTED_CENTRES": {
			let selected = state.selectedCentres || [];
			if (state.selectedCentres.includes(action.selectedCentre)) {
				selected = selected.filter((c) => c !== action.selectedCentre);
			} else {
				selected = [...selected, action.selectedCentre];
			}
			return {
				...state,
				selectedCentres: [...selected],
				previousResultsPages: clearPreviousResultsPages(),
			};
		}
		case "SEARCH_BREED_LIST":
			return {
				...state,
				breedSearchTerm: action.breedSearchTerm,
			};

		case "TOGGLE_IS_UNDERDOG":
			return {
				...state,
				isUnderdog: action.isUnderdog,
				pagination: 0,
				previousResultsPages: clearPreviousResultsPages(),
			};

		case "TOGGLE_NO_RESERVED":
			return {
				...state,
				noReserved: action.isNoReserved,
				pagination: 0,
				previousResultsPages: clearPreviousResultsPages(),
			};
		default:
			return state;
	}
}
