import { isInRangeFilter } from "@dogstrust/src/utils/distance";
import type { CharityShopsAction } from "./CharityShops.actions";
import { CharityShopsState } from "./CharityShops.types";

export const charityShopsDefaultState: CharityShopsState = {
	charityShopsLoaded: false,
	selectedCharityShopName: "",
	charityShopsLocations: [],
	charityShopsInRange: [],
	addressSuggestions: [],
	sortBy: "A",
	pagination: 0,
	currentDistance: 1000,
	userLocation: null,
	locationSearchTerm: "",
	distanceToCharityShops: {},
};
export function charityShopsReducer(
	state: CharityShopsState,
	action: CharityShopsAction,
): CharityShopsState {
	switch (action.type) {
		case "LOAD_ALL_CHARITY_SHOPS":
			if (state.charityShopsLoaded) return state;
			return {
				...state,
				charityShopsLoaded: true,
				pagination: 0,
				charityShopsLocations: [...action.allCharityShops],
				charityShopsInRange: [...action.allCharityShops],
			};
		case "SELECT_CHARITY_SHOP":
			return {
				...state,
				selectedCharityShopName:
					state.selectedCharityShopName === action.charityShopName
						? ""
						: action.charityShopName,
			};
		case "UPDATE_SORT_OPTION":
			return {
				...state,
				pagination: 0,
				sortBy: action.selectedOption,
			};

		case "LOAD_MORE":
			return {
				...state,
				pagination: state.pagination + 1,
			};

		case "UPDATE_TRAVEL_DISTANCE": {
			const shopsInRange1 = state.charityShopsLocations.filter(
				(shop) =>
					!state.userLocation ||
					isInRangeFilter(
						{
							latitude: parseFloat(shop.field_latitude),
							longitude: parseFloat(shop.field_longitude),
							address: "",
						},
						state.userLocation,
						action.distance,
					),
			);

			return {
				...state,
				pagination: 0, // Reset pagination as results will change
				charityShopsInRange: [...shopsInRange1],
				currentDistance: action.distance,
				userLocation: {
					...state.userLocation,
				},
			};
		}

		case "UPDATE_LOCATION": {
			const shopsInRange2 = state.charityShopsLocations.filter(
				(shop) =>
					!action.userLocation ||
					isInRangeFilter(
						{
							latitude: parseFloat(shop.field_latitude),
							longitude: parseFloat(shop.field_longitude),
							address: "",
						},
						action.userLocation,
						action.distance,
					),
			);
			return {
				...state,
				pagination: 0, // Reset pagination as results will change
				charityShopsInRange: [...shopsInRange2],
				userLocation: action.userLocation,
				currentDistance: action.distance,
			};
		}

		case "UPDATE_LOCATION_SUGGESTIONS":
			return {
				...state,
				addressSuggestions: action.locations || [],
			};

		case "UPDATE_CHARITY_SHOPS_IN_RANGE":
			return {
				...state,
				charityShopsInRange: action.charityShopsInRange,
				distanceToCharityShops: action.distanceToCharityShops,
				selectedCharityShopName: action.charityShopsInRange
					.map((charityshop) => charityshop.title)
					.includes(state.selectedCharityShopName)
					? state.selectedCharityShopName
					: "",
			};
		default:
			return state;
	}
}
