export const compareSponsorDogs = (
	prev?: SponsorDogWithDistance[],
	next?: SponsorDogWithDistance[],
) => {
	// The dogs state does not yet exist or has changed.
	if (!prev || !next) return false;
	// The length of the dogs has changed.
	if (prev.length !== next.length) {
		return false;
	}

	// The order of the dogs has changed.
	return (
		JSON.stringify(prev.map((n) => n.title)) ===
			JSON.stringify(next.map((n) => n.title)) &&
		JSON.stringify(prev.map((n) => n.distance)) ===
			JSON.stringify(next.map((n) => n.distance))
	);
};
