import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
} from "react";
import {
	onClearUserLocationAction,
	onLoadMoreAction,
	onLoadOurCentresAction,
	onSelectCentreAction,
	onUpdateLocationAction,
	onUpdateSortOptionAction,
	onUpdateTravelDistanceAction,
} from "./OurCentres.actions";
import {
	ourCentresDefaultState,
	ourCentresReducer,
} from "./OurCentres.reducer";
import { CentresSortOption } from "./OurCentres.types";

import { useLazyQuery } from "@apollo/client";
import { dogSearchClient } from "@dogstrust/src/apollo/client";
import { SEARCH_DOG_QUERY } from "../../apollo/queries";
import { OurCentresContext } from "./OurCentres.context";
import { DISTANCE_OPTIONS } from "./OurCentres.fixtures";

export const OurCentresProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [
		{
			pagination,
			centreNames,
			sortBy,
			userLocation,
			currentDistance,
			centreLocations,
			...rest
		},
		dispatch,
	] = useReducer(ourCentresReducer, ourCentresDefaultState);
	// ============================================ GQL QUERY HOOK ==========================================================

	const [getDogs, { data }] = useLazyQuery(SEARCH_DOG_QUERY, {
		client: dogSearchClient,
		fetchPolicy: "cache-first",
		variables: {
			page: 0,
			sort: "NEW",
			breed: [],
			age: [],
			size: [],
			gender: [],
			centres: [],
			daysSinceAdded: undefined,
			liveWithCats: false,
			liveWithDogs: false,
			liveWithPreschool: false,
			liveWithPrimary: false,
			liveWithSecondary: false,
		},
	});

	useEffect(() => {
		getDogs();
	}, [getDogs]);
	// ============================================ REDUCER ACTIONS =====================================================

	const onLoadOurCentres = useCallback(
		(allCentres: Queries.RehomingCentreDataFragment[]) =>
			dispatch(onLoadOurCentresAction(allCentres)),
		[],
	);
	const onSelectCentre = useCallback(
		(centreCode: string) => dispatch(onSelectCentreAction(centreCode)),
		[],
	);
	const onUpdateTravelDistance = useCallback(
		(distance: number) => dispatch(onUpdateTravelDistanceAction(distance)),
		[],
	);

	const onUpdateLocation = useCallback(
		(userLocation: Place, distance: number) =>
			dispatch(onUpdateLocationAction(userLocation, distance)),
		[],
	);

	const onClearUserLocation = useCallback(
		() => dispatch(onClearUserLocationAction()),
		[],
	);

	const onUpdateSort = useCallback(
		(selectedOption: CentresSortOption) =>
			dispatch(onUpdateSortOptionAction(selectedOption)),
		[],
	);

	// ============================================ FUNCTIONS =====================================================

	const distanceThrottle = useRef(null);
	/**
	 * On Change event for the distance slider. Only sets state when it's landed on expected value.
	 * Stops state from changing during slide event
	 * @param value The new value of the slider
	 */
	const safelyChangeDistance = useCallback(
		(value: string) => {
			if (DISTANCE_OPTIONS.includes(parseInt(value))) {
				// Stop changes on inbetween vals
				distanceThrottle.current = setTimeout(() => {
					onUpdateTravelDistance(parseFloat(value));
				}, 300);
			}
		},
		[onUpdateTravelDistance],
	);

	/**
	 * Returns the current number of results
	 */
	const resultsCount = useMemo(() => {
		return data?.results?.totalResults || 0;
	}, [data]);

	const onLoadMore = useCallback(() => dispatch(onLoadMoreAction()), []);

	return (
		<OurCentresContext.Provider
			value={{
				...rest,
				centreLocations,
				centreNames,
				resultsCount,
				sortBy,
				pagination,
				currentDistance,
				userLocation,
				onLoadOurCentres,
				onSelectCentre,
				safelyChangeDistance,
				onUpdateTravelDistance,
				onClearUserLocation,
				onUpdateLocation,
				onUpdateSort,
				onLoadMore,
			}}
		>
			{children}
		</OurCentresContext.Provider>
	);
};
