export { getNextScreen } from "./routing";
import { shuffleArray } from "@dogstrust/src/utils/array";

/**
 * @file National Dog Survey utility functions
 * Injects warm data into a string
 * @param str - The string to inject the warm data into
 * @param warmData - The warm data to inject
 * @param dogNumber - The index of the dog to use
 * @returns The string with the warm data injected
 */
export const injectWarmData = (
	str: string,
	warmData?: NDSWarmUserData,
): string => {
	if (warmData?.dog) {
		const dog = warmData.dog;
		return str
			.replace(/\[firstName\]/g, warmData.firstName)
			.replace(/\[dogName\]/g, dog.name)
			.replace(/\[dogSex\]/g, dog.dogSex)
			.replace(
				/\[neuteredStatus\]/g,
				dog.neuterStatus === "neutered" ? "a neutered" : "an unneutered",
			)
			.replace(/\[dogMonthAcquired\]/g, dog.monthAcquired)
			.replace(/\[dogYearAcquired\]/g, dog.yearAcquired);
	}
	return str;
};
export const DEFAULT_MULTI_DOG_PROGRESS = {
	aboutYourDog: -1,
	lifeWithYourDog: -1,
};
export const multiDogFields: NDSFormFieldNames[] = [
	"nds-q-2-dogs-name",
	"nds-q-3-dog-month",
	"nds-q-3-dog-year",
	"nds-q-5-dog-years",
	"nds-q-5-dog-months",
	"nds-q-6-sex-neutered",
	"nds-q-7-describe-breed",
	"nds-q-7.1-single-breed",
	"nds-q-7.1-single-breed-unlisted",
	"nds-q-7.2-breed-1",
	"nds-q-7.2-breed-2",
	"nds-q-7.2-multi-breed-unlisted",
	"nds-q-8-dog-description",
	"nds-q-9-dog-favourite-toy",
	"nds-q-11-collar",
	"nds-q-11-lead",
	"nds-q-11-other",
	"nds-q-11-other-describe",
	"nds-q-13-involved-in-incident-no",
	"nds-q-13-involved-in-incident-yes",
	"nds-q-13-incident-more-info",

	"nds-q-20.A-recall",
	"nds-q-20.B-greet-politely",
	"nds-q-20.C-leave-it",
	"nds-q-20.D-home-alone",
	"nds-q-20.E-loose-lead-walking",
	"nds-q-20.F-other-dogs",
	"nds-q-20.G-fireworks",
	"nds-q-20.H-strangers",
	"nds-q-20.I-sleep-through-night",
	"nds-q-20.J-door-barking",

	"nds-q-20.1.A-recall-statements",
	"nds-q-20.1.A-recall-advice",
	"nds-q-20.1.A-recall-advice-other",
	"nds-q-20.1.B-greet-politely-statements",
	"nds-q-20.1.B-greet-politely-advice",
	"nds-q-20.1.B-greet-politely-advice-other",
	"nds-q-20.1.C-leave-it-statements",
	"nds-q-20.1.C-leave-it-advice",
	"nds-q-20.1.C-leave-it-advice-other",
	"nds-q-20.1.D-home-alone-statements",
	"nds-q-20.1.D-home-alone-advice",
	"nds-q-20.1.D-home-alone-advice-other",
	"nds-q-20.1.E-loose-lead-walking-statements",
	"nds-q-20.1.E-loose-lead-walking-advice",
	"nds-q-20.1.E-loose-lead-walking-advice-other",
	"nds-q-20.1.F-other-dogs-statements",
	"nds-q-20.1.F-other-dogs-advice",
	"nds-q-20.1.F-other-dogs-advice-other",
	"nds-q-20.1.G-fireworks-statements",
	"nds-q-20.1.G-fireworks-advice",
	"nds-q-20.1.G-fireworks-advice-other",
	"nds-q-20.1.H-strangers-statements",
	"nds-q-20.1.H-strangers-advice",
	"nds-q-20.1.H-strangers-advice-other",
	"nds-q-20.1.I-sleep-through-night-statements",
	"nds-q-20.1.I-sleep-through-night-advice",
	"nds-q-20.1.I-sleep-through-night-advice-other",
	"nds-q-20.1.J-door-barking-statements",
	"nds-q-20.1.J-door-barking-advice",
	"nds-q-20.1.J-door-barking-advice-other",
];

export const getShuffledOptions = (
	checkboxGroupProps: FormField,
	optionOrder: string,
): FormOption[] => {
	if (typeof window === "undefined") return checkboxGroupProps.options;

	const optionsFromLS = localStorage.getItem(optionOrder);
	if (optionsFromLS) {
		return JSON.parse(optionsFromLS);
	}

	let relaxedOptions = checkboxGroupProps.options.filter(
		(option) => option.type === "relaxed",
	);
	let moreSpaceOptions = checkboxGroupProps.options.filter(
		(option) => option.type === "moreSpace",
	);
	let moreSpaceNowOptions = checkboxGroupProps.options.filter(
		(option) => option.type === "moreSpaceNow",
	);
	const notSureOption = checkboxGroupProps.options.find(
		(option) => option.type === "notSure",
	);

	// Shuffle the options for each type
	relaxedOptions = shuffleArray(relaxedOptions);
	moreSpaceOptions = shuffleArray(moreSpaceOptions);
	moreSpaceNowOptions = shuffleArray(moreSpaceNowOptions);

	// Select one option of each type
	const selectedOptions = [
		relaxedOptions[0],
		moreSpaceOptions[0],
		moreSpaceNowOptions[0],
	];

	// Get the remaining options excluding the selected ones and "notSure" option
	const remainingOptions = checkboxGroupProps.options.filter(
		(option) =>
			![
				...selectedOptions.map((option) => option.value),
				notSureOption.value,
			].includes(option.value),
	);

	// Shuffle the remaining options and select one random option
	const shuffledRemainingOptions = shuffleArray(remainingOptions).slice(0, 1);

	// Concatenate all the selected and remaining options
	const shuffledOptions = [
		...selectedOptions,
		...shuffledRemainingOptions,
		notSureOption,
	];

	localStorage.setItem(optionOrder, JSON.stringify(shuffledOptions));
	return shuffledOptions;
};
