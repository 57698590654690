/**
 * Sorts dog images by weight, with nulls last
 * @param a  - DogImage
 * @param b  - DogImage
 * @returns
 */
export const orderDogImages = (a: DogImage, b: DogImage) => {
	if (a.weight === null) {
		return 1; // a is greater than b
	} else if (b.weight === null) {
		return -1; // a is less than b
	} else {
		return a.weight - b.weight; // a is less than b
	}
};

export const getDogHeroImage = ({ media }: Queries.DogFragment | ESDocDog) =>
	[...(media?.images || [])]
		.sort(orderDogImages)
		.find((image) => image.isHero) ||
	media?.images?.[0] ||
	null;
