/**
 * Returns the number of dogs the user has and whether they have already told us about all of their dogs
 * @param formData - The form data
 * @param questionSchema - The question schema
 * @param currentDogIndex - The index of the current dog
 * @returns [
 *  The number of dogs the user has,
 *  Whether the user has already told us about all of their dogs
 * ]
 */
export const getMultiDogStatus = (
	formData: NDSFormData,
	questionSchema: NDSQuestionSchema,
	currentDogIndex: number,
): [number, boolean] => {
	// If the user has 0 dogs, or the user has already told us about all of their dogs:
	const numberOfDogsAnswer = formData["nds-q-1-number-of-dogs"] as string;
	if (numberOfDogsAnswer) {
		//! COLD ROUTE
		const numberOfDogsOptions = questionSchema.routes["about-your-dogs"][
			"nds-q-1"
		].fields[0].options.map(({ value }) => value);

		const answerIndex = numberOfDogsOptions.indexOf(numberOfDogsAnswer);

		// This is if they have multiple dogs and they have already told us about all of them
		const numberOfDogsSpecified = formData[
			"nds-q-1-number-of-dogs-specify"
		] as string;

		const numberOfDogs =
			answerIndex <= 4 ? answerIndex : Number.parseInt(numberOfDogsSpecified);

		return [numberOfDogs, currentDogIndex >= numberOfDogs];
	}

	//! WARM ROUTE

	const numberOfDogsAnswerWarm = formData["nds-q-0.4-number-of-dogs"] as string;

	const numberOfDogsOptions = questionSchema.routes.warm[
		"nds-q-0.4"
	].fields[1].options.map(({ value }) => value);

	const answerIndex = numberOfDogsOptions.indexOf(numberOfDogsAnswerWarm);

	// This is if they have multiple dogs and they have already told us about all of them
	const numberOfDogsSpecified = formData[
		"nds-q-0.4-number-of-dogs-specify"
	] as string;

	// The warm route has no option for 0 dogs so the index is +1
	const numberOfDogs =
		answerIndex <= 4 ? answerIndex + 1 : Number.parseInt(numberOfDogsSpecified);

	return [numberOfDogs, currentDogIndex >= numberOfDogs];
};
