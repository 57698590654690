import { getMultiDogStatus } from "./utils";

/**
 * Given the current screen and form data, returns the next screen for the about you route
 * @param currentScreen	- The current screen
 * @param formData	- The form data
 * @param _questionSchema - The question schema
 * @returns The next screen
 */
export const getNextScreenAboutYouRoute = (
	screenState: NDSScreenState,
	formData: NDSFormData,
	questionSchema: NDSQuestionSchema,
	_currentDogs: Record<number, Record<string, string | string[]>>,
	surveyProgress: NDSSurveyProgress,
):
	| [NDSScreenState, NDSSurveyProgress]
	| [NDSScreenState, NDSSurveyProgress, NDSTransition] => {
	const currentScreen = screenState.screen;
	const currentDogIndex = screenState.dogIndex;
	const currentRoute = "about-you";

	if (
		currentScreen === "about-you-intro" ||
		currentScreen === "about-you-non-dog-intro"
	) {
		//! Tell us about your dog's human!
		return [
			{ ...screenState, route: "about-you", screen: "nds-q-25" },
			{ ...surveyProgress, aboutYou: 0 },
		]; // What age group do you fall into?
	}
	if (currentScreen === "nds-q-25") {
		//! What age group do you fall into?
		return [
			{ ...screenState, route: "about-you", screen: "nds-q-26" },
			{ ...surveyProgress, aboutYou: 15 },
		]; // Please count the number of people in each age bracket. Include yourself in the 18+ years count.
	}
	if (currentScreen === "nds-q-26") {
		//! Please count the number of people in each age bracket. Include yourself in the 18+ years count.

		const preferNotToSayOption =
			questionSchema.routes["about-you"]["nds-q-26"].fields[5].options[0].value;

		const preferNotToSay =
			formData["nds-q-26-prefer-not-to-say"]?.includes(preferNotToSayOption);

		// IF they prefer not to say, then we cannot ask them about the number of people in each age bracket
		if (preferNotToSay) {
			return [
				{ ...screenState, route: "about-you", screen: "nds-q-27" },
				{ ...surveyProgress, aboutYou: 30 },
			]; //  What gender do you identify as?
		}
		return [
			{ ...screenState, route: "about-you", screen: "nds-q-26.1" },
			{ ...surveyProgress, aboutYou: 25 },
		]; // Just to confirm, in your household, there is you, plus xx other adults and xx children. Is this correct?
	}
	if (currentScreen === "nds-q-26.1") {
		//! Just to confirm, in your household, there is you, plus xx other adults and xx children. Is this correct?
		const answer = formData["nds-q-26.1-confirm-household"];

		const positiveAnswer =
			questionSchema.routes["about-you"]["nds-q-26.1"].fields[0].options[0]
				.value;

		// They have confirmed their household so proceed.
		if (answer === positiveAnswer) {
			return [
				{ ...screenState, route: "about-you", screen: "nds-q-27" },
				{ ...surveyProgress, aboutYou: 30 },
			]; //  What gender do you identify as?
		}

		// They have flagged incorrect data so they need to go back to the previous screen to fix it.
		return [
			{ ...screenState, route: "about-you", screen: "nds-q-26" },
			{ ...surveyProgress, aboutYou: 25 },
		]; //  What gender do you identify as?
	}
	if (currentScreen === "nds-q-27") {
		//! What gender do you identify as?
		return [
			{ ...screenState, route: "about-you", screen: "nds-q-28" },
			{ ...surveyProgress, aboutYou: 40 },
		]; // Which of the following best describes your sexual orientation?
	}
	if (currentScreen === "nds-q-28") {
		//! Which of the following best describes your sexual orientation?
		return [
			{ ...screenState, route: "about-you", screen: "nds-q-29" },
			{ ...surveyProgress, aboutYou: 50 },
		]; // What is your ethnic group?
	}
	if (currentScreen === "nds-q-29") {
		// ! What is your ethnic group?
		return [
			{ ...screenState, route: "about-you", screen: "nds-q-30" },
			{ ...surveyProgress, aboutYou: 60 },
		]; // Which county or region do you live in?
	}
	if (currentScreen === "nds-q-30") {
		// ! Which county or region do you live in?
		const numberOfDogsAnswer = formData["nds-q-1-number-of-dogs"];

		const noDogsOption =
			questionSchema.routes["about-your-dogs"]["nds-q-1"].fields[0].options[0]
				.value;

		const isDogOwner = numberOfDogsAnswer !== noDogsOption;
		// If they are a dog owner
		if (isDogOwner) {
			return [
				{ ...screenState, route: "about-you", screen: "nds-q-31" },
				{ ...surveyProgress, aboutYou: 70 },
			]; // !(If dog owner) Is there anything else you would like to tell us about the relationship with your dog?
		}

		// If they are not a dog owner
		return [
			{ ...screenState, route: "about-you", screen: "nds-q-32" },
			{ ...surveyProgress, aboutYou: 80 },
		]; // Contact and consent
	}
	if (currentScreen === "nds-q-31") {
		// !(If dog owner) Is there anything else you would like to tell us about the relationship with your dog?
		return [
			{ ...screenState, route: "about-you", screen: "nds-q-32" },
			{ ...surveyProgress, aboutYou: 80 },
		]; // Contact and consent
	}
	if (currentScreen === "nds-q-32") {
		//! Contact and consent
		const [numberOfDogs, hasToldUsAboutAllDogs] = getMultiDogStatus(
			formData,
			questionSchema,
			currentDogIndex,
		);

		// This is if they have one dog and they have already told us about it
		if (numberOfDogs <= 1) {
			return [
				{ ...screenState, route: "about-you", screen: "nds-q-32.1" },
				{ ...surveyProgress, aboutYou: 100 },
				"transition-end-about-you",
			]; // About you or end survey
		}

		if (!hasToldUsAboutAllDogs) {
			return [
				{ ...screenState, route: "about-you", screen: "nds-q-32.2" },
				{ ...surveyProgress, aboutYou: 100 },
				"transition-end-about-you",
			]; // Would you like to tell us about another dog?
		}

		return [
			{ ...screenState, route: "about-you", screen: "nds-q-32.1" },
			{ ...surveyProgress, aboutYou: 100 },
			"transition-end-about-you",
		]; // Would you like to tell us about another dog?
	}

	if (["nds-q-32.1", "nds-q-32.2", "nds-q-32.3"].includes(currentScreen)) {
		//! Tell us about yourself/another dog/ end survey
		//! This is a special screen where we have no questions and it handles it's own nav
		console.warn("This screen should not be navigated from directly");
		return [
			{ ...screenState, route: currentRoute, screen: currentScreen },
			{ ...surveyProgress, aboutYou: 100 },
		];
	}
	if (currentScreen === "nds-q-33") {
		//! I see myself as... (agree-disagree with personality traits)

		return [
			{ ...screenState, route: "about-you", screen: "nds-q-34" },
			surveyProgress,
		]; // Hobbies / interests
	}
	if (currentScreen === "nds-q-34") {
		//! Are you happy to tell us a bit more about your personality and hobbies?
		const [numberOfDogs, hasToldUsAboutAllDogs] = getMultiDogStatus(
			formData,
			questionSchema,
			currentDogIndex,
		);

		// This is if they have one dog and they have already told us about it
		if (numberOfDogs <= 1) {
			return [
				{ ...screenState, route: "about-you", screen: "nds-q-34.2" },
				surveyProgress,
				"transition-end-about-you",
			]; // Thank you page
		}

		if (!hasToldUsAboutAllDogs && currentDogIndex < 5) {
			return [
				{ ...screenState, route: "about-you", screen: "nds-q-34.1" },
				surveyProgress,
				"transition-end-about-you",
			]; // Would you like to tell us about another dog?
		}

		return [
			{ ...screenState, route: "about-you", screen: "nds-q-34.2" },
			{ ...surveyProgress, aboutYou: 100 },
			"transition-end-about-you",
		]; // Thank you page
	}
	if (currentScreen === "nds-q-34.1") {
		//! Would you like to tell us about another dog or end survey?
		//! Tell us about yourself/another dog/ end survey
		console.warn("This screen should not be navigated from directly");
		return [
			{ ...screenState, route: currentRoute, screen: currentScreen },
			surveyProgress,
		];
	}
};
