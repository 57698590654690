import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from "react";
import {
	dogSchoolDefaultState,
	dogSchoolReducer,
} from "./DogSchoolApi.reducer";

import { haversineDistance } from "@dogstrust/src/utils/distance";
import {
	onClearSelectedRegionAction,
	onLoadAllDogSchoolRegionsAction,
	onUpdateClassTypeAction,
	onUpdateLocationAction,
	onUpdateSelectedClassLocationAction,
	onUpdateSelectedRegionAction,
	onUpdateSortOptionAction,
	onUpdateTravelDistanceAction,
} from "./DogSchoolApi.actions";
import { DogSchoolApiContext } from "./DogSchoolApi.context";
import { DISTANCE_OPTIONS } from "./DogSchoolApi.fixtures";
import {
	DogClassLocation,
	DogClassRegion,
	DogClassesSortOption,
} from "./DogSchoolApi.types";
import { filterDogLocations, filterDogRegions } from "./DogSchoolApi.utils";

export const DogSchoolApiProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [_usingDogSchoolQuery, setUsingDogSchoolQuery] = useState(false);

	const useDogSchoolQuery = () => setUsingDogSchoolQuery(true);
	const [
		{
			allDogSchoolRegions,
			selectedRegionName,
			selectedLocation,
			selectedType,
			userLocation,
			addressSuggestions,
			currentDistance,
			locationSearchTerm,
			sortBy,
			filteredByRegion,
			userDistanceToLocation,
		},
		dispatch,
	] = useReducer(dogSchoolReducer, dogSchoolDefaultState);

	const onLoadDogSchoolRegions = useCallback((regions: DogClassRegion[]) => {
		dispatch(onLoadAllDogSchoolRegionsAction(regions));
	}, []);

	const onUpdateSelectedRegion = useCallback(
		(region: string) => dispatch(onUpdateSelectedRegionAction(region)),
		[],
	);

	const onUpdateTravelDistance = useCallback(
		(distance: number) => dispatch(onUpdateTravelDistanceAction(distance)),
		[],
	);

	const onUpdateLocation = useCallback(
		(userLocation: Place, distance: number) =>
			dispatch(onUpdateLocationAction(userLocation, distance)),
		[],
	);

	const onUpdateClassType = useCallback(
		(classType: string) => dispatch(onUpdateClassTypeAction(classType)),
		[],
	);

	const onUpdateSort = useCallback(
		(selectedOption: DogClassesSortOption) =>
			dispatch(onUpdateSortOptionAction(selectedOption)),
		[],
	);

	const onClearSelectedRegion = useCallback(
		() => dispatch(onClearSelectedRegionAction()),
		[],
	);

	const onUpdateSelectedClassLocation = useCallback(
		(selectedLocation: DogClassLocation) =>
			dispatch(onUpdateSelectedClassLocationAction(selectedLocation)),
		[],
	);

	const distanceThrottle = useRef(null);
	/**
	 * On Change event for the distance slider. Only sets state when it's landed on expected value.
	 * Stops state from changing during slide event
	 * @param value The new value of the slider
	 */
	const safelyChangeDistance = useCallback(
		(value: string) => {
			if (DISTANCE_OPTIONS.includes(parseInt(value))) {
				// Stop changes on inbetween vals
				distanceThrottle.current = setTimeout(() => {
					onUpdateTravelDistance(parseInt(value));
				}, 300);
			}
		},
		[onUpdateTravelDistance],
	);

	const [regionsWithDistances, setRegionsWithDistances] = useState<
		DogClassRegion[]
	>([]);

	const [locationsWithDistances, setLocationsWithDistances] = useState<
		DogClassLocation[]
	>([]);

	const allDogSchoolLocations: DogClassLocation[] = useMemo(
		() =>
			allDogSchoolRegions?.flatMap(
				(region: DogClassRegion) => region.classLocations,
			) || [],
		[allDogSchoolRegions],
	);

	const dogSchoolRegionsInRange: DogClassRegion[] = useMemo(
		() =>
			allDogSchoolRegions.filter(
				(region: DogClassRegion) => region.name !== "Reactive Dog School",
			) || [],
		[allDogSchoolRegions],
	);
	const dogSchoolLocationsInRange: DogClassLocation[] = useMemo(
		() =>
			dogSchoolRegionsInRange?.flatMap(
				(region: DogClassRegion) => region.classLocations,
			) || [],
		[dogSchoolRegionsInRange],
	);

	useEffect(() => {
		setRegionsWithDistances([
			...dogSchoolRegionsInRange.map((region) => {
				const classLocations = region.classLocations.map((classLocation) => ({
					...classLocation,
					userDistance: userLocation
						? haversineDistance(userLocation, {
								latitude: classLocation.location.lat,
								longitude: classLocation.location.lon,
								address: "",
						  })
						: null,
				}));
				const userDistance = Math.min(
					...classLocations.map((cL) => cL.userDistance),
				);
				return {
					...region,
					classLocations,
					userDistance,
				};
			}),
		]);
		setLocationsWithDistances(() => [
			...dogSchoolLocationsInRange.map((classLocation) => ({
				...classLocation,
				userDistance: userLocation
					? haversineDistance(userLocation, {
							latitude: classLocation.location.lat,
							longitude: classLocation.location.lon,
							address: "",
					  })
					: null,
			})),
		]);
	}, [userLocation, dogSchoolLocationsInRange, dogSchoolRegionsInRange]);

	const selectedRegion = useMemo(
		() =>
			[...regionsWithDistances, ...dogSchoolRegionsInRange].find(
				(region) => region.name === selectedRegionName,
			),
		[selectedRegionName, regionsWithDistances, dogSchoolRegionsInRange],
	);

	return (
		<DogSchoolApiContext.Provider
			value={{
				allDogSchoolRegions: allDogSchoolRegions.filter((region) =>
					filterDogRegions(region, selectedType, userLocation, currentDistance),
				),
				allDogSchoolLocations: allDogSchoolLocations.filter((location) =>
					filterDogLocations(
						location,
						selectedType,
						userLocation,
						currentDistance,
					),
				),

				dogSchoolRegionsInRange: regionsWithDistances.filter((region) =>
					filterDogRegions(region, selectedType, userLocation, currentDistance),
				),

				dogSchoolLocationsInRange: locationsWithDistances.filter((location) =>
					filterDogLocations(
						location,
						selectedType,
						userLocation,
						currentDistance,
					),
				),

				selectedRegionName,
				selectedRegion: selectedRegion
					? {
							...selectedRegion,
							classLocations: selectedRegion?.classLocations.filter(
								(location) =>
									filterDogLocations(
										location,
										selectedType,
										userLocation,
										currentDistance,
									),
							),
					  }
					: null,
				selectedType,
				selectedLocation,

				userLocation,
				addressSuggestions,
				currentDistance,
				locationSearchTerm,
				sortBy,
				filteredByRegion,
				userDistanceToLocation,
				onLoadDogSchoolRegions,
				useDogSchoolQuery,
				onUpdateSelectedRegion,
				safelyChangeDistance,
				onUpdateLocation,

				onUpdateTravelDistance,
				onUpdateClassType,
				onUpdateSort,
				onClearSelectedRegion,
				onUpdateSelectedClassLocation,
			}}
		>
			{children}
		</DogSchoolApiContext.Provider>
	);
};
