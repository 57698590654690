// biome-ignore lint/suspicious/noExplicitAny: Legitimately need to use any here
export const unique = (value: any, index: number, self: any) => {
	return self.indexOf(value) === index;
};

export const uniqueSet = <T>(
	arr: T[],
	encoder: (item: T) => string = JSON.stringify,
	decoder: (item: string) => T = JSON.parse,
): T[] => {
	const uniqueItems = [...new Set(arr.map(encoder))];
	return uniqueItems.map(decoder);
};

export function shuffleArray<Type>(array: Type[]): Type[] {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
}
