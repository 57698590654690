import { DogSchoolApiState, DogSchoolFilterState } from "./DogSchoolApi.types";

import type { DogSchoolAction } from "./DogSchoolApi.actions";

const defaultFilterState: DogSchoolFilterState = {
	selectedRegionName: undefined,
	selectedLocation: undefined,
	selectedType: undefined,
	userLocation: null,
	currentDistance: 1000,
	addressSuggestions: [],
	locationSearchTerm: "",
	sortBy: "A",
	filteredByRegion: false,
	userDistanceToLocation: [],
};
export const dogSchoolDefaultState: DogSchoolApiState = {
	allDogSchoolRegions: [],
	...defaultFilterState,
};

// eslint-disable-next-line complexity
export function dogSchoolReducer(
	state: DogSchoolApiState,
	action: DogSchoolAction,
): DogSchoolApiState {
	switch (action.type) {
		case "LOAD_ALL_DOG_SCHOOL_REGIONS":
			return {
				...state,
				allDogSchoolRegions: action.dogClassRegions,
			};
		case "UPDATE_SELECTED_NAME":
			return { ...state, selectedRegionName: action.selectedRegionName };

		case "UPDATE_LOCATION_SUGGESTIONS":
			return {
				...state,
				addressSuggestions: action.locations || [],
			};

		case "UPDATE_TRAVEL_DISTANCE":
			return {
				...state,
				currentDistance: action.distance,
			};

		case "UPDATE_LOCATION":
			return {
				...state,
				locationSearchTerm: action.userLocation?.address || "",
				userLocation: action.userLocation,
				currentDistance: action.distance,
			};

		case "UPDATE_CLASS_TYPE":
			return { ...state, selectedType: action.selectedType };

		case "UPDATE_SORT_OPTION":
			return {
				...state,
				sortBy: action.selectedOption,
			};

		case "CLEAR_SELECTED_REGION":
			return {
				...state,
				selectedRegionName: undefined,
				selectedLocation: undefined,
			};

		case "UPDATE_USER_DISTANCE":
			return {
				...state,
				userDistanceToLocation: action.userDistance,
			};

		case "UPDATE_SELECTED_CLASS_LOCATION":
			return {
				...state,
				selectedLocation:
					action.selectedLocation.name !== state.selectedLocation?.name
						? action.selectedLocation
						: undefined,
			};

		default:
			return state;
	}
}
