import "iframe-resizer";
import React from "react";
import type { ReactNode } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { HelmetProvider } from "react-helmet-async";
import { CharityShopsProvider } from "./context/CharityShops/CharityShops.provider";
import { CmsMapProvider } from "./context/CmsMap/CmsMap.provider";
import { DogSchoolApiProvider } from "./context/DogSchoolApi/DogSchoolApi.provider";
import { DogSearchApiProvider } from "./context/DogSearchApi/DogSearchApi.provider";
import FavouritesManagerProvider from "./context/FavouritesManager/FavouritesManager.provider";
import { MyAccountProvider } from "./context/MyAccount/MyAccount.provider";
import { OurCentresProvider } from "./context/OurCentres/OurCentres.provider";
import { PageManagerProvider } from "./context/PageManager/PageManager.provider";
import SponsorDogsProvider from "./context/SponsorDogs/SponsorDogs.provider";
import VolunteerVacanciesProvider from "./context/VolunteerVacancies/VolunteerVacancies.provider";
import "./style/theme.scss";
import { NationalDogSurveyContextProvider } from "./templates/NationalDogSurvey/NationalDogSurvery.context";
interface Props {
	element: ReactNode;
}

const RootElement: React.FC<Props> = ({ element }) => {
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
			useRecaptchaNet={true}
			useEnterprise={true}
			scriptProps={{
				async: false,
				defer: false,
				appendTo: "head",
			}}
		>
			<PageManagerProvider>
				<MyAccountProvider>
					<HelmetProvider>
						<OurCentresProvider>
							<CharityShopsProvider>
								<CmsMapProvider>
									<DogSchoolApiProvider>
										<SponsorDogsProvider>
											<VolunteerVacanciesProvider>
												<DogSearchApiProvider>
													<FavouritesManagerProvider>
														<NationalDogSurveyContextProvider>
															{element}
														</NationalDogSurveyContextProvider>
													</FavouritesManagerProvider>
												</DogSearchApiProvider>
											</VolunteerVacanciesProvider>
										</SponsorDogsProvider>
									</DogSchoolApiProvider>
								</CmsMapProvider>
							</CharityShopsProvider>
						</OurCentresProvider>
					</HelmetProvider>
				</MyAccountProvider>
			</PageManagerProvider>
		</GoogleReCaptchaProvider>
	);
};

export const wrapRootElement = RootElement;
