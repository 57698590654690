import React, { useCallback, useEffect, useState } from "react";

import { SortOption } from "@dogstrust/src/context/DogSearchApi/DogSearchApi.types";
import { unique } from "@dogstrust/src/utils/array";

import { getFilteredDogs } from "@dogstrust/src/containers/sections/SectionSADFiltersList/SectionSADFiltersList.utils";
import { useMemoCompare } from "@dogstrust/src/hooks/useMemoCompare";
import { haversineDistance } from "@dogstrust/src/utils/distance";
import { mapSponsorDogToCard } from "@dogstrust/src/utils/mappers";
import { useMemo } from "react";
import { usePageManager } from "..";
import { SponsorDogsContext } from "./SponsorDogs.context";
import { compareSponsorDogs } from "./SponsorDogs.utils";

const SponsorDogsProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [userHasUpdatedFilters, setUserHasUpdatedFilters] = useState(false);
	const [sponsorDogs, setSponsorDogs] = useState<
		Queries.SponsorDogDataFragment[]
	>([]);
	const [userLocation, setUserLocation] = useState<Place | null>(null);
	const [currentDistance, setCurrentDistance] = useState<number>(1000);
	const [showFilters, setShowFilters] = useState(false);
	const [showSortOptions, setShowSortOptions] = useState(false);
	const [sortBy, setSortBy] = useState<SortOption>("NEW");

	const [selectedLocations, setSelectedLocations] = useState([]);
	const [selectedBreeds, setSelectedBreeds] = useState([]);

	const [canVisit, setCanVisit] = useState(false);

	const availableBreeds = sponsorDogs
		.map((dog) => dog.relationships.field_breed[0].name)
		.filter(unique)
		.sort();
	useEffect(() => {
		if (userLocation)
			setSortBy((currentSort) =>
				currentSort === "NEW" ? "NEAR" : currentSort,
			);
		else {
			setSortBy("NEW");
		}
	}, [userLocation]);

	// useMemo is good with primitive types, but not with objects or arrays so we use useMemoCompare to do a deep comparison

	const sponsorDogsWithDistance = useMemoCompare<SponsorDogWithDistance[]>(
		sponsorDogs.map((dog) => ({
			...dog,
			distance: haversineDistance(
				{
					latitude: parseFloat(dog.rehomingCentre.field_latitude),
					longitude: parseFloat(dog.rehomingCentre.field_longitude),
					address: "",
				},
				userLocation,
			),
		})),
		compareSponsorDogs,
	);
	const filteredDogs = useMemo(
		() =>
			mapSponsorDogToCard(
				getFilteredDogs(
					sponsorDogsWithDistance,
					2000,
					selectedBreeds,
					canVisit,
					sortBy,
				),
			),
		[sponsorDogsWithDistance, selectedBreeds, canVisit, sortBy],
	);
	const onReset = () => {
		setSelectedLocations([]);
		setSelectedBreeds([]);
		setSortBy("NEW");
		setShowFilters(false);
		setShowSortOptions(false);
		setUserLocation(null);
		setUserHasUpdatedFilters(true);
		setCanVisit(false);
		window.localStorage.setItem("persistedSADFilters", "");
	};

	const { loadPagination } = usePageManager();

	useEffect(() => {
		if (filteredDogs.length > 0) {
			loadPagination(
				filteredDogs.map((dog) => dog.url),
				filteredDogs.length,
				"/support-us/sponsor/dogs",
			);
		}
	}, [filteredDogs, loadPagination]);

	useEffect(() => {
		if (userHasUpdatedFilters) {
			window.localStorage.setItem(
				"persistedSADFilters",
				JSON.stringify({
					userLocation,
					sortBy,
					selectedBreeds,
					selectedLocations,
					canVisit,
				}),
			);
		}
	}, [
		userHasUpdatedFilters,
		userLocation,
		sortBy,
		selectedBreeds,
		selectedLocations,
		canVisit,
	]);

	useEffect(() => {
		if (!userHasUpdatedFilters) {
			const persistedState = window.localStorage.getItem("persistedSADFilters");
			if (persistedState) {
				const parsedState = JSON.parse(persistedState);
				setUserLocation(parsedState.userLocation);
				setSortBy(parsedState.sortBy);
				setSelectedBreeds(parsedState.selectedBreeds);
				setSelectedLocations(parsedState.selectedLocations);
				setCanVisit(parsedState.canVisit);
			}
		}
	}, [userHasUpdatedFilters]);

	const onChangeLocation = useCallback(
		(location: Place | null, distance: number) => {
			setUserHasUpdatedFilters(true);
			setUserLocation(location);
			setCurrentDistance(distance);
		},
		[],
	);
	const updateSponsorDogs = useCallback(
		(sponsorDogs: Queries.SponsorDogDataFragment[]) => {
			setUserHasUpdatedFilters(true);
			setSponsorDogs(sponsorDogs);
		},
		[],
	);
	const updateShowFilters = useCallback((showFilters: boolean) => {
		setUserHasUpdatedFilters(true);
		setShowFilters(showFilters);
	}, []);
	const updateShowSortOptions = useCallback((showSortOptions: boolean) => {
		setUserHasUpdatedFilters(true);
		setShowSortOptions(showSortOptions);
	}, []);
	const updateSortBy = useCallback((sortBy: SortOption) => {
		setUserHasUpdatedFilters(true);
		setSortBy(sortBy);
	}, []);
	const updateCanVisit = useCallback((canVisit: boolean) => {
		setUserHasUpdatedFilters(true);
		setCanVisit(canVisit);
	}, []);

	const onUpdateBreeds = useCallback(
		(breed: string) => {
			setUserHasUpdatedFilters(true);
			if (!selectedBreeds.includes(breed))
				setSelectedBreeds([...selectedBreeds, breed]);
			else setSelectedBreeds(selectedBreeds.filter((l) => l !== breed));
		},
		[selectedBreeds],
	);
	return (
		<SponsorDogsContext.Provider
			value={{
				filteredDogs,
				userLocation,
				showFilters,
				showSortOptions,
				sortBy,
				selectedBreeds,
				availableBreeds,
				canVisit,
				selectedLocations,
				currentDistance,
				onChangeLocation,
				setSponsorDogs: updateSponsorDogs,
				setShowFilters: updateShowFilters,
				setShowSortOptions: updateShowSortOptions,
				setSortBy: updateSortBy,
				setCanVisit: updateCanVisit,
				onUpdateBreeds,
				onReset,
			}}
		>
			{children}
		</SponsorDogsContext.Provider>
	);
};

export default SponsorDogsProvider;
