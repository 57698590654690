/**
 * Gets a cookie of the given name
 * @param name The name of the cookie to retrieve
 * @returns The cookie data
 */
export const getCookie = (name: string): string => {
	if (!name || typeof window === "undefined" || !window.document) return null;
	const nameLenPlus = name.length + 1;
	return (
		document.cookie
			.split(";")
			.map((c) => c.trim())
			.filter((cookie) => {
				return cookie.substring(0, nameLenPlus) === `${name}=`;
			})
			.map((cookie) => {
				return decodeURIComponent(cookie.substring(nameLenPlus));
			})[0] || null
	);
};

/**
 * Given an array of SAD dogs, sets the SAD dog cookie
 * @param sadDogs The array of SAD dogs
 * @param isGift Whether the sponsorship is a gift
 */
export const setSadCookie = (sadDogs: SADCookie[], amount: number) => {
	if (typeof window === "undefined" || !window.document) return;
	const today = new Date();
	const expire = new Date();
	expire.setTime(
		today.getTime() +
			3600000 * 24 * parseInt(process.env.GATSBY_SAD_COOKIE_EXPIRY || "3"),
	);
	const sharedCookie = `${process.env.GATSBY_SAD_COOKIE_NAME}=${JSON.stringify({
		dog_sponsorships: sadDogs.map((dog) => ({
			name: dog.name,
			fundCode: dog.fundCode,
			imagePath: dog.imagePath,
			imageBaseUrl: dog.imageBaseUrl,
			amount: amount.toFixed(2), // HMD are expecting this to be per dog, not per basket
			isGift: dog.isGift || false,
		})),
	})};expires=${expire.toUTCString()};domain=${
		process.env.GATSBY_COOKIE_DOMAIN
	};path=/`;
	document.cookie = sharedCookie;
};

export const setFavDogCreateAccCookie = () => {
	if (typeof window === "undefined" || !window.document) return;
	const expire = new Date();
	expire.setTime(
		expire.getTime() +
			parseInt(process.env.GATSBY_FAVDOG_CREATE_ACC_COOKIE_EXPIRY) * 60 * 1000,
	);
	const cookieValue = `${encodeURIComponent(
		true,
	)};expires=${expire.toUTCString()}`;
	document.cookie = `${process.env.GATSBY_FAVDOG_CREATE_ACC_COOKIE_NAME}=${cookieValue};domain=${process.env.GATSBY_COOKIE_DOMAIN};path=/`;
};

export const setDogToEnquireCookie = (
	dog: ESDocDog,
	dogData: DogDataTracking,
) => {
	if (typeof window === "undefined" || !window.document) return;
	const expire = new Date();
	expire.setMinutes(
		expire.getMinutes() +
			parseInt(process.env.GATSBY_DOG_TO_ENQUIRE_COOKIE_EXPIRY),
	);

	localStorage.setItem(
		process.env.GATSBY_DOG_TO_ENQUIRE_COOKIE_NAME,
		JSON.stringify({ dog, dogData, expires: expire.getTime() }),
	);
};

export const getDogToEnquireCookie = (): {
	dog: ESDocDog;
	dogData: DogDataTracking;
	expires: number;
} => {
	if (typeof window === "undefined" || !window.document) return null;
	const enquireCookie = localStorage.getItem(
		process.env.GATSBY_DOG_TO_ENQUIRE_COOKIE_NAME,
	);
	if (!enquireCookie) return null;
	const parsedCookie = JSON.parse(enquireCookie);
	if (parsedCookie.expires < new Date().getTime()) {
		console.log("cookieExpired");
		localStorage.removeItem(process.env.GATSBY_DOG_TO_ENQUIRE_COOKIE_NAME);
		return null;
	}
	return parsedCookie;
};
