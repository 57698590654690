import { CentresSortOption } from "./OurCentres.types";

// ========================== LOAD_ALL_CENTRES ===================================
export const LOAD_ALL_CENTRES = "LOAD_ALL_CENTRES";
interface LoadOurCentresAction {
	type: typeof LOAD_ALL_CENTRES;
	allCentres: Queries.RehomingCentreDataFragment[];
}

export const onLoadOurCentresAction = (
	allCentres: Queries.RehomingCentreDataFragment[],
): LoadOurCentresAction => ({
	type: LOAD_ALL_CENTRES,
	allCentres,
});

// ========================== SELECT_CENTRE ===================================
export const SELECT_CENTRE = "SELECT_CENTRE";
interface SelectCentreAction {
	type: typeof SELECT_CENTRE;
	centreCode: string;
}

export const onSelectCentreAction = (
	centreCode: string,
): SelectCentreAction => ({
	type: SELECT_CENTRE,
	centreCode,
});

// ========================== UPDATE_SORT_OPTION ===================================
export const UPDATE_SORT_OPTION = "UPDATE_SORT_OPTION";
interface UpdateSortOptionAction {
	type: typeof UPDATE_SORT_OPTION;
	selectedOption: CentresSortOption;
}

export const onUpdateSortOptionAction = (
	selectedOption: CentresSortOption,
): UpdateSortOptionAction => ({
	type: UPDATE_SORT_OPTION,
	selectedOption,
});

// ========================== LOAD_MORE ==================================
export const LOAD_MORE = "LOAD_MORE";
interface LoadMoreAction {
	type: typeof LOAD_MORE;
}
export const onLoadMoreAction = (): LoadMoreAction => ({
	type: LOAD_MORE,
});

// ========================== UPDATE_TRAVEL_DISTANCE =========================
export const UPDATE_TRAVEL_DISTANCE = "UPDATE_TRAVEL_DISTANCE";
interface UpdateTravelDistanceAction {
	type: typeof UPDATE_TRAVEL_DISTANCE;
	distance: number;
}
export const onUpdateTravelDistanceAction = (
	distance: number,
): UpdateTravelDistanceAction => ({
	type: UPDATE_TRAVEL_DISTANCE,
	distance,
});

// ========================== UPDATE_LOCATION_SUGGESTIONS ====================
export const UPDATE_LOCATION_SUGGESTIONS = "UPDATE_LOCATION_SUGGESTIONS";
interface UpdateLocationSuggestionsAction {
	type: typeof UPDATE_LOCATION_SUGGESTIONS;
	locations: string[];
}
export const onUpdateLocationSuggestionsAction = (
	locations: string[],
): UpdateLocationSuggestionsAction => {
	return {
		type: UPDATE_LOCATION_SUGGESTIONS,
		locations,
	};
};

// ========================== CONFIRM_USER_LOCATION ==========================// ========================== UPDATE_LOCATION ==========================
export const UPDATE_LOCATION = "UPDATE_LOCATION";
interface UpdateLocationAction {
	type: typeof UPDATE_LOCATION;
	userLocation: Place | null;
	distance: number;
}

export const onUpdateLocationAction = (
	userLocation: Place | null,
	distance: number,
): UpdateLocationAction => ({ type: UPDATE_LOCATION, userLocation, distance });

// ========================== CLEAR_USER_LOCATION ==========================
export const CLEAR_USER_LOCATION = "CLEAR_USER_LOCATION";
interface ClearUserLocationAction {
	type: typeof CLEAR_USER_LOCATION;
}

export const onClearUserLocationAction = (): ClearUserLocationAction => ({
	type: CLEAR_USER_LOCATION,
});

// ========================== UPDATE_CENTRES_IN_RANGE ==========================
export const UPDATE_CENTRES_IN_RANGE = "UPDATE_CENTRES_IN_RANGE";
interface UpdateCentresInRangeAction {
	type: typeof UPDATE_CENTRES_IN_RANGE;
	centresInRange: Queries.RehomingCentreDataFragment[];
	distanceToCentres: { [key: string]: number };
}

export const onUpdateCentresInRangeAction = (
	centresInRange: Queries.RehomingCentreDataFragment[],
	distanceToCentres: { [key: string]: number },
): UpdateCentresInRangeAction => ({
	type: UPDATE_CENTRES_IN_RANGE,
	centresInRange,
	distanceToCentres,
});

export type OurCentresAction =
	| LoadOurCentresAction
	| SelectCentreAction
	| UpdateSortOptionAction
	| LoadMoreAction
	| UpdateTravelDistanceAction
	| UpdateLocationSuggestionsAction
	| UpdateLocationAction
	| ClearUserLocationAction
	| UpdateCentresInRangeAction;
