import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
	NDS_Q_8_ORDER,
	NDS_Q_14_ORDER,
	NDS_Q_17_ORDER,
	NDS_Q_19_ORDER,
	NDS_Q_20_ORDER,
	NDS_Q_21_ORDER,
	NDS_Q_24_ORDER,
	NDS_Q_E4_OPTIONS_ORDER,
} from "../consts";

export const usePersistSurveyData = (
	warmData: NDSWarmUserData,
	routeStack: NDSScreenState[],
	surveyProgress: NDSSurveyProgress,
	playedTransitions: NDSTransition[],
	formData: NDSFormData,
	currentDogs: Record<number, Record<string, string | string[]>>,
	surveyStartTime: number,
	supporterId: string | null,
	surveyResponseId: string | null,
) => {
	useEffect(() => {}, []);

	useEffect(() => {
		localStorage.setItem("nds-survey-status", "started");
		localStorage.setItem("nds-warmData", JSON.stringify(warmData));
	}, [warmData]);
	useEffect(() => {
		localStorage.setItem("nds-survey-status", "started");

		localStorage.setItem("nds-routeStack", JSON.stringify(routeStack));
	}, [routeStack]);
	useEffect(() => {
		localStorage.setItem("nds-survey-status", "started");

		localStorage.setItem("nds-surveyProgress", JSON.stringify(surveyProgress));
	}, [surveyProgress]);
	useEffect(() => {
		localStorage.setItem("nds-survey-status", "started");

		localStorage.setItem(
			"nds-playedTransitions",
			JSON.stringify(playedTransitions),
		);
	}, [playedTransitions]);
	useEffect(() => {
		localStorage.setItem("nds-survey-status", "started");

		localStorage.setItem("nds-currentDogs", JSON.stringify(currentDogs));
	}, [currentDogs]);
	useEffect(() => {
		localStorage.setItem("nds-survey-status", "started");

		localStorage.setItem("nds-formData", JSON.stringify(formData));
	}, [formData]);

	useEffect(() => {
		localStorage.setItem("nds-survey-status", "started");

		localStorage.setItem("nds-surveyStartTime", surveyStartTime.toString());
	}, [surveyStartTime]);

	useEffect(() => {
		localStorage.setItem("nds-survey-started", "true");
		localStorage.setItem("nds-supporterId", supporterId || "");
	}, [supporterId]);

	useEffect(() => {
		localStorage.setItem("nds-survey-started", "true");
		localStorage.setItem("nds-surveyResponseId", surveyResponseId || "");
	}, [surveyResponseId]);

	const clearSurveyCookies = () => {
		localStorage.removeItem("nds-survey-status");
		localStorage.removeItem("nds-warmData");
		localStorage.removeItem("nds-routeStack");
		localStorage.removeItem("nds-surveyProgress");
		localStorage.removeItem("nds-playedTransitions");
		localStorage.removeItem("nds-currentDogs");
		localStorage.removeItem("nds-formData");
		localStorage.removeItem("nds-naqFields");
		localStorage.removeItem("nds-surveyStartTime");
		localStorage.removeItem("nds-supporterId");
		localStorage.removeItem("nds-survey-is-complete");
		localStorage.removeItem("nds-surveyResponseId");

		localStorage.removeItem(NDS_Q_8_ORDER);
		localStorage.removeItem(NDS_Q_14_ORDER);
		localStorage.removeItem(NDS_Q_17_ORDER);
		localStorage.removeItem(NDS_Q_19_ORDER);
		localStorage.removeItem(NDS_Q_20_ORDER);
		localStorage.removeItem(NDS_Q_21_ORDER);
		localStorage.removeItem(NDS_Q_24_ORDER);
		localStorage.removeItem(NDS_Q_E4_OPTIONS_ORDER);
	};

	const exportSurveyCookies = () => {
		const surveyState = JSON.stringify({
			warmData,
			routeStack,
			surveyProgress,
			playedTransitions,
			currentDogs,
			formData,
			supporterId,
			surveyResponseId,
		});
		// Copy to clipboard

		navigator.clipboard.writeText(surveyState);
		return JSON.stringify(surveyState);
	};

	const importSurveyCookies = (data: string) => {
		const surveyState = JSON.parse(data);

		if (
			surveyState.routeStack &&
			surveyState.surveyProgress &&
			surveyState.playedTransitions &&
			surveyState.currentDogs &&
			surveyState.formData
		) {
			clearSurveyCookies();
			localStorage.setItem(
				"nds-warmData",
				JSON.stringify(surveyState.warmData),
			);
			localStorage.setItem(
				"nds-routeStack",
				JSON.stringify(surveyState.routeStack),
			);
			localStorage.setItem(
				"nds-surveyProgress",
				JSON.stringify(surveyState.surveyProgress),
			);
			localStorage.setItem(
				"nds-playedTransitions",
				JSON.stringify(surveyState.playedTransitions),
			);
			localStorage.setItem(
				"nds-currentDogs",
				JSON.stringify(surveyState.currentDogs),
			);
			localStorage.setItem(
				"nds-formData",
				JSON.stringify(surveyState.formData),
			);
			localStorage.setItem(
				"nds-naqFields",
				JSON.stringify(surveyState.naqFields),
			);
			localStorage.setItem(
				"nds-surveyStartTime",
				surveyState.surveyStartTime?.toString(),
			);
			localStorage.setItem("nds-supporterId", surveyState.supporterId || "");
			localStorage.setItem("nds-survey-status", "started");

			localStorage.setItem(
				"nds-surveyResponseId",
				surveyState.surveyResponseId || uuidv4(),
			);
			window.location.reload();
			return;
		}

		alert("Error loading survey state: Invalid data.");
	};
	return { clearSurveyCookies, exportSurveyCookies, importSurveyCookies };
};
