import React from "react";
import { DogSchoolContextState } from "./DogSchoolApi.types";

export const DogSchoolApiContext = React.createContext<DogSchoolContextState>({
	onLoadDogSchoolRegions: () => {},

	allDogSchoolRegions: [],
	dogSchoolRegionsInRange: [],
	allDogSchoolLocations: [],
	dogSchoolLocationsInRange: [],
	selectedRegion: undefined,
	selectedType: undefined,
	addressSuggestions: [],
	currentDistance: 1000,
	userLocation: null,
	locationSearchTerm: "",
	sortBy: "A",
	filteredByRegion: false,
	selectedRegionName: "",
	userDistanceToLocation: undefined,
	useDogSchoolQuery: () => {},
	onUpdateSelectedRegion: () => {},
	safelyChangeDistance: () => {},
	onUpdateTravelDistance: () => {},
	onUpdateClassType: () => {},
	onUpdateSort: () => {},
	onClearSelectedRegion: () => {},
	onUpdateSelectedClassLocation: () => {},
	onUpdateLocation: () => {},
});
