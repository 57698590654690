export const formatDate = (date: Date) => {
	const nth = (d: number) => {
		if (d > 3 && d < 21) return "th";
		switch (d % 10) {
			case 1:
				return "st";
			case 2:
				return "nd";
			case 3:
				return "rd";
			default:
				return "th";
		}
	};

	const month = (month: number) => {
		return [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		][month];
	};
	const dayOfMonth = date.getDate();
	return `${dayOfMonth}${nth(dayOfMonth)} ${month(
		date.getMonth(),
	)} ${date.getFullYear()}`;
};

export const formatDateNoYear = (date: Date) => {
	const nth = (d: number) => {
		if (d > 3 && d < 21) return "th";
		switch (d % 10) {
			case 1:
				return "st";
			case 2:
				return "nd";
			case 3:
				return "rd";
			default:
				return "th";
		}
	};

	const month = (month: number) => {
		return [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		][month];
	};
	const dayOfMonth = date.getDate();
	return `${dayOfMonth}${nth(dayOfMonth)} ${month(date.getMonth())}`;
};

export const formatShortDateNoYear = (date: Date) => {
	const nth = (d: number) => {
		if (d > 3 && d < 21) return "th";
		switch (d % 10) {
			case 1:
				return "st";
			case 2:
				return "nd";
			case 3:
				return "rd";
			default:
				return "th";
		}
	};

	const month = (month: number) => {
		return [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"June",
			"July",
			"Aug",
			"Sept",
			"Oct",
			"Nov",
			"Dec",
		][month];
	};
	const dayOfMonth = date.getDate();
	return `${dayOfMonth}${nth(dayOfMonth)} ${month(date.getMonth())}`;
};

export const getDogAgeBand = (dogDOB: string) => {
	function monthDiff(d1: Date, d2: Date) {
		let months: number;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	const months = monthDiff(new Date(dogDOB), new Date());

	if (months < 6) {
		return "Under 6 months";
	} else if (months < 12) {
		return "6 - 12 months";
	} else if (months < 24) {
		return "1 - 2 years";
	} else if (months < 60) {
		return "2 - 5 years";
	} else if (months < 96) {
		return "5 - 7 years";
	} else {
		return "8 + years";
	}
};
/*
Formats the date as dd/mm/yy hh:mm
*/
export const formatDateTime = (date: Date) => {
	const pad = (n: number) => (n < 10 ? `0${n}` : n);
	return `${pad(date.getDate())}/${pad(
		date.getMonth() + 1,
	)}/${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getMinutes())}`;
};
export const getDogAgeBandArticleTaxonomy = (dogDOB: string) => {
	function monthDiff(d1: Date, d2: Date) {
		let months: number;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	const months = monthDiff(new Date(dogDOB), new Date());

	if (months < 6) {
		return "AGE_UNDER_6_MONTHS";
	} else if (months < 12) {
		return "AGE_6_TO_12_MONTHS";
	} else if (months < 24) {
		return "AGE_1_TO_2_YEARS";
	} else if (months < 60) {
		return "AGE_2_TO_5_YEARS";
	} else if (months < 96) {
		return "AGE_5_TO_7_YEARS";
	} else {
		return "AGE_8_PLUS";
	}
};
