import React from "react";
import { CharityShopsContextState } from "./CharityShops.types";

export const CharityShopsContext =
	React.createContext<CharityShopsContextState>({
		selectedCharityShopName: "",
		charityShopsLocations: [],
		charityShopsInRange: [],
		addressSuggestions: [],
		sortBy: "A",
		pagination: 0,
		currentDistance: 1000,
		userLocation: null,
		locationSearchTerm: "",
		distanceToCharityShops: {},
		onLoadCharityShops: () => {},
		onSelectCharityShops: () => {},
		safelyChangeDistance: () => {},
		onUpdateTravelDistance: () => {},
		onUpdateLocation: () => {},
		onUpdateSort: () => {},
		onLoadMore: () => {},
	});
