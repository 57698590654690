import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

import fetch from "isomorphic-fetch";
import { hashCode } from "../utils";

const uri = process.env.GATSBY_DS_SEARCH_GATEWAY_API;

const customFetch = (
	_path: string,
	options: {
		body: string;
		method: string;
	},
) => {
	const hash = hashCode(options.body);
	return fetch(`${uri}?cacheKey=${hash}`, options);
};
export const cacheableLink = createHttpLink({
	fetch: customFetch,
	credentials:
		process.env.GATSBY_ENVIRONMENT === "development"
			? "same-origin"
			: "include",
});

const client = new ApolloClient({
	link: cacheableLink,
	cache: new InMemoryCache({
		resultCaching: true,
	}),
	connectToDevTools: true,
});

export default client;
