import * as styles from "./FormTextInputAutoSuggest.module.scss";

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import React, { HTMLAttributes, useEffect, useRef, useState } from "react";

import cx from "classnames";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

export interface FormTextInputAutoSuggestProps
	extends HTMLAttributes<HTMLInputElement> {
	label?: string;
	name: string;
	type?: "text" | "email" | "password";
	placeholder: string;
	onChangeCallback?: (value: string) => void;
	onConfirmCallback?: (value: string) => void;
	ariaLabel?: string;
	ariaIconLeftHidden?: boolean;
	ariaIconRightHidden?: boolean;
	ariaIconLeftLabel?: string;
	ariaIconRightLabel?: string;
	IconLeft?: React.FC<IconProps>; // TODO Implement Icons
	IconRight?: React.FC<IconProps>; // TODO Implement Icons
	onIconLeftCallback?: () => void;
	onIconRightCallback?: () => void;

	iconPosition?: "left" | "right";
	errorMessage?: string;
	suggestions: string[];
	inputValue: string;
	noIcons?: boolean;
}

export const FormTextInputAutoSuggest: React.FC<FormTextInputAutoSuggestProps> =
	({
		label,
		name,
		onChangeCallback,
		onConfirmCallback,
		ariaIconLeftHidden = false,
		ariaIconRightHidden = false,
		ariaIconLeftLabel,
		ariaIconRightLabel,
		IconLeft,
		IconRight,
		onIconLeftCallback,
		onIconRightCallback,
		className,
		suggestions,
		inputValue,
		noIcons,
		...rest
	}) => {
		const [showSuggestions, setShowSuggestions] = useState(false);

		const {
			register,
			unregister,
			setValue,
			formState: { isDirty, errors },
		} = useFormContext();

		useEffect(() => () => unregister(name), [unregister, name]);

		const [error, setError] = useState(errors[name]);
		const [hasFocus, setHasFocus] = useState(false);
		const getInputStateStyles = () => {
			if (IconLeft) return styles.formtextinputwithlefticon;
			return styles.formtextinput;
		};
		// biome-ignore lint/correctness/useExhaustiveDependencies: isDirty is important to trigger the useEffect
		useEffect(() => {
			setError(errors[name]);
		}, [isDirty, errors, name]);

		const clearErrorOnChange = useCallback(
			async (value: string) => {
				if (error) setError(false);

				if (value.length > 2 && suggestions.length) {
					setShowSuggestions(true);
				} else {
					setShowSuggestions(false);
				}
				return onChangeCallback(value);
			},
			[onChangeCallback, error, suggestions],
		);

		const suggestionOnClick = (value: string) => {
			if (value !== "NO RESULTS") {
				onConfirmCallback(value);
				setValue(name, value);
			}
			setShowSuggestions(false);
		};

		useEffect(() => {
			if (suggestions.length > 0) {
				setShowSuggestions(true);
			}
		}, [suggestions]);

		const hideSuggestionsTimeout = useRef<NodeJS.Timeout | null>(null);
		const hideSuggestions = () => {
			hideSuggestionsTimeout.current = setTimeout(() => {
				setShowSuggestions(false);
				setHasFocus(false);
			}, 200);
		};

		useEffect(() => () => clearTimeout(hideSuggestionsTimeout.current), []);
		return (
			<div className={styles.textinputwrapper}>
				{label && (
					<label htmlFor={name} className={styles.textinputlabel}>
						{label}
					</label>
				)}
				<div className={styles.inputwithicon}>
					{IconLeft && !noIcons && (
						<button
							type="button"
							className={styles.iconleft}
							onClick={onIconLeftCallback}
							aria-hidden={ariaIconLeftHidden}
							aria-label={ariaIconLeftLabel}
						>
							{<IconLeft height={"25px"} width={"25px"} />}
						</button>
					)}
					{IconRight && !noIcons && (
						<button
							type="button"
							className={styles.iconright}
							style={{
								cursor: onIconRightCallback ? "pointer" : "auto",
							}}
							onClick={onIconRightCallback}
							aria-hidden={ariaIconRightHidden}
							aria-label={ariaIconRightLabel}
						>
							{<IconRight height={"25px"} width={"25px"} />}
						</button>
					)}
					<input
						{...register(name)}
						tabIndex={0}
						name={name}
						id={label ? label : name}
						className={cx(getInputStateStyles(), className)}
						{...rest}
						onFocus={async (e) => {
							setHasFocus(true);
							await clearErrorOnChange(e.target.value);
						}}
						onChange={async (e) => await clearErrorOnChange(e.target.value)}
						onBlur={hideSuggestions}
						title={label}
						value={inputValue}
						aria-autocomplete="list"
						autoComplete="off"
						aria-controls={showSuggestions && hasFocus ? "suggestions" : null}
					/>
					{showSuggestions && hasFocus && (
						<div className={styles.suggestionbox}>
							<ul id="suggestions" className={styles.suggestionboxresults}>
								{suggestions.map((suggestion) => (
									<button
										role="listbox"
										type="button"
										key={suggestion}
										className={styles.suggestion}
										onClick={() => suggestionOnClick(suggestion)}
										onFocus={() => {
											clearTimeout(hideSuggestionsTimeout.current);
										}}
										onBlur={hideSuggestions}
										onKeyDown={(e) =>
											e.key === "Enter" && suggestionOnClick(suggestion)
										}
									>
										{suggestion}
									</button>
								))}
							</ul>
						</div>
					)}
				</div>
				{!!error && <p className={styles.errormessage}>{error.message}</p>}
			</div>
		);
	};

export default FormTextInputAutoSuggest;
