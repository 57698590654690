import { DEFAULT_MULTI_DOG_PROGRESS } from "..";
import { NDS_Q_20_ORDER } from "../../../consts";
import { getMultiDogStatus } from "./utils";
import { getNextScreenWellbeingAndSupportRoute } from "./wellbeingAndSupport";

/**
 * Given the current screen and form data, returns the next screen for the about your dogs route
 * @param currentScreen	- The current screen
 * @param formData	- The form data
 * @param _questionSchema - The question schema
 * @returns The next screen
 */

/**
 * This maps the follow up questions to the likert scale question
 */
export const followUpQuestionMap: Record<string, string> = {
	"nds-q-20.A-recall": "nds-q-20.1.A",
	"nds-q-20.B-greet-politely": "nds-q-20.1.B",
	"nds-q-20.C-leave-it": "nds-q-20.1.C",
	"nds-q-20.D-home-alone": "nds-q-20.1.D",
	"nds-q-20.E-loose-lead-walking": "nds-q-20.1.E",
	"nds-q-20.F-other-dogs": "nds-q-20.1.F",
	"nds-q-20.G-fireworks": "nds-q-20.1.G",
	"nds-q-20.H-strangers": "nds-q-20.1.H",
	"nds-q-20.I-sleep-through-night": "nds-q-20.1.I",
	"nds-q-20.J-door-barking": "nds-q-20.1.J",
};

export const getQuestion20FollowUps = (
	formData: NDSFormData,
	currentDog: Record<string, string | string[]>,
	questionSchema: NDSQuestionSchema,
): string[] => {
	const questionOrderFromForm = formData[NDS_Q_20_ORDER] as string[];

	const questionOrder =
		questionOrderFromForm?.length > 0
			? questionOrderFromForm
			: [
					"nds-q-20.A-recall",
					"nds-q-20.B-greet-politely",
					"nds-q-20.C-leave-it",
					"nds-q-20.D-home-alone",
					"nds-q-20.E-loose-lead-walking",
					"nds-q-20.F-other-dogs",
					"nds-q-20.G-fireworks",
					"nds-q-20.H-strangers",
					"nds-q-20.I-sleep-through-night",
					"nds-q-20.J-door-barking",
				];

	let followUpQuestions: string[] = [];
	for (const question of questionOrder) {
		const answer = currentDog[question] as string;
		const fields = questionSchema.routes["reading-your-dog"]["nds-q-20"].fields;
		const answerOne = fields.find(({ name }) => name === question)
			?.likertRange[0];
		const answerTwo = fields.find(({ name }) => name === question)
			?.likertRange[1];

		if (answer === answerOne) {
			followUpQuestions = [followUpQuestionMap[question], ...followUpQuestions];
		}
		if (answer === answerTwo) {
			followUpQuestions = [...followUpQuestions, followUpQuestionMap[question]];
		}
	}
	return followUpQuestions.filter(Boolean).slice(0, 3);
};

export const getNextScreenReadingYourDogsRoute = (
	screenState: NDSScreenState,
	formData: NDSFormData,
	questionSchema: NDSQuestionSchema,
	currentDogs: Record<number, Record<string, string | string[]>>,
	surveyProgress: NDSSurveyProgress,
):
	| [NDSScreenState, NDSSurveyProgress]
	| [NDSScreenState, NDSSurveyProgress, NDSTransition] => {
	const currentScreen = screenState.screen;
	const currentDogIndex = screenState.dogIndex;

	if (currentScreen === "out-and-about-with-your-dog-intro") {
		//! Intro screen
		return [
			{ ...screenState, route: "reading-your-dog", screen: "nds-q-10" },
			{
				...surveyProgress,
				multiDog: DEFAULT_MULTI_DOG_PROGRESS,
			},
		];
	}
	if (currentScreen === "nds-q-10") {
		// ! How frequently do you walk <Dog’s Name> / your dog(s) in the following areas?
		return [
			{ ...screenState, route: "reading-your-dog", screen: "nds-q-11" },
			{ ...surveyProgress, lifeWithYourDog: 10 },
		]; // What equipment does <Dog’s Name> typically wear when going for a walk?
	}
	if (currentScreen === "nds-q-11") {
		//! What equipment does <Dog’s Name> typically wear when going for a walk?

		// Question 12 is only asked once
		if (currentDogIndex === 1) {
			return [
				{ ...screenState, route: "reading-your-dog", screen: "nds-q-12" },
				{
					...surveyProgress,
					lifeWithYourDog: 20,
					multiDog: DEFAULT_MULTI_DOG_PROGRESS,
				},
			]; // When out walking with your dog… (dog interactions)
		}
		return [
			{ ...screenState, route: "reading-your-dog", screen: "nds-q-13" },
			{
				...surveyProgress,
				multiDog: { ...surveyProgress.multiDog, lifeWithYourDog: 33 },
			},
		]; // Has <Dog’s Name> ever been bitten or attacked by another dog while out on a walk?
	}
	if (currentScreen === "nds-q-12") {
		//! When out walking with your dog… (dog interactions)
		// * THIS SCREEN IS ONLY ASKED ONCE
		return [
			{ ...screenState, route: "reading-your-dog", screen: "nds-q-13" },
			{ ...surveyProgress, lifeWithYourDog: 30 },
		]; // Has <Dog’s Name> ever been bitten or attacked by another dog while out on a walk?
	}
	if (currentScreen === "nds-q-13") {
		//! Has <Dog’s Name> ever been bitten or attacked by another dog while out on a walk?

		// Question 14, 16, 17, 18, 19 are only asked once
		if (currentDogIndex === 1) {
			return [
				{
					...screenState,
					route: "reading-your-dog",
					screen: "understanding-your-dog-intro",
				},
				{
					...surveyProgress,
					lifeWithYourDog: 40,
					multiDog: DEFAULT_MULTI_DOG_PROGRESS,
				},
			]; // "Understanding your dog"
		}

		return [
			{ ...screenState, route: "reading-your-dog", screen: "nds-q-20" },
			{
				...surveyProgress,
				multiDog: { ...surveyProgress.multiDog, lifeWithYourDog: 66 },
			},
		]; // How often does <Dog’s Name> do the following? (behaviors)
	}
	if (currentScreen === "understanding-your-dog-intro") {
		//! "Understanding your dog"
		// * THIS SCREEN IS ONLY ASKED ONCE
		return [
			{ ...screenState, route: "reading-your-dog", screen: "nds-q-14" },
			{
				...surveyProgress,
				multiDog: DEFAULT_MULTI_DOG_PROGRESS,
			},
		]; // Which of these dogs do you think need more space?
	}
	if (currentScreen === "nds-q-14") {
		//! Which of these dogs do you think need more space?
		// * THIS SCREEN IS ONLY ASKED ONCE
		return [
			{ ...screenState, route: "reading-your-dog", screen: "nds-q-16" },
			{ ...surveyProgress, lifeWithYourDog: 50 },
		]; // How do you manage your dog(s) when a delivery (post, parcel, takeaway meal or similar) is being made to your house?
	}
	if (currentScreen === "nds-q-16") {
		//! How do you manage your dog(s) when a delivery (post, parcel, takeaway meal or similar) is being made to your house?
		// * THIS SCREEN IS ONLY ASKED ONCE
		return [
			{ ...screenState, route: "reading-your-dog", screen: "nds-q-17" },
			{ ...surveyProgress, lifeWithYourDog: 60 },
		]; // How much do you agree or disagree with the following statements? (attitudes to bite risk)
	}
	if (currentScreen === "nds-q-17") {
		//! How much do you agree or disagree with the following statements? (attitudes to bite risk)
		// * THIS SCREEN IS ONLY ASKED ONCE
		return [
			{
				...screenState,
				route: "reading-your-dog",
				screen: "dog-behaviour-support-intro",
			},
			{ ...surveyProgress, lifeWithYourDog: 70 },
		]; // "Dog behaviour support"
	}
	if (currentScreen === "dog-behaviour-support-intro") {
		//! "Dog behaviour support"
		// * THIS SCREEN IS ONLY ASKED ONCE
		return [
			{ ...screenState, route: "reading-your-dog", screen: "nds-q-18" },
			{
				...surveyProgress,
				multiDog: DEFAULT_MULTI_DOG_PROGRESS,
			},
		]; // Do you attend training classes?
	}
	if (currentScreen === "nds-q-18") {
		//! Do you attend training classes?
		// * THIS SCREEN IS ONLY ASKED ONCE
		return [
			{ ...screenState, route: "reading-your-dog", screen: "nds-q-19" },
			{
				...{
					...surveyProgress,
					multiDog: DEFAULT_MULTI_DOG_PROGRESS,
				},
				lifeWithYourDog: 80,
			},
		]; // How do you feel about the following statements about training dogs?
	}
	if (currentScreen === "nds-q-19") {
		//! How do you feel about the following statements about training dogs?
		return [
			{ ...screenState, route: "reading-your-dog", screen: "nds-q-20" },
			{
				...{
					...surveyProgress,
					multiDog: DEFAULT_MULTI_DOG_PROGRESS,
				},
				lifeWithYourDog: 90,
			},
		]; // How often does <Dog’s Name> do the following? (behaviors)
	}

	const followUpQuestions = getQuestion20FollowUps(
		formData,
		currentDogs[currentDogIndex],
		questionSchema,
	);

	if (currentScreen === "nds-q-20") {
		//! How often does <Dog’s Name> do the following? (behaviors)
		if (followUpQuestions.length > 0) {
			return [
				{
					...screenState,
					route: "reading-your-dog",
					screen: followUpQuestions[0] as NDSScreens,
				},
				{
					...{
						...surveyProgress,
						multiDog: DEFAULT_MULTI_DOG_PROGRESS,
					},
					lifeWithYourDog: 92,
				},
			];
		}

		if (currentDogIndex > 1) {
			// Skip to wellbeing as they've answered all the follow up questions previously
			return getNextScreenWellbeingAndSupportRoute(
				{ ...screenState, screen: "nds-q-24" },
				formData,
				questionSchema,
				currentDogs,
				surveyProgress,
			);
		}

		return [
			{
				...screenState,
				route: "wellbeing-and-support",
				screen: "wellbeing-and-support-intro",
			},
			{
				...{
					...surveyProgress,
					multiDog: DEFAULT_MULTI_DOG_PROGRESS,
				},
				lifeWithYourDog: 100,
			},
			"transition-end-reading-your-dog",
		];
	}
	if (
		[
			"nds-q-20.1.A",
			"nds-q-20.1.B",
			"nds-q-20.1.C",
			"nds-q-20.1.D",
			"nds-q-20.1.E",
			"nds-q-20.1.F",
			"nds-q-20.1.G",
			"nds-q-20.1.H",
			"nds-q-20.1.I",
			"nds-q-20.1.J",
		].includes(currentScreen)
	) {
		//! "nds-q-20.1.A" Thinking about when [dogName] doesn't come back when called...
		//! "nds-q-20.1.B" Thinking about when [dogName] jumps up uninvited...
		//! "nds-q-20.1.C" Thinking about when [dogName] refuses to give up an item...
		//! "nds-q-20.1.D" Thinking about when [dogName] panics when left alone in the house...
		//! "nds-q-20.1.E" Thinking about when [dogName] pulls on lead...
		//! "nds-q-20.1.F" Thinking about when [dogName] is unable to remain calm around other dogs (lunging or barking at other dogs)...
		//! "nds-q-20.1.G" Thinking about when [dogName] growls, barks or lunges at people/strangers...
		//! "nds-q-20.1.H" Thinking about when [dogName] barks when people knock on the door or make noise outside...
		//! "nds-q-20.1.I" Thinking about when [dogName] has trouble sleeping through the night...
		//! "nds-q-20.1.J" Thinking about when [dogName] has trouble staying calm when fireworks can be heard...

		const currentIndex = followUpQuestions.indexOf(currentScreen);

		if (currentIndex === 0) {
			// It's the first follow up question
			if (followUpQuestions.length > 1) {
				return [
					{
						...screenState,
						route: "reading-your-dog",
						screen: followUpQuestions[1] as NDSScreens,
					},
					{
						...{
							...surveyProgress,
							multiDog: DEFAULT_MULTI_DOG_PROGRESS,
						},
						lifeWithYourDog: 95,
					},
				];
			}
		}
		if (currentIndex === 1) {
			// It's the second follow up question
			if (followUpQuestions.length > 2) {
				return [
					{
						...screenState,
						route: "reading-your-dog",
						screen: followUpQuestions[2] as NDSScreens,
					},
					{
						...{
							...surveyProgress,
							multiDog: DEFAULT_MULTI_DOG_PROGRESS,
						},
						lifeWithYourDog: 97,
					},
				];
			}
		}

		if (currentDogIndex > 1) {
			const question33Fields: NDSFormFieldNames[] = [
				"nds-q-33-extroverted-enthusiastic",
				"nds-q-33-critical-quarrelsome",
				"nds-q-33-dependable-self-disciplined",
				"nds-q-33-anxious-easily-upset",
				"nds-q-33-open-to-new-experiences-complex",
				"nds-q-33-reserved-quiet",
				"nds-q-33-sympathetic-warm",
				"nds-q-33-disorganised-careless",
				"nds-q-33-calm-emotionally-stable",
				"nds-q-33-conventional-uncreative",
				"nds-q-33-prefer-not-to-say",
			];

			const question33Answer = question33Fields.some(
				(field) => formData[field]?.length > 0,
			);

			const question34Answer =
				formData["nds-q-34-hobbies-interests"]?.length > 0;

			const [, hasAnsweredAboutAllDogs] = getMultiDogStatus(
				formData,
				questionSchema,
				currentDogIndex,
			);

			const canNotAnswerAboutMoreDogs =
				hasAnsweredAboutAllDogs || screenState.dogIndex === 5;
			// If they have already answered questions 33 and 34...
			if (question33Answer && question34Answer && canNotAnswerAboutMoreDogs) {
				return [
					{
						...screenState,
						route: "about-you",
						screen: "nds-q-34.2",
					},
					surveyProgress,
				];
			}

			return [
				{
					...screenState,
					route: "about-you",
					screen: canNotAnswerAboutMoreDogs ? "nds-q-32.1" : "nds-q-32.2",
				},
				surveyProgress,
			];
		}

		// They are on their first dog so need to answer wellbeing
		return [
			{
				...screenState,
				route: "wellbeing-and-support",
				screen: "wellbeing-and-support-intro",
			},
			{
				...{
					...surveyProgress,
					multiDog: DEFAULT_MULTI_DOG_PROGRESS,
				},
				lifeWithYourDog: 100,
			},
			"transition-end-reading-your-dog",
		];
	}

	console.warn("No next screen found for", currentScreen);
	return [
		{ ...screenState },
		{
			...surveyProgress,
			multiDog: DEFAULT_MULTI_DOG_PROGRESS,
		},
	]; // Default to the current screen
};
