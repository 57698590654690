import React from "react";
import { OurCentresContextState } from "./OurCentres.types";

export const OurCentresContext = React.createContext<OurCentresContextState>({
	selectedCentreCode: "",
	centreLocations: [],
	centreNames: {},
	centresInRange: [],
	addressSuggestions: [],
	resultsCount: 0,
	sortBy: "A",
	pagination: 0,
	currentDistance: 1000,
	userLocation: null,
	locationSearchTerm: "",
	distanceToCentres: {},
	onLoadOurCentres: () => {},
	onSelectCentre: () => {},
	safelyChangeDistance: () => {},
	onUpdateTravelDistance: () => {},
	onClearUserLocation: () => {},
	onUpdateSort: () => {},
	onLoadMore: () => {},
	onUpdateLocation: () => {},
});
