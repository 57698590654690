/**
 * Given the current screen and form data, returns the next screen for the warm route
 * @param currentScreen	- The current screen
 * @param formData	- The form data
 * @param _questionSchema - The question schema
 * @returns The next screen
 */
export const getNextScreenWarmRoute = (
	screenState: NDSScreenState,
	formData: NDSFormData,
	questionSchema: NDSQuestionSchema,
	_currentDogs: Record<number, Record<string, string | string[]>>,
	surveyProgress: NDSSurveyProgress,
): [NDSScreenState, NDSSurveyProgress] => {
	const currentScreen = screenState.screen;
	// ======================== Warm Screens ========================
	if (currentScreen === "warm-intro") {
		return [
			{ ...screenState, route: "warm", screen: "nds-q-0.1" },
			surveyProgress,
		];
	}
	if (currentScreen === "nds-q-0.1") {
		const negativeAnswer =
			questionSchema.routes.warm["nds-q-0.1"].fields[0].options[0].value;

		const answer = formData["nds-q-0.1-details-correct"];

		if (answer === negativeAnswer) {
			return [
				{ ...screenState, route: "about-your-dogs", screen: "nds-q-1" },
				surveyProgress,
			];
		}

		return [
			{ ...screenState, route: "warm", screen: "nds-q-0.2" },
			surveyProgress,
		];
	}

	const question2Answer =
		formData["nds-q-0.2-confirm-dog-still-lives-with-you"];
	const question2PositiveAnswer =
		questionSchema.routes.warm["nds-q-0.2"].fields[0].options[0].value;

	const question2NegativeAnswer =
		questionSchema.routes.warm["nds-q-0.2"].fields[0].options[1].value;

	if (currentScreen === "nds-q-0.2") {
		if (question2Answer === question2PositiveAnswer) {
			// If they are neutered we ask a follow up question
			if (screenState.warmData?.dog.neuterStatus !== "neutered") {
				return [
					{ ...screenState, route: "warm", screen: "nds-q-0.2.1" },
					surveyProgress,
				];
			}

			// Otherwise proceed to the next question
			return [
				{ ...screenState, route: "warm", screen: "nds-q-0.3" },
				surveyProgress,
			];
		}

		if (question2Answer === question2NegativeAnswer) {
			return [
				{ ...screenState, route: "warm", screen: "nds-q-0.2.2" },
				surveyProgress,
			];
		}

		return [
			{ ...screenState, route: "about-your-dogs", screen: "nds-q-1" },
			surveyProgress,
		];
	}

	if (currentScreen === "nds-q-0.2.1") {
		return [
			{ ...screenState, route: "warm", screen: "nds-q-0.3" },
			surveyProgress,
		];
	}

	if (currentScreen === "nds-q-0.2.2") {
		return [
			{ ...screenState, route: "warm", screen: "nds-q-0.3" },
			surveyProgress,
		];
	}

	const question3Answer = formData["nds-q-0.3-do-you-have-other-dogs"];

	const question3PositiveAnswer =
		questionSchema.routes.warm["nds-q-0.3"].fields[0].options[0].value;

	const question3NegativeAnswer =
		questionSchema.routes.warm["nds-q-0.3"].fields[0].options[1].value;

	if (currentScreen === "nds-q-0.3") {
		// If they don't have any new dogs but they do still have their warm dog we start them on question 8 of about your dogs
		if (question2Answer === question2PositiveAnswer) {
			if (question3Answer === question3PositiveAnswer) {
				// We start the cold route
				return [
					{ ...screenState, route: "warm", screen: "nds-q-0.4" },
					surveyProgress,
				];
			}

			// There are no other dogs in their household.
			return [
				{ ...screenState, route: "about-your-dogs", screen: "nds-q-8" },
				surveyProgress,
			];
		}

		// If they don't have any new dogs but they no longer have their warm dog we start them on the non-dog owner route
		if (question2Answer === question2NegativeAnswer) {
			if (question3Answer === question3PositiveAnswer) {
				// We start the cold route
				return [
					{ ...screenState, route: "about-your-dogs", screen: "nds-q-1" },
					surveyProgress,
				];
			}

			// There are other dogs in their household.

			if (question3Answer === question3NegativeAnswer) {
				// There are no other dogs in their household.
				return [
					{ ...screenState, route: "non-dog-owner", screen: "nds-q-E2" },
					surveyProgress,
				];
			}
		}

		console.warn("Shouldn't get here");
		return [screenState, surveyProgress];
	}

	if (currentScreen === "nds-q-0.4") {
		const question4Answer = formData["nds-q-0.4-have-you-acquired-other-dogs"];

		const question4PositiveAnswer =
			questionSchema.routes.warm["nds-q-0.4"].fields[0].options[0].value;

		const question4NegativeAnswer =
			questionSchema.routes.warm["nds-q-0.4"].fields[0].options[1].value;

		if (question4Answer === question4PositiveAnswer) {
			// There are new dogs in their household.
			return [
				{ ...screenState, route: "about-your-dogs", screen: "nds-q-2" },
				surveyProgress,
			];
		}

		if (question4Answer === question4NegativeAnswer) {
			// There are no new dogs in their household. so they skip to question 8 in the about your dogs route
			return [
				{ ...screenState, route: "about-your-dogs", screen: "nds-q-8" },
				surveyProgress,
			];
		}

		console.warn("Shouldn't get here");

		return [screenState, surveyProgress];
	}
};
