import React, { useCallback, useReducer, useRef } from "react";
import {
	onLoadCharityShopsAction,
	onLoadMoreAction,
	onSelectCharityShopsAction,
	onUpdateLocationAction,
	onUpdateSortOptionAction,
	onUpdateTravelDistanceAction,
} from "./CharityShops.actions";
import {
	charityShopsDefaultState,
	charityShopsReducer,
} from "./CharityShops.reducer";
import { CharityShopsSortOption } from "./CharityShops.types";

import { CharityShopsContext } from "./CharityShops.context";
import { DISTANCE_OPTIONS } from "./CharityShops.fixtures";

export const CharityShopsProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [
		{
			pagination,
			sortBy,
			userLocation,
			currentDistance,
			charityShopsLocations,
			...rest
		},
		dispatch,
	] = useReducer(charityShopsReducer, charityShopsDefaultState);

	// ============================================ REDUCER ACTIONS =====================================================

	const onLoadCharityShops = useCallback(
		(allCharityShops: Queries.CharityShopDataFragment[]) =>
			dispatch(onLoadCharityShopsAction(allCharityShops)),
		[],
	);
	const onSelectCharityShops = useCallback(
		(charityShopName: string) =>
			dispatch(onSelectCharityShopsAction(charityShopName)),
		[],
	);
	const onUpdateTravelDistance = useCallback(
		(distance: number) => dispatch(onUpdateTravelDistanceAction(distance)),
		[],
	);

	const onUpdateLocation = useCallback(
		(userLocation: Place, distance: number) =>
			dispatch(onUpdateLocationAction(userLocation, distance)),
		[],
	);

	const onUpdateSort = useCallback(
		(selectedOption: CharityShopsSortOption) =>
			dispatch(onUpdateSortOptionAction(selectedOption)),
		[],
	);

	// ============================================ FUNCTIONS =====================================================

	const distanceThrottle = useRef(null);
	/**
	 * On Change event for the distance slider. Only sets state when it's landed on expected value.
	 * Stops state from changing during slide event
	 * @param value The new value of the slider
	 */
	const safelyChangeDistance = useCallback(
		(value: string) => {
			if (DISTANCE_OPTIONS.includes(parseInt(value))) {
				// Stop changes on inbetween vals
				distanceThrottle.current = setTimeout(() => {
					onUpdateTravelDistance(parseInt(value));
				}, 300);
			}
		},
		[onUpdateTravelDistance],
	);

	const onLoadMore = useCallback(() => dispatch(onLoadMoreAction()), []);

	return (
		<CharityShopsContext.Provider
			value={{
				...rest,
				charityShopsLocations,
				sortBy,
				pagination,
				currentDistance,
				userLocation,
				onLoadCharityShops,
				onSelectCharityShops,
				safelyChangeDistance,
				onUpdateTravelDistance,
				onUpdateLocation,
				onUpdateSort,
				onLoadMore,
			}}
		>
			{children}
		</CharityShopsContext.Provider>
	);
};
