import { useContext } from "react";
import { CharityShopsContext } from "./CharityShops/CharityShops.context";
import { CharityShopsContextState } from "./CharityShops/CharityShops.types";
import { CmsMapContext } from "./CmsMap/CmsMap.context";
import { CmsMapContextState } from "./CmsMap/CmsMap.types";
import { DogSchoolApiContext } from "./DogSchoolApi/DogSchoolApi.context";
import { DogSchoolContextState } from "./DogSchoolApi/DogSchoolApi.types";
import { DogSearchApiContext } from "./DogSearchApi/DogSearchApi.context";
import { DogSearchContextState } from "./DogSearchApi/DogSearchApi.types";
import { FavouritesManagerContext } from "./FavouritesManager/FavouritesManager.context";
import { MyAccountContext } from "./MyAccount/MyAccount.context";
import { OurCentresContext } from "./OurCentres/OurCentres.context";
import { OurCentresContextState } from "./OurCentres/OurCentres.types";
import { PageManagerContext } from "./PageManager/PageManager.context";
import { SponsorDogsContext } from "./SponsorDogs/SponsorDogs.context";
import { VolunteerVacanciesContext } from "./VolunteerVacancies/VolunteerVacancies.context";

export const useDogSearchApi = (): DogSearchContextState =>
	useContext(DogSearchApiContext);

export const useDogSchoolApi = (): DogSchoolContextState =>
	useContext(DogSchoolApiContext);

export const useOurCentres = (): OurCentresContextState =>
	useContext(OurCentresContext);

export const useCharityShops = (): CharityShopsContextState =>
	useContext(CharityShopsContext);

export const usePageManager = (): PageManagerContextState =>
	useContext(PageManagerContext);

export const useVolunteerVacancies = (): VolunteerVacanciesContext =>
	useContext(VolunteerVacanciesContext);

export const useSponsorDogs = (): SponsorDogsContext =>
	useContext(SponsorDogsContext);

export const useFavouritesManager = (): FavouritesManagerContext =>
	useContext(FavouritesManagerContext);

export const useCmsMap = (): CmsMapContextState => useContext(CmsMapContext);
export const useMyAccount = () => useContext(MyAccountContext);
