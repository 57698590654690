import { getProfile, syncProfile } from "@dogstrust/src/utils/api/my-account";
import {
	getCookie,
	getDogToEnquireCookie,
	setSadCookie,
} from "@dogstrust/src/utils/cookies";
import { pushToDataLayer } from "@dogstrust/src/utils/google";
import { generateUUID } from "@dogstrust/src/utils/uuid";
import React, {
	type PropsWithChildren,
	useCallback,
	useEffect,
	useState,
} from "react";
import { MyAccountContext } from "./MyAccount.context";

const UNINITIALISED_PROFILE: Profile = {
	userId: "offline",
	type: "uninitialised",
	amount: 2,
	favourites: [],
	basket: [],
	interested: [],
	adoptionSubmissions: [],
	shouldSync: false,
	idp: "email",
};

const DEFAULT_PROFILE: Profile = {
	userId: "offline",
	amount: 2,
	favourites: [],
	basket: [],
	interested: [],
	adoptionSubmissions: [],
	type: "offline",
	shouldSync: false,
	idp: "email",
};

export const MyAccountProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [authStatus, setAuthStatus] = useState<MyAccountAuthState>("unknown");
	const [profile, setProfile] = useState<Profile>(UNINITIALISED_PROFILE);
	const [shouldMergeProfile, setShouldMergeProfile] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [shouldHandleEnquireAboutDog, setShouldHandleEnquireAboutDog] =
		useState(false);

	/**
	 * Clears the local profile
	 *
	 * This is used to clear the profile in local storage
	 */
	const clearLocalProfile = () => {
		console.log("🚀 Removing local profile");
		localStorage.removeItem("profile");
	};

	/**
	 * Update the local profile
	 * @param profile - The profile to update
	 *
	 * This is used to update the profile in local storage
	 */
	const updateLocalProfile = useCallback((profile: Profile) => {
		console.log("🚀 Updating local profile", profile);
		if (profile.type !== "uninitialised")
			localStorage.setItem(
				"profile",
				JSON.stringify({
					...profile,
					localSyncTime: new Date().getTime(), // We expire the local profile after 4 hours with no updates
				}),
			);
	}, []);

	/**
	 * Get the local profile
	 * @returns The local profile
	 * This is used to get the profile from local storage
	 * If there is no profile, return the default profile
	 */
	const getLocalProfile = useCallback((): Profile => {
		const profileCookieString: string | null = localStorage.getItem("profile");
		if (profileCookieString) {
			const parsedProfile: Profile = JSON.parse(profileCookieString);
			console.log("🚀 Getting local profile", parsedProfile);

			const lastSyncTime = new Date(parsedProfile.localSyncTime);
			const maxSessionTime = new Date();
			maxSessionTime.setHours(maxSessionTime.getHours() - 4);
			if (lastSyncTime.getTime() > maxSessionTime.getTime()) {
				// The profile is still valid because the last sync time is less than 4 hours ago
				parsedProfile.basket = parsedProfile.basket.map((dog) => ({
					...dog,
					basketId: dog.basketId || generateUUID(),
				}));
				return parsedProfile;
			}

			console.log("🚀 Session has expired so...");
		}

		console.log("🚀 Getting default profile");
		updateLocalProfile({ ...DEFAULT_PROFILE });
		return DEFAULT_PROFILE;
	}, [updateLocalProfile]);

	/**
	 * Fetch the latest profile
	 * @param onLogin - Whether this is being called on login
	 * This is used to fetch the latest profile from the API
	 * If there is no profile, return the default profile
	 * If there is an error, return the default profile
	 * If the user is not authenticated, return the default profile
	 * If the user is authenticated, return the profile
	 * If the user is authenticated and there is a local profile, merge the local profile with the account profile
	 **/
	const fetchLatestProfile = useCallback(
		async (onLogin?: boolean) => {
			await getProfile()
				.then((accountProfile) => {
					if (accountProfile) {
						console.log("🚀 Got profile", accountProfile);
						setProfile({
							...accountProfile,
							type: "account",
							shouldSync: false,
						});
						const localProfile = getLocalProfile();
						console.log(
							"🚀 (fetchLatestProfile) Got local profile",
							localProfile,
						);
						const shouldMergeProfile =
							onLogin && // We only want to merge the account when the user first logs in
							(localProfile.basket.length > 0 || // if the user has a basket
								localProfile.favourites.length > 0 || // or the user has favourites
								localProfile.interested.length > 0); // or the user has interested
						setShouldMergeProfile(shouldMergeProfile);

						if (!shouldMergeProfile) {
							setIsLoading(false);
						}
					} else {
						console.log("🚀 No profile found");
						// TODO - Is this the best way to handle this? - Do we need to tell the user?
						setAuthStatus("unauthorized");
						setIsLoading(false);
					}
				})
				.catch((error) => {
					console.error("An error occurred:", error);
					setAuthStatus("unauthorized");
				});
		},
		[getLocalProfile],
	);

	/**
	 * This useEffect handles the authentication of the user and the fetching of the latest profile
	 */
	useEffect(() => {
		/**
		 * Check if the user is authenticated - and set the state accordingly
		 */
		const checkAuth = async () => {
			const isLoggedIn = await fetch(process.env.GATSBY_AUTH_STATUS_URL, {
				credentials: "include",
				method: "GET",
				redirect: "manual",
			});
			console.log("🚀 checkAuth", isLoggedIn);
			setAuthStatus(isLoggedIn.ok ? "authorized" : "unauthorized");
		};
		// If the user is not authenticated, use the status url to check if they are logged in
		if (authStatus === "unknown") {
			setAuthStatus("authenticating");
			checkAuth();
		} else if (authStatus === "authorized") {
			fetchLatestProfile(true); // If the user's auth status has changed to authorized, fetch the latest profile indicating the user has just logged in.
		} else if (authStatus === "unauthorized") {
			const localProfile = getLocalProfile();
			console.log("🚀 Unauthorized so got local profile", localProfile);
			setProfile(localProfile);
			setIsLoading(false);
		}
	}, [authStatus, fetchLatestProfile, getLocalProfile]);

	/**
	 * Remove a dog from the users basket
	 * If the user is authenticated, remove the dog from their account
	 * If the user is not authenticated, remove the dog from the cookie
	 * @param sadDog - The dog to remove from the basket
	 */
	const handleRemoveFromBasket = useCallback(
		async (sadDog: SADCookie, indexToRemove?: number) => {
			(window as GoogleWindow).dataLayer =
				(window as GoogleWindow).dataLayer || [];
			(window as GoogleWindow).dataLayer.push({
				event: "removeFromCart",
				ecommerce: {
					currencyCode: "GBP",
					remove: {
						products: [
							{
								name: `Sponsorship - ${sadDog.name}`, // concatenation of Sponsorship and dog to be sponsored.
								id: sadDog.fundCode,
								brand: "Sponsorship",
								gift: sadDog.isGift ? "yes" : "no",
								category: "",
								variant: "",
								quantity: 1,
							},
						],
					},
				},
			});
			if (indexToRemove === undefined) {
				setProfile((prevProfile) => {
					// It's not a specific index so we remove the last one that matches this sadDog
					const indexOfDog = prevProfile?.basket?.findIndex(
						(dog) => dog.fundCode === sadDog.fundCode,
					);
					return {
						...prevProfile,
						basket: [
							...prevProfile.basket.filter((_dog, i) => i !== indexOfDog),
						],
						shouldSync: true,
					};
				});
			}
			setProfile((prevProfile) => ({
				...prevProfile,
				basket:
					prevProfile?.basket?.filter((_dog, i) => i !== indexToRemove) || [],
				shouldSync: true,
			}));
		},
		[],
	);

	/**
	 * Add a dog to the users basket
	 * If the user is authenticated, add the dog to their account
	 * If the user is not authenticated, add the dog to the cookie
	 * @param sadDog - The dog to add to the basket
	 */
	const handleAddToBasket = useCallback(async (sadDog: SADCookie) => {
		(window as GoogleWindow).dataLayer =
			(window as GoogleWindow).dataLayer || [];
		(window as GoogleWindow).dataLayer.push({
			event: "addToCart",
			ecommerce: {
				currencyCode: "GBP",
				add: {
					products: [
						{
							name: `Sponsorship - ${sadDog.name}`, // concatenation of Sponsorship and dog to be sponsored.
							id: sadDog.fundCode,
							price: sadDog.amount,
							brand: "Sponsorship",
							category: "",
							variant: "",
							quantity: 1,
						},
					],
				},
			},
		});

		setProfile((prevProfile) => {
			const profileAfter = {
				...prevProfile,
				basket: [
					...(prevProfile.basket || []),
					{ ...sadDog, basketId: generateUUID() },
				],
				shouldSync: true,
			};
			console.log("Add to basket, profile before", prevProfile);
			console.log("Add to basket, set profile after", profileAfter);

			return profileAfter;
		});
	}, []);

	/**
	 * Update the recipient of the sponsorship
	 * @param recipient - The recipient of the sponsorship
	 * This is used to update the recipient of the sponsorship
	 */
	const handleRecipientChange = useCallback(
		async (basketKey: number, isGift: boolean) => {
			setProfile((prevProfile) => ({
				...prevProfile,
				basket: prevProfile.basket.map((dog, i) => ({
					...dog,
					isGift: i === basketKey ? isGift : dog.isGift,
				})),
				shouldSync: true,
			}));
		},
		[],
	);

	/**
	 * Update the amount of the sponsorship
	 * @param amount - The amount of the sponsorship
	 * This is used to update the amount of the sponsorship
	 */
	const handleUpdateAmount = useCallback(async (amount: number) => {
		setProfile((prevProfile) => ({
			...prevProfile,
			amount,
			shouldSync: true,
		}));
		console.log("Update amount", amount);
	}, []);

	/**
	 * Add a dog to the users favourites
	 * If the user is authenticated, add the dog to their account
	 * If the user is not authenticated, add the dog to local storage
	 * @param dog - The dog to add to the favourites
	 */
	const handleAddToFavourites = useCallback(
		async (
			dog: ESDocDog,
			data: {
				card_location: string;
				event: "card_favourite";
				card_state: string;
				card_title: string;
				card_sub_title: string;
			},
		) => {
			setProfile((prevProfile) => {
				const existsInFavourites = prevProfile.favourites?.some(
					(favDog) => favDog?.apiKey === dog?.apiKey,
				);
				if (existsInFavourites) {
					console.log("🚀 Dog already exists in favourites");
					return;
				}
				// ============== Handle tracking ==============
				const currentPageUrl =
					typeof window !== "undefined" ? window.location.pathname : "";
				data.card_location = currentPageUrl;
				data.card_title = dog.name;
				data.card_sub_title = dog.breed;
				data.card_state = "added";
				// biome-ignore  lint/suspicious/noExplicitAny:
				(window as any).dataLayer = (window as any).dataLayer || [];
				// biome-ignore  lint/suspicious/noExplicitAny:
				(window as any).dataLayer.push(data);
				return {
					...prevProfile,
					favourites: [...prevProfile.favourites, dog],
					shouldSync: true,
				};
			});
		},
		[],
	);

	/**
	 * Enquire about the dog
	 * The user must be authenticated
	 * If the dog is already in the favourites list do not add again, just update the interested list
	 * @param dog - The dog to add to the favourites
	 */
	const handleEnquireAboutDog = useCallback(
		async (dog: ESDocDog, data: DogDataTracking) => {
			// ============== Handle tracking ============== //TODO - Confirm if there will be specific tracking about enquiries

			setProfile((prevProfile) => {
				const dogIsAlreadyInFavourites = prevProfile.favourites?.some(
					(favDog) => favDog?.apiKey === dog?.apiKey,
				);
				const dogIsAlreadyInInterested = prevProfile.interested?.some(
					(interestedDog) => interestedDog?.apiKey === dog?.apiKey,
				);

				if (!dogIsAlreadyInFavourites) {
					// We are only going to track adding to faves if the dog is not already faved
					const currentPageUrl =
						typeof window !== "undefined" ? window.location.pathname : "";
					data.card_location = currentPageUrl;
					data.card_title = dog.name;
					data.card_sub_title = dog.breed;
					data.card_state = "added";
					// biome-ignore  lint/suspicious/noExplicitAny:
					(window as any).dataLayer = (window as any).dataLayer || [];
					// biome-ignore  lint/suspicious/noExplicitAny:
					(window as any).dataLayer.push(data);
				}

				localStorage.removeItem(process.env.GATSBY_DOG_TO_ENQUIRE_COOKIE_NAME);

				return {
					...prevProfile,
					favourites: dogIsAlreadyInFavourites
						? prevProfile.favourites
						: [...prevProfile.favourites, dog],
					interested: dogIsAlreadyInInterested
						? prevProfile.interested
						: [dog, ...(prevProfile.interested || [])].slice(0, 4),
					shouldSync: true,
				};
			});
		},
		[],
	);

	/**
	 * Remove a dog from the users favourites
	 * If the user is authenticated, remove the dog from their account
	 * If the user is not authenticated, remove the dog from local storage
	 * @param dog - The dog to remove from the favourites
	 */
	const handleRemoveFromFavourites = useCallback(
		async (dog: ESDocDog, data: DogDataTracking) => {
			// ============== Handle tracking ==============
			const currentPageUrl =
				typeof window !== "undefined" ? window.location.pathname : "";
			data.card_location = currentPageUrl;
			data.card_title = dog.name;
			data.card_sub_title = dog.breed;
			data.card_state = "removed";
			// biome-ignore  lint/suspicious/noExplicitAny:
			(window as any).dataLayer = (window as any).dataLayer || [];
			// biome-ignore  lint/suspicious/noExplicitAny:
			(window as any).dataLayer.push(data);

			setProfile((prevProfile) => ({
				...prevProfile,
				favourites: prevProfile.favourites.filter(
					(favDog) => favDog?.apiKey !== dog?.apiKey,
				),
				shouldSync: true,
			}));
		},
		[],
	);

	const handleAddToInterested = useCallback(async (dog: ESDocDog) => {
		setProfile((prevProfile) => {
			const dogAlreadyInterested = prevProfile.interested.some(
				(interestedDog) => interestedDog?.apiKey === dog?.apiKey,
			);
			if (dogAlreadyInterested) {
				console.log("🚀 Dog already exists in interested");
				return;
			}

			return {
				...prevProfile,
				interested: [...prevProfile.interested, dog],
			};
		});
	}, []);

	const handleRemoveFromInterested = useCallback(async (dog: ESDocDog) => {
		setProfile((prevProfile) => ({
			...prevProfile,
			interested: prevProfile.interested.filter(
				(interestedDog) => interestedDog?.id !== dog?.id,
			),
		}));
	}, []);

	const getCanSubmitAdoptionApplication = () => {
		if (
			!profile.adoptionSubmissions ||
			profile.adoptionSubmissions.length === 0
		)
			return true;

		if (process.env.GATSBY_ENVIRONMENT === "prod") {
			// They can only submit an application once every 3 months so check no application has been submitted in the last 3 months
			const threeMonthsAgo = new Date();
			threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
			const submissionsWithinLastThreeMonths =
				profile.adoptionSubmissions.filter(
					(submission) => submission > threeMonthsAgo.getTime(),
				);
			return submissionsWithinLastThreeMonths.length === 0;
		}
		const tenMinsAgo = new Date();
		tenMinsAgo.setMinutes(tenMinsAgo.getMinutes() - 10);
		const submissionsWithinLastTenMins = profile.adoptionSubmissions.filter(
			(submission) => submission > tenMinsAgo.getTime(),
		);
		return submissionsWithinLastTenMins.length === 0;
	};

	const syncTimeout = React.useRef<NodeJS.Timeout>();
	const syncRetryCount = React.useRef(0);

	useEffect(() => {
		const handleSyncingOfProfile = async () => {
			if (
				authStatus === "authorized" &&
				profile.type !== "uninitialised" &&
				profile.type !== "offline"
			) {
				console.log("🚀 Authed so syncing profile", profile);
				const hasSynced = await syncProfile({ ...profile, shouldSync: false });
				if (!hasSynced && syncRetryCount.current < 5) {
					syncRetryCount.current++;
					syncTimeout.current = setTimeout(handleSyncingOfProfile, 5000);
				} else if (hasSynced) {
					console.log("🚀 Profile has synced", profile);
					localStorage.setItem(
						"syncedProfile",
						JSON.stringify({ ...profile, shouldSync: false }),
					);
					syncRetryCount.current = 0;
					setSadCookie(profile.basket, profile.amount);
				}
			} else if (authStatus === "unauthorized") {
				console.log("🚀 Not authed so updating profile", profile);
				updateLocalProfile(profile);
				setSadCookie(profile.basket, profile.amount);
			}
		};
		handleSyncingOfProfile();

		return () => {
			clearTimeout(syncTimeout.current);
		};
	}, [authStatus, profile, updateLocalProfile]);

	const justSignedInCookie = getCookie("justsignedin");
	const justSignedUpCookie = getCookie("justsignedup");

	useEffect(() => {
		const alreadyAddedLoginToDataLayer = (
			window as GoogleWindow
		).dataLayer?.some(
			(item: {
				event: string;
				authentication: string;
			}) =>
				item.event === "login" && item.authentication === justSignedInCookie,
		);
		// Only push the login and sign-up events if they haven't already been pushed
		if (
			justSignedInCookie &&
			!alreadyAddedLoginToDataLayer &&
			profile.userId !== "offline"
		) {
			pushToDataLayer({
				event: "login",
				authentication: justSignedInCookie,
				user_id: profile.userId,
			});
		}

		const alreadyAddedSignupToDataLayer = (
			window as GoogleWindow
		).dataLayer?.some(
			(item: {
				event: string;
				authentication: string;
			}) =>
				item.event === "account_creation" &&
				item.authentication === justSignedUpCookie,
		);

		if (
			justSignedUpCookie &&
			!alreadyAddedSignupToDataLayer &&
			profile.userId !== "offline"
		) {
			pushToDataLayer({
				event: "account_creation",
				authentication: justSignedUpCookie,
				user_id: profile.userId,
			});
		}
	}, [profile.userId, justSignedInCookie, justSignedUpCookie]);

	useEffect(() => {
		addEventListener("storage", async (event) => {
			if (event.storageArea !== localStorage) return;
			if (event.key === "syncedProfile") {
				setProfile(JSON.parse(event.newValue));
			}
		});
	}, []);

	// Handle the merging of the local profile and the account profile
	useEffect(() => {
		if (shouldMergeProfile) {
			const localProfile: Profile = getLocalProfile();
			console.log("🚀 Should merge local profile", localProfile);
			setProfile((prevProfile) => {
				const mergedFaves = [
					...localProfile.favourites,
					...prevProfile.favourites,
				];
				console.log("🚀 mergedFaves", mergedFaves);
				const dedeuplicatedFaves: ESDocDog[] = Object.values(
					mergedFaves.reduce(
						(acc, dog) => {
							acc[dog.apiKey] = dog;
							return acc;
						},
						{} as Record<string, ESDocDog>,
					),
				);

				console.log("🚀 mergedFaves de-duplicated", dedeuplicatedFaves);

				const mergedInterested = [
					...localProfile.interested,
					...prevProfile.interested,
				];
				console.log("🚀 mergedInterested", mergedInterested);

				const deduplicatedInterested: ESDocDog[] = Object.values(
					mergedInterested.reduce(
						(acc, dog) => {
							acc[dog.apiKey] = dog;
							return acc;
						},
						{} as Record<string, ESDocDog>,
					),
				);
				console.log(
					"🚀 mergedInterested de-duplicated",
					deduplicatedInterested,
				);

				const mergedBasket = [...localProfile.basket, ...prevProfile.basket];
				console.log("🚀 mergedBasket", mergedBasket);

				const deduplicatedBasket: SADCookie[] = Object.values(
					mergedBasket.reduce(
						(acc, dog) => {
							acc[dog.basketId] = dog;
							return acc;
						},
						{} as Record<string, SADCookie>,
					),
				);

				return {
					...localProfile,
					...prevProfile,
					basket: deduplicatedBasket.slice(0, 4), // The basket is max 4, we give preference to the local basket as that will be the most up to date,
					favourites: dedeuplicatedFaves,
					interested: deduplicatedInterested,
					shouldSync: true,
				};
			});
			setShouldMergeProfile(false);
			setIsLoading(false);
			clearLocalProfile();
		}
	}, [shouldMergeProfile, clearLocalProfile, getLocalProfile]);

	useEffect(() => {
		if (!shouldHandleEnquireAboutDog)
			// We only want to add the dog to the interested list if the user completes their login/signup journey and returns to the my-account/rehoming page
			return;
		if (profile.shouldSync)
			// Don't change the profile if we are already syncing
			return;
		if (profile.type !== "account")
			// We only want to add the dog to the interested list if the user is authenticated
			return;
		const enquireDog = getDogToEnquireCookie();
		// If there is not dog to enquire about, return
		if (!enquireDog) return;

		handleEnquireAboutDog(enquireDog.dog, enquireDog.dogData);
	}, [shouldHandleEnquireAboutDog, profile, handleEnquireAboutDog]);

	return (
		<MyAccountContext.Provider
			value={{
				isAuthenticated: authStatus === "authorized",
				authStatus,
				profile,
				isLoading,
				handleAddToBasket,
				handleRemoveFromBasket,
				handleAddToFavourites,
				handleRemoveFromFavourites,
				handleAddToInterested,
				handleEnquireAboutDog,
				setShouldHandleEnquireAboutDog,
				handleRemoveFromInterested,
				handleUpdateAmount,
				handleRecipientChange,
				canSubmitAdoptionApplication: getCanSubmitAdoptionApplication(),
			}}
		>
			{children}
		</MyAccountContext.Provider>
	);
};
