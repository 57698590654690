import { FilterDisplayState, SortOption } from "./DogSearchApi.types";

// ========================== CHANGE_FILTER_VIEW ==================================
export const CHANGE_FILTER_VIEW = "CHANGE_FILTER_VIEW";
interface ChangeFilterViewAction {
	type: typeof CHANGE_FILTER_VIEW;
	newView: FilterDisplayState;
}
export const onChangeFilterViewAction = (
	newView: FilterDisplayState,
): ChangeFilterViewAction => ({
	type: CHANGE_FILTER_VIEW,
	newView,
});

// ========================== UPDATE_FILTER_OPTION ===================================
export const UPDATE_FILTER_OPTION = "UPDATE_FILTER_OPTION";
interface UpdateFilterOptionAction {
	type: typeof UPDATE_FILTER_OPTION;
	selectedOption: {
		selectedAge?: string;
		selectedSize?: string;
		selectedGender?: string;
		selectedCentre?: string;
		selectedDayRange?: string;
		selectedLivesWithOption?: string;
	};
}
export const onUpdateFilterOptionAction = (selectedOption: {
	selectedAge?: string;
	selectedSize?: string;
	selectedGender?: string;
	selectedCentre?: string;
	selectedDayRange?: string;
	selectedLivesWithOption?: string;
}): UpdateFilterOptionAction => ({
	type: UPDATE_FILTER_OPTION,
	selectedOption,
});

// ========================== UPDATE_SORT_OPTION ===================================
export const UPDATE_SORT_OPTION = "UPDATE_SORT_OPTION";
interface UpdateSortOptionAction {
	type: typeof UPDATE_SORT_OPTION;
	selectedOption: SortOption;
}

export const onUpdateSortOptionAction = (
	selectedOption: SortOption,
): UpdateSortOptionAction => ({
	type: UPDATE_SORT_OPTION,
	selectedOption,
});

// ========================== RESET_FILTERS ==================================
export const RESET_FILTERS = "RESET_FILTERS";
interface ResetFiltersAction {
	type: typeof RESET_FILTERS;
	dontShowFilters?: boolean;
}
export const onResetFiltersAction = (
	dontShowFilters?: boolean,
): ResetFiltersAction => ({
	type: RESET_FILTERS,
	dontShowFilters,
});

// ========================== SELECT_BREED ===================================
export const INIT_FILTERS = "INIT_FILTERS";
interface InitFiltersAction {
	type: typeof INIT_FILTERS;
	isListingPage: boolean;
	shouldMaintainQueryParams: boolean;
	allCentres: RehomingCentreLocation[];
	centreCode?: string;
}
export const onInitFiltersAction = (
	isListingPage: boolean,
	shouldMaintainQueryParams: boolean,
	allCentres: RehomingCentreLocation[],
	centreCode?: string,
): InitFiltersAction => ({
	type: INIT_FILTERS,
	isListingPage,
	shouldMaintainQueryParams,
	allCentres,
	centreCode,
});

// ========================== LOAD_MORE ==================================
export const LOAD_MORE = "LOAD_MORE";
interface LoadMoreAction {
	type: typeof LOAD_MORE;
	previousResultsPages: ESDocDog[];
}
export const onLoadMoreAction = (
	previousResultsPages: ESDocDog[],
): LoadMoreAction => ({
	type: LOAD_MORE,
	previousResultsPages,
});

// ========================== SELECT_BREED ===================================
export const SELECT_BREED = "SELECT_BREED";
interface SelectBreedAction {
	type: typeof SELECT_BREED;
	breed: string;
}
export const onSelectBreedAction = (breed: string): SelectBreedAction => ({
	type: SELECT_BREED,
	breed,
});

// ========================== SELECT_BREEDS ===================================
export const SELECT_BREEDS = "SELECT_BREEDS";
interface SelectBreedsAction {
	type: typeof SELECT_BREEDS;
	breeds: string[];
}
export const onSelectBreedsAction = (breeds: string[]): SelectBreedsAction => ({
	type: SELECT_BREEDS,
	breeds,
});

// ========================== CONFIRM_SELECTED_BREEDS ========================
export const CONFIRM_SELECTED_BREEDS = "CONFIRM_SELECTED_BREEDS";
interface ConfirmSelectedBreedsAction {
	type: typeof CONFIRM_SELECTED_BREEDS;
}
export const onConfirmSelectedBreedsAction =
	(): ConfirmSelectedBreedsAction => ({
		type: CONFIRM_SELECTED_BREEDS,
	});

// ========================== CANCEL_SELECTED_BREEDS =========================
export const CANCEL_SELECTED_BREEDS = "CANCEL_SELECTED_BREEDS";
interface CancelSelectedBreedsAction {
	type: typeof CANCEL_SELECTED_BREEDS;
}
export const onCancelSelectedBreedsAction = (): CancelSelectedBreedsAction => ({
	type: CANCEL_SELECTED_BREEDS,
});

// ========================== UPDATE_TRAVEL_DISTANCE =========================
export const UPDATE_TRAVEL_DISTANCE = "UPDATE_TRAVEL_DISTANCE";
interface UpdateTravelDistanceAction {
	type: typeof UPDATE_TRAVEL_DISTANCE;
	distance: number;
}
export const onUpdateTravelDistanceAction = (
	distance: number,
): UpdateTravelDistanceAction => ({
	type: UPDATE_TRAVEL_DISTANCE,
	distance,
});

// ========================== UPDATE_LOCATION_SUGGESTIONS ====================
export const UPDATE_LOCATION_SUGGESTIONS = "UPDATE_LOCATION_SUGGESTIONS";
interface UpdateLocationSuggestionsAction {
	type: typeof UPDATE_LOCATION_SUGGESTIONS;
	postcodeSuggestions?: string[];
	addressSuggestions?: string[];
}
export const onUpdateLocationSuggestionsAction = (
	postcode?: string,
	townCity?: string,
): UpdateLocationSuggestionsAction => {
	if (postcode) {
		return {
			type: UPDATE_LOCATION_SUGGESTIONS,
			postcodeSuggestions: [],
		};
	} else if (townCity) {
		return {
			type: UPDATE_LOCATION_SUGGESTIONS,
			addressSuggestions: [],
		};
	} else {
		return {
			type: UPDATE_LOCATION_SUGGESTIONS,
			postcodeSuggestions: [],
			addressSuggestions: [],
		};
	}
};

// ========================== UPDATE_LOCATION ==========================
export const UPDATE_LOCATION = "UPDATE_LOCATION";
interface UpdateLocationAction {
	type: typeof UPDATE_LOCATION;
	userLocation: Place | null;
	distance: number;
}

export const onUpdateLocationAction = (
	userLocation: Place | null,
	distance: number,
): UpdateLocationAction => ({ type: UPDATE_LOCATION, userLocation, distance });

// ========================== CLEAR_USER_LOCATION ==========================
export const CLEAR_USER_LOCATION = "CLEAR_USER_LOCATION";
interface ClearUserLocationAction {
	type: typeof CLEAR_USER_LOCATION;
}

export const onClearUserLocationAction = (): ClearUserLocationAction => ({
	type: CLEAR_USER_LOCATION,
});

// ========================== UPDATE_SELECTED_CENTRES ==========================
export const UPDATE_SELECTED_CENTRES = "UPDATE_SELECTED_CENTRES";
interface UpdateSelectedCentres {
	type: typeof UPDATE_SELECTED_CENTRES;
	selectedCentre: string;
}

export const onUpdateSelectedCentresAction = (
	selectedCentre: string,
): UpdateSelectedCentres => ({
	type: UPDATE_SELECTED_CENTRES,
	selectedCentre,
});

// ========================== SEARCH_BREED_LIST ==========================
export const SEARCH_BREED_LIST = "SEARCH_BREED_LIST";
interface SearchBreedListAction {
	type: typeof SEARCH_BREED_LIST;
	breedSearchTerm: string;
}

export const onSearchBreedListAction = (
	breedSearchTerm: string,
): SearchBreedListAction => ({
	type: SEARCH_BREED_LIST,
	breedSearchTerm,
});

// ========================== TOGGLE_IS_UNDERDOG ==========================
export const TOGGLE_IS_UNDERDOG = "TOGGLE_IS_UNDERDOG";
interface ToggleIsUnderdogAction {
	type: typeof TOGGLE_IS_UNDERDOG;
	isUnderdog: boolean;
}

export const onToggleIsUnderdogAction = (
	isUnderdog: boolean,
): ToggleIsUnderdogAction => ({
	type: TOGGLE_IS_UNDERDOG,
	isUnderdog,
});

// ========================== TOGGLE_NO_RESERVED ==========================
export const TOGGLE_NO_RESERVED = "TOGGLE_NO_RESERVED";
interface ToggleNoReservedAction {
	type: typeof TOGGLE_NO_RESERVED;
	isNoReserved: boolean;
}

export const onToggleNoReservedAction = (
	isNoReserved: boolean,
): ToggleNoReservedAction => ({
	type: TOGGLE_NO_RESERVED,
	isNoReserved,
});

export type DogSearchAction =
	| ChangeFilterViewAction
	| UpdateFilterOptionAction
	| UpdateSortOptionAction
	| ResetFiltersAction
	| LoadMoreAction
	| InitFiltersAction
	| SelectBreedAction
	| SelectBreedsAction
	| ConfirmSelectedBreedsAction
	| CancelSelectedBreedsAction
	| UpdateTravelDistanceAction
	| UpdateLocationSuggestionsAction
	| UpdateLocationAction
	| ClearUserLocationAction
	| UpdateSelectedCentres
	| SearchBreedListAction
	| ToggleIsUnderdogAction
	| ToggleNoReservedAction;
